import React from 'react'

export default function ArrowLeft({ type = 'round' }) {
  return (
    <>
      {type === 'round' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 17.5 11.5"
          fill="none"
        >
          <path stroke="currentColor" stroke-width="1.5" d="M0.8,5.8h16" />
          <path
            stroke="currentColor"
            stroke-width="1.5"
            d="M5.7,10.8c0,0-5-3.7-5-5s5-5,5-5"
          />
        </svg>
      )}
      {type === 'sharp' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 17.1 17.1"
          fill="none"
        >
          <line
            stroke="currentColor"
            stroke-width="2" // 1.5
            x1="1.1"
            y1="8.5"
            x2="17.1"
            y2="8.5"
          />
          <polyline
            stroke="currentColor"
            stroke-width="2" // 1.5
            points="6.1,13.5 1.1,8.5 6.1,3.5 "
          />
        </svg>
      )}
      {type === 'squad_activity' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21.5 13.5"
          fill="none"
        >
          <path stroke="currentColor" stroke-width="1.5" d="M1,6.8h17.5" />
          <path
            stroke="currentColor"
            stroke-width="1.5"
            d="M6.3,12.2c0,0-5.5-4.1-5.5-5.5s5.5-5.5,5.5-5.5"
          />
        </svg>
      )}
    </>
  )
}
