export const BATCH = 'BATCH'
export const CHATROOMS = 'CHATROOMS'
// export const ENTRY = 'ENTRY'
// export const FOCUS = 'FOCUS'
// export const JOURNAL = 'JOURNAL'
export const LOCATION = 'LOCATION'

// export const DECREMENT = 'DECREMENT';
// export const decrement = () => ({ type: DECREMENT })  // Plain object

export const handleCHATROOMS = () => ({ type: CHATROOMS })

export const setLOCATION = (value) => ({
  type: 'LOCATION',
  payload: value,
})

export const rdx = (typeOrObj, value = null) => {
  if (typeof typeOrObj === 'string') {
    return { type: typeOrObj, payload: value }
  } else {
    return {
      type: 'BATCH',
      payload: typeOrObj,
    }
  }
}
