import { DJANGO, IN_DEPLOYMENT, mobile } from './components/ROUTERJS'

export async function backend(url, options = null) {
  console.log(`%c@backend core.js ${url}`, 'color:blue')
  const meta = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
  if (options) {
    if (options.headers) {
      meta.headers = { ...meta.headers, ...options.headers }
    }
    if (options.body) {
      meta.method = 'POST'
      meta.body = JSON.stringify(options.body)
    }
  }
  return fetch(url, meta)
    .then(async (response) => {
      if (!response.ok) {
        console.log('%c!response.ok', 'color:red;font-size:1rem')
        console.log(response)
        const response_json = await response.json()
        console.log(response_json)
        throw {
          body: response_json
            ? response_json
            : '@backend() failed to load response_json',
          status: response.status,
        }
      }
      const reponse_json = await response.json()
      console.log(reponse_json)
      return { body: reponse_json, status: response.status }
    })
    .catch((error) => {
      soundTheAlert("Something's gone horribly wrong @backend()")
      console.log(error)
      return error
    })
}

export function community_name(name, type = null) {
  if (Array.isArray(name)) {
    if (type === 'short') {
      return name[1]
    } else {
      return name[0]
    }
  } else {
    return name
  }
}

export const NA = 'This feature is not made yet.'

export const simulate = (callback, ms = 1000) => {
  setTimeout(() => {
    callback()
  }, ms)
}

export function soundTheAlert(message) {
  if (IN_DEPLOYMENT || mobile) {
    alert(message)
  } else {
    const alertTone = new Audio(src('dev/442546-SBsfi1-Error_Simple_010.wav'))
    console.log(`%c${message}`, 'font-size:1rem;color:hotpink')
    alertTone.play().catch((error) => {
      console.log(
        `%cError playing the alert tone: ${error}`,
        'font-size:1rem;color:hotpink'
      )
    })
  }
}

export const src = (src, type = null) => {
  let url = '/'
  if (DJANGO) {
    url = '/static/'
  }
  if (type === 'compressed') {
    const [filename, extension] = src.split('.')
    url +=
      'img-compressed/' + filename + (extension === 'gif' ? '.gif' : '.webp')
  } else {
    url += src
  }
  return url
}
