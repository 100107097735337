import React from 'react'

export default function GlobalRankInfo({
  props: { thisCommunityLib, currentDivision },
}) {
  function get_global_divisions() {
    const divisions_num = thisCommunityLib.global.divisions
    const division_interval = (index) => {
      const this_division =
        index === 0 ? 'top' : index + 1 === divisions_num ? 'bottom' : 'middle'
      switch (this_division) {
        case 'top':
          return '>' + thisCommunityLib.global.parameters[index]
        case 'middle':
          return thisCommunityLib.global.parameters[index].join(' - ')
        case 'bottom':
          return '<' + thisCommunityLib.global.parameters[index]
        default:
          break
      }
    }
    const division = (index) => (
      <div
        className={
          '-f-ja division' + (currentDivision === index + 1 ? ' current' : '')
        }
      >
        <p>{division_interval(index)}</p>
      </div>
    )
    return Array.from({ length: divisions_num }).map((_, index) =>
      division(index)
    )
  }
  return (
    <div id="GlobalRankInfo">
      <div className="-divisions-">
        <div className="-divisions">{get_global_divisions()}</div>
      </div>
    </div>
  )
}
