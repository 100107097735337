import React from 'react'

export default function Squad() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 21.5">
      <circle
        cx="10.75"
        cy="10.75"
        r="10"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        className="guts"
        d="M14.75,12.25c0,0-2.946-3-4-3c-1.054,0-4,3-4,3"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
