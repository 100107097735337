import React, { useEffect, useRef, useState } from 'react'

import { fun } from '../.fun'
import { community_name, NA, src } from '../.core'
import { PROJECT_NAME, desktop, mobile } from './ROUTERJS'

import lib from '../data/lib.json'

import _squad_ from '../svg/Squad'

export default function Home({
  props: { squad_batch, user, _community_id, _route, _squad_id, join_squad },
}) {
  function get_dummy(num) {
    return Array.from({ length: num }, (_) => null).map((e) => (
      <div className="dummy"></div>
    ))
  }

  function get_joinable_communities() {
    const community = (id, obj) => (
      <div
        className="-f-a --joinable community hover-low"
        // onMouseEnter={() => {
        //   setHovered(squad_obj.squad)
        // }}
        // onMouseLeave={() => {
        //   setHovered(null)
        // }}
        onClick={() => _route('Community') + _community_id(id)}
      >
        <img
          id={obj.id.split('.')[1]}
          src={src(`community-icons/${obj.img}`)}
          alt=""
        />
        <div className="posrel">
          <p className="t">{community_name(obj.name)}</p>
          <p className="sub dim">{obj.sub}</p>
        </div>
      </div>
    )
    const communities = Object.entries(lib.communities).map(([key, val]) => {
      if (!(key in user.communities)) {
        return community(key, val)
      }
    })
    return (
      communities.length > 0 && (
        <div
          id="joinable-communities"
          className={desktop ? 'grid-gap-bottom' : ''}
        >
          <p className="header">Joinable</p>
          <div className="communities">{communities}</div>
        </div>
      )
    )
  }

  function get_my_communities() {
    const community = (community_id, community_obj, squad_obj) => (
      <div
        className="-f-a community --my hover-low"
        // onMouseEnter={() => {
        //   setHovered(squad_obj.squad)
        // }}
        // onMouseLeave={() => {
        //   setHovered(null)
        // }}
        onClick={() =>
          _route('App') +
          _community_id(community_id) +
          _squad_id(squad_obj.squad)
        }
      >
        <img
          id={community_id.split('.')[1]}
          src={src(`community-icons/${community_obj.img}`)}
          alt=""
        />
        <p className="t">
          <span>{community_name(community_obj.name, 'short')}</span>
          <span className="squad-id inline smaller semibold dim">
            #{squad_obj.squad}
          </span>
          {community_obj.counter && (
            <span className="day-x smaller">
              {' - Day '}
              {fun().msDiff(squad_obj.meta.inception, 'days')}
            </span>
          )}
        </p>
        {stats(squad_obj)}
      </div>
    )
    const stats = (squad_obj) => (
      <p className="stat-current ico">
        {squad_obj.stat_current + ' '}
        <_squad_ />
      </p>
    )
    const communities = Object.keys(squad_batch)
      .sort()
      .map((e) => {
        const community_obj = lib.communities[e]
        const squad_obj = squad_batch[e]
        return community(e, community_obj, squad_obj)
      })
    return (
      <div id="my-communities" className={desktop ? 'grid-gap-bottom' : ''}>
        <p className="header">My Communities</p>
        <div className="communities">{communities}</div>
      </div>
    )
  }

  return (
    <div
      id="-HOME2-"
      style={mobile ? { height: window.innerHeight + 'px' } : {}}
    >
      <div className="exhibit posrel">
        <div className="-credentials">
          <div className="-img">
            <img id="pilot-logo" src={src('logo-negative.svg')} alt="" />
          </div>
          <p className="name">{PROJECT_NAME.capitalized} Pilot</p>
        </div>
      </div>
      {get_my_communities()}
      {get_joinable_communities()}
      {/* <div className="-rules posrel">
        {mobile && mob_ps ? (
          <p ref={ruleAboutRef} className="rule-about color">
            {lib.rules_about[mob_ps]}
          </p>
        ) : null}
        <p className="t">Rules:</p>
        {get_rules()}
      </div> */}
      <div id="-footer">
        <div id="footer">
          <p
            className="rlink"
            onClick={() => alert('Do challenges in a squad.')}
          >
            About
          </p>
          <p className="rlink" onClick={() => alert(NA)}>
            Contact
          </p>
          <p className="rlink" onClick={() => alert(NA)}>
            Help
          </p>
          {get_dummy(2)}
          {get_dummy(2)}
          <p>© foo 2024</p>
        </div>
      </div>
    </div>
  )
}
