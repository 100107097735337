import React, { useEffect, useRef, useState } from 'react'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'

import { src } from '../.core'

import { desktop } from '../components/ROUTERJS.js'

import lib_data from '../data/lib.data.json'

import ArrowBack from '../svg/ArrowBack.js'
import _spinner_ from '../svg/Spinner.js'
import _tick_ from '../svg/Tick.js'

export default function Join({
  props: { awaiting, loggingIn, backend, setParentState },
}) {
  const iso3166 = require('iso-3166-1')
  const colloquialCountries = require('i18n-iso-countries')

  colloquialCountries.registerLocale(
    require('i18n-iso-countries/langs/en.json')
  ) // ?? necessary but what is it?

  const emailRef = useRef()
  const passwordRef = useRef()

  const getImageRef = useRef()
  const usernameRef = useRef()
  const countryRef = useRef()

  const [entryMethod, _entryMethod] = useState(true)

  const [invalid_email, _invalid_email] = useState(false)
  const [invalid_password, _invalid_password] = useState(false)
  const [password_error, _password_error] = useState(null)

  const [forgot, _forgot] = useState(false)

  const [checked_username, _checked_username] = useState(null)
  const [checked_country, _checked_country] = useState(null)

  const [username, _username] = useState('')
  const [checking_username, _checking_username] = useState(false)

  const [err, _err] = useState(null)

  const [searchResults, _searchResults] = useState([])
  const [showOverseasTerritories, _showOverseasTerritories] = useState(false)
  const [showResults, _showResults] = useState(false)

  const [arrowSelection, _arrowSelection] = useState(-1)

  const [progress, _progress] = useState(1) // 🟡
  const [fin, _fin] = useState(false)

  // DEV
  const $$flagType$$ = 'span' // or 'img'
  const $$preventDefaultStartup$$ = false
  // DEV

  let default_username = 'TranquilOcean992'

  let form_data = {
    username: null,
    country: null,
  }

  let tCheck

  const handleClick = (ev) => {
    let targetElement = ev.target
    while (targetElement) {
      if (targetElement.id && targetElement.id === 'form-country') {
        return
      }
      targetElement = targetElement.parentElement
    }
    _showResults(false)
    searchFlag('single')
  }

  const handleKeyDown = (ev) => {
    switch (ev.key) {
      case 'Escape':
        _showResults(false)
        break
      case 'Delete':
        countryRef.current.value = ''
        _checked_country(null)
        _searchResults([])
        _showResults(false)
        break
      case 'Enter':
        if (!isNaN(arrowSelection) && arrowSelection > -1) {
          let value = searchResults[arrowSelection]
          if (Array.isArray(value)) {
            value = value[0]
          }
          countryRef.current.value = value
          countryRef.current.blur()
          _checked_country(value)
          _showResults(false)
        } else {
          if (searchFlag('single')) {
            _showResults(false)
            countryRef.current.blur()
          }
        }
        break
      case 'ArrowDown':
        if (showResults && searchResults.length > 0) {
          ev.preventDefault()
          if (
            arrowSelection !== 'suspend' &&
            arrowSelection < searchResults.length
          ) {
            const new_value = arrowSelection + 1
            _arrowSelection(new_value)
            const elm = document.getElementById(`result-${new_value}`)
            elm.scrollIntoView({ behavior: 'auto', block: 'nearest' })
          }
        }
        break
      case 'ArrowUp':
        if (showResults && searchResults.length > 0) {
          ev.preventDefault()
          if (arrowSelection !== 'suspend' && arrowSelection > 0) {
            const new_value = arrowSelection - 1
            _arrowSelection(new_value)
            const elm = document.getElementById(`result-${new_value}`)
            elm.scrollIntoView({ behavior: 'auto', block: 'nearest' })
          }
        }
        break
      default:
        break
    }
  }

  const handleChange = function (event) {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = function (e) {
        const preview = document.getElementById('profileImage')
        preview.src = e.target.result
        preview.style.display = 'block'
      }
      reader.readAsDataURL(file)
    }
  }

  function signInUp() {
    // const ready_email = test('email', emailRef.current.value)
    const ready_email = true
    const ready_password = test('password', passwordRef.current.value)
    if (entryMethod === true) {
      if (ready_email && ready_password) {
        try {
          if (passwordRef.current.value === 'incorrect') {
            throw new Error('Incorrect Password')
          }
        } catch (error) {
          _forgot(true)
          return
        }
        backend('login', emailRef.current.value)
      } else if (!ready_password) {
        _forgot(true)
      }
    }
    if (entryMethod === false) {
      if (ready_email && ready_password) {
        _progress(2)
      }
    }
  }

  function packageUp(params) {
    form_data.username = username
    form_data.country = checked_country
    _progress(3)
    // backend('create')
  }

  function searchFlag(searchType) {
    // console.log('... searching for flag')
    if (arrowSelection !== 'suspend') {
      _arrowSelection(-1)
    }
    _showOverseasTerritories(false)
    _checked_country(null)
    const input = countryRef.current.value.toLowerCase()
    if (input.length > 0) {
      let foundACountry = false
      const substr_match = (item) => {
        if (Array.isArray(item)) {
          const [decorator, val] = item[1].split(':')
          if (item[0].toLowerCase().startsWith(input)) {
            if (decorator !== 't') {
              foundACountry = item[0]
            }
            return true
          }
          switch (decorator) {
            case 'a':
              if (val.includes(',')) {
                if (
                  val
                    .split(',')
                    .some((element) => element.toLowerCase().startsWith(input))
                ) {
                  foundACountry = item[1]
                  return true
                }
              } else {
                if (val.toLowerCase().startsWith(input)) {
                  foundACountry = item[1]
                  return true
                }
              }
              break
            case 's':
              if (val.includes(',')) {
                if (
                  val
                    .split(',')
                    .some((element) => element.toLowerCase().startsWith(input))
                ) {
                  foundACountry = item[1]
                  return true
                }
              } else {
                if (val.toLowerCase().startsWith(input)) {
                  foundACountry = item[1]
                  return true
                }
              }
              break
            default:
              return false
          }
        } else {
          if (item.toLowerCase().startsWith(input)) {
            foundACountry = item
            return true
          } else {
            return false
          }
        }
      }
      let results = lib_data.accepted_countries.filter(substr_match)
      if (searchType === 'single') {
        let exactMatch = null
        // if exact match
        for (const result of results) {
          if (Array.isArray(result)) {
            if (result[0].toLowerCase() === input) {
              exactMatch = result[0]
              break
            }
            const [decorator, val] = result[1].split(':')
            switch (decorator) {
              case 'a':
                if (val.includes(',')) {
                  if (
                    val
                      .split(',')
                      .some((element) => element.toLowerCase() === input)
                  ) {
                    exactMatch = result[0]
                  }
                } else {
                  if (val.toLowerCase() === input) {
                    exactMatch = result[0]
                  }
                }
                break
              case 's':
                if (val.includes(',')) {
                  if (
                    val
                      .split(',')
                      .some((element) => element.toLowerCase() === input)
                  ) {
                    exactMatch = result[0]
                  }
                } else {
                  if (val.toLowerCase() === input) {
                    exactMatch = item[1]
                  }
                }
                break
              default:
                break
            }
          } else {
            if (result.toLowerCase() === input) {
              exactMatch = result
              break
            }
          }
        }
        if (exactMatch) {
          countryRef.current.value = exactMatch
          _checked_country(exactMatch)
          return true
        } else {
          _checked_country(false)
          return false
        }
      } else {
        // # post results
        if (!foundACountry || input.length > 7) {
          _showOverseasTerritories(true)
        }
        _searchResults(results)
        _showResults(true)
      }
    } else {
      _searchResults([])
      _showResults(false)
    }
  }

  function getFlag(country) {
    const getCode = () => {
      switch (country) {
        case 'Catalonia':
          return 'ES-CT'
        case 'British Overseas Territory':
          return 'GB'
        case 'England':
          return 'GB-ENG'
        case 'Scotland':
          return 'GB-SCT'
        case 'U.S. Territory':
          return 'US'
        case 'Wales':
          return 'GB-WLS'
        default:
          const alpha2Code = colloquialCountries.getAlpha2Code(country, 'en')
          if (alpha2Code) {
            return alpha2Code
          } else {
            alert(`i18n-iso-countries has no record for: ${country}`)
            return
          }
      }
    }
    const svgCode = getCode()
    if (svgCode.length > 2) {
      return $$flagType$$ === 'span'
        ? `url(${src('3x2/principal-subdivisions/' + svgCode + '.svg')})`
        : src('3x2/principal-subdivisions/' + svgCode + '.svg')
    } else {
      return $$flagType$$ === 'span'
        ? `url(${src('3x2/' + svgCode + '.svg')})`
        : src('3x2/' + svgCode + '.svg')
    }
  }

  async function check_username() {
    console.log('@async check_username()')
    return new Promise((resolve) => {
      if (checked_username === true) {
        console.log('already checked')
        resolve(true)
        return
      }
      const value = usernameRef.current.value
      if (value.length === 0) {
        resolve(true)
        return
      }
      // ... username viability check
      const testResult = test('username', value)
      if (testResult[0] === true) {
        if (checking_username) {
          clearTimeout(tCheck)
        } else {
          _checking_username(true)
        }
        let checked = null
        // ... username availability check
        if (value === 'unavailable') {
          checked = 'unavailable'
        } else {
          checked = true
        }
        // simulated response time
        tCheck = setTimeout(() => {
          _checked_username(checked)
          _checking_username(false)
          resolve(checked)
        }, 1000)
      } else {
        console.log('test failed')
        _checked_username(false)
        _checking_username(false)
        resolve(false)
      }
    })
  }

  function test(type, value) {
    if (type === 'email') {
      if (value.length === 0) return
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const validEmail = emailPattern.test(value)
      if (!validEmail) {
        _invalid_email(true)
        return false
      } else {
        return true
      }
    }
    if (type === 'password') {
      if (value.length === 0) return
      if (value.length < 8) {
        if (!entryMethod) {
          _password_error('length')
        }
        _invalid_password(true)
        return false
      } else {
        return true
      }
    }
    if (type === 'username') {
      const forbiddenCharsCheck = (value) => {
        const forbiddenChars = ',!@#$%^&*()'
        const forbiddenRegex = new RegExp(`[${forbiddenChars}]`, 'g')
        const matches = value.match(forbiddenRegex)
        return matches || []
      }
      const forbiddenCharsResult = forbiddenCharsCheck(value)
      console.log('forbiddenCharsResult -> ', forbiddenCharsResult)
      if (forbiddenCharsResult.length === 0) {
        _err(null)
        if (value.length > 4) {
          return [true]
        } else {
          return ['length', false]
        }
      } else {
        _err(
          `forbidden character${
            forbiddenCharsResult.length > 1 ? 's' : ''
          }: ${forbiddenCharsResult.join('')}`
        )
        return ['forbidden', forbiddenCharsResult]
      }
    }
  }

  useEffect(() => {
    if (progress === 2) {
      document.addEventListener('click', handleClick)
      getImageRef.current.addEventListener('change', handleChange)
    }

    return () => {
      document.removeEventListener('click', handleClick)
      if (getImageRef.current) {
        getImageRef.current.removeEventListener('change', handleChange)
      }
    }
  }, [progress])

  useEffect(() => {
    if ($$preventDefaultStartup$$) return
    const elm = document.getElementById('button-Google')
    const span = elm.querySelector('span')
    if (span) {
      span.innerText = entryMethod
        ? 'Sign in with Google'
        : 'Continue with Google'
    }
    passwordRef.current.value = ''
    _invalid_password(false)
    _password_error(null)
    _forgot(false)
  }, [entryMethod])

  useEffect(() => {
    if (fin) {
      const ready_username =
        checked_username !== false && checked_username !== 'unavailable'
      const ready_country =
        checked_country !== false &&
        !(checked_country === null && countryRef.current.value.length > 0)
      if (ready_username && ready_country) {
        if (username === '' && checked_country === null) {
          const userConfirmed = window.confirm('Continue with default values?')
          if (userConfirmed) {
            packageUp()
          } else {
            _fin(false)
          }
        } else {
          packageUp()
        }
      } else {
        _fin(false)
      }
    }
  }, [fin, checked_username, checked_country])

  return (
    <div
      id="Join"
      progress={'_' + progress}
      style={{ height: window.innerHeight + 'px' }}
    >
      <div className={'panel ' + (desktop ? 'xy' : '')}>
        <div
          className="click-me"
          onClick={() =>
            progress === 2 ? _progress(1) : setParentState({ page: 'login' })
          }
        >
          <ArrowBack />
        </div>
        {progress === 1 ? (
          <>
            <div id="entryMethod" className="-f-ja">
              <p
                className={entryMethod ? 'selected' : ''}
                onClick={() => _entryMethod(true)}
              >
                Sign In
              </p>
              <p
                className={entryMethod ? '' : 'selected'}
                onClick={() => _entryMethod(false)}
              >
                Sign Up
              </p>
            </div>
            <div className="sign-in-email">
              <input
                ref={emailRef}
                className={invalid_email ? 'error' : ''}
                type="text"
                placeholder="Email"
                onChange={() => _invalid_email(false)}
                onBlur={(e) => test('email', e.target.value)}
              />
              <div className="posrel stack">
                <input
                  ref={passwordRef}
                  className={invalid_password ? 'error' : ''}
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    _invalid_password(false)
                    _password_error(null)
                  }}
                  onBlur={(e) => test('password', e.target.value)}
                />
                <p className="sub">
                  {password_error === 'length' && 'Minumum 8 characters.'}
                </p>
                {forgot && (
                  <p className="forgot sub rlink" onClick={() => null}>
                    Forgot login?
                  </p>
                )}
              </div>
              <div id="wrapper-sign-in-up" className="-f-j">
                <div className="posrel">
                  <p
                    id="sign-in-up"
                    className="progressHandle"
                    onClick={() => signInUp()}
                  >
                    {entryMethod ? 'Sign In' : 'Continue'}
                  </p>
                  {(awaiting || loggingIn) && (
                    <div
                      className={'-spinner ' + (loggingIn ? 'loggingIn' : '')}
                    >
                      <_spinner_ />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div id="button-Google" className="sign-in-api Google">
              {/* <button class="gsi-material-button">
                <div class="gsi-material-button-state"></div>
                <div class="gsi-material-button-content-wrapper">
                  <div class="gsi-material-button-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      ></path>
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      ></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                  <span class="gsi-material-button-contents">
                    {entryMethod
                      ? 'Sign in with Google'
                      : 'Continue with Google'}
                  </span>
                  <span style="display: none;">Continue with Google</span>
                </div>
              </button> */}
              <GoogleOAuthProvider clientId="295424987834-hr9n38vflas0vcmhm9e1ojbcscoj176s.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    console.log(credentialResponse)
                  }}
                  onError={() => {
                    console.log('Login Failed')
                  }}
                />
              </GoogleOAuthProvider>
            </div>
            {/* <div class="g-signin2" data-onsuccess="onSignIn"></div> */}
            <div className="-f-a sign-in-api Facebook">
              <div className="-icon">
                <img src={src('svg/3rd-Party/Facebook-white.svg')} alt="" />
              </div>
              <p>
                {entryMethod
                  ? 'Sign in with Facebook'
                  : 'Continue with Facebook'}
              </p>
            </div>
          </>
        ) : null}
        {progress === 2 ? (
          <>
            <img
              id="profileImage"
              src={src('img-dummy/dummy-img-1.jpg')}
              alt=""
              onClick={() => getImageRef.current.click()}
            />
            <input
              type="file"
              ref={getImageRef}
              id="getProfileImage"
              name="getProfileImage"
              accept="image/*"
              required
              style={{ display: 'none' }}
            ></input>
            <div
              className={
                '-input ' + (checked_username === false ? 'incorrect' : '')
              }
            >
              <p className="label">
                username <span className="err">{err}</span>
              </p>
              <input
                ref={usernameRef}
                type="username"
                spellcheck="false"
                placeholder={default_username}
                onKeyDown={(ev) =>
                  ev.key === 'Enter' && !checked_username && check_username()
                }
                onChange={(e) =>
                  _checked_username(null) +
                  _username(e.target.value) +
                  test('username', e.target.value)
                }
                onBlur={() => !checked_username && check_username()}
              />
              <div className="shadow">
                <p id="p-shadow">{username}</p>
                <div className="-check">
                  {checking_username ? (
                    <div className="-spinner">
                      <_spinner_ />
                    </div>
                  ) : checked_username === true ? (
                    <div className="-tick tick-stroke">
                      <_tick_ type={'stroke'} />
                    </div>
                  ) : checked_username === 'unavailable' ? (
                    <p id="unavailable">unavailable</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              id="form-country"
              className={
                '-input stack-2 ' +
                (checked_country === false ? 'incorrect' : '')
              }
            >
              <p className="label">country</p>
              <div className="-f-a">
                {checked_country ? (
                  $$flagType$$ === 'span' ? (
                    <span
                      className="flag push-half pseudo-top-mini"
                      style={{
                        backgroundImage: getFlag(checked_country),
                      }}
                    >
                      <span className="pseudo">{checked_country}</span>
                    </span>
                  ) : (
                    <div className="posrel pseudo-top-mini">
                      <img
                        className="flag push-half"
                        src={getFlag(checked_country)}
                        alt=""
                      />
                      <p className="pseudo">{checked_country}</p>
                    </div>
                  )
                ) : null}
                <input
                  ref={countryRef}
                  type="country"
                  spellcheck="false"
                  placeholder="None"
                  onFocus={() =>
                    countryRef.current.value.length > 0
                      ? _showResults(true)
                      : null
                  }
                  onKeyDown={(ev) => handleKeyDown(ev)}
                  onChange={() => searchFlag()}
                />
              </div>
              <div
                className="drop-menu"
                onMouseEnter={() => _arrowSelection('suspend')}
                onMouseLeave={() => _arrowSelection(-1)}
                style={{
                  display: showResults ? 'block' : 'none',
                }}
              >
                {searchResults.length > 0 ? (
                  searchResults.map((e, index) => {
                    const isOverseasTerritory =
                      Array.isArray(e) && e[1].includes('t:')
                    if (isOverseasTerritory && !showOverseasTerritories) {
                      return null
                    } else {
                      const value = Array.isArray(e) ? e[0] : e
                      const undecoratedOverseasTerritory =
                        isOverseasTerritory && e[1].split(':')[1]
                      return (
                        <p
                          key={value}
                          id={`result-${index}`}
                          className={
                            'menu-item ' +
                            (isOverseasTerritory ? 'overseas-territory ' : '') +
                            (arrowSelection === index ? 'highlight' : '')
                          }
                          onClick={() => {
                            countryRef.current.value = isOverseasTerritory
                              ? undecoratedOverseasTerritory
                              : value
                            _checked_country(
                              isOverseasTerritory
                                ? undecoratedOverseasTerritory
                                : value
                            )
                            _showResults(false)
                          }}
                        >
                          {isOverseasTerritory ? (
                            <>
                              {value}
                              <span>{` ${undecoratedOverseasTerritory}`}</span>
                            </>
                          ) : (
                            value
                          )}
                        </p>
                      )
                    }
                  })
                ) : (
                  <p>Not found</p>
                )}
              </div>
            </div>
            <p id="fin" className="progressHandle" onClick={() => _fin(true)}>
              Finish
            </p>
          </>
        ) : null}
        {progress === 3 ? (
          <div className="xy -tick">
            <_tick_ />
          </div>
        ) : null}
      </div>
    </div>
  )
}
