import React from 'react'
import { useSelector } from 'react-redux'

export default function MobNavBar({ route }) {
  const { gChatRooms, gFocus, gJournal } = useSelector((state) => ({
    gChatRooms: state.chatRooms,
    gFocus: state.focus,
    gJournal: state.journal,
  }))

  return (
    <div
      id="mobile-navbar"
      style={{
        display:
          ['Home', 'Community'].includes(route) ||
          gChatRooms ||
          gFocus ||
          gJournal
            ? 'none'
            : 'block',
      }}
    ></div>
  )
}
