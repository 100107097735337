import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { rdx } from '../actions.js'

import { NA } from '../.core.js'

import { desktop, mobile } from './ROUTERJS'
import { user_img } from './ROUTERJS'

import COG from '../svg/COG.js'
import MsgDM from '../svg/MsgDM.js'
import X from '../svg/X.js'

export default function Profile({
  props: {
    Dev,
    theme,
    theme_exe,
    setLoggedIn,
    setDev,
    profile_open,
    _profile_open,
    _betaPortal,
  },
}) {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [isNA, setIsNA] = useState()
  const [relapseOption, setRelapseOption] = useState(false)

  const [devOption, setDevOption] = useState(false)

  let counter = 0

  const icons = (id) => {
    const icon = {
      light: (
        <svg
          id="light"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
            stroke="currentColor"
            stroke-width="1.5"
          />
          <path
            d="M11.9955 3H12.0045M11.9961 21H12.0051M18.3588 5.63599H18.3678M5.63409 18.364H5.64307M5.63409 5.63647H5.64307M18.3582 18.3645H18.3672M20.991 12.0006H21M3 12.0006H3.00898"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      dark: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            d="M21.5 14.0784C20.3003 14.7189 18.9301 15.0821 17.4751 15.0821C12.7491 15.0821 8.91792 11.2509 8.91792 6.52485C8.91792 5.06986 9.28105 3.69968 9.92163 2.5C5.66765 3.49698 2.5 7.31513 2.5 11.8731C2.5 17.1899 6.8101 21.5 12.1269 21.5C16.6849 21.5 20.503 18.3324 21.5 14.0784Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      profile: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="1.5"
          />
          <path
            d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      ),
      messages: <MsgDM />,
      'my wins': (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 12V18"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M12 18C10.3264 18 8.86971 19.012 8.11766 20.505C7.75846 21.218 8.27389 22 8.95877 22H15.0412C15.7261 22 16.2415 21.218 15.8823 20.505C15.1303 19.012 13.6736 18 12 18Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M5 5H3.98471C2.99819 5 2.50493 5 2.20017 5.37053C1.89541 5.74106 1.98478 6.15597 2.16352 6.9858C2.50494 8.57086 3.24548 9.9634 4.2489 11"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M19 5H20.0153C21.0018 5 21.4951 5 21.7998 5.37053C22.1046 5.74106 22.0152 6.15597 21.8365 6.9858C21.4951 8.57086 20.7545 9.9634 19.7511 11"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M12 12C15.866 12 19 8.8831 19 5.03821C19 4.93739 18.9978 4.83707 18.9936 4.73729C18.9509 3.73806 18.9295 3.23845 18.2523 2.61922C17.5751 2 16.8247 2 15.324 2H8.67596C7.17526 2 6.42492 2 5.74772 2.61922C5.07051 3.23844 5.04915 3.73806 5.00642 4.73729C5.00215 4.83707 5 4.93739 5 5.03821C5 8.8831 8.13401 12 12 12Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      ),
      settings: (
        <COG />
        // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        //   <path
        //     d="M20.3584 13.3567C19.1689 14.546 16.9308 14.4998 13.4992 14.4998C11.2914 14.4998 9.50138 12.7071 9.50024 10.4993C9.50024 7.07001 9.454 4.83065 10.6435 3.64138C11.8329 2.45212 12.3583 2.50027 17.6274 2.50027C18.1366 2.49809 18.3929 3.11389 18.0329 3.47394L15.3199 6.18714C14.6313 6.87582 14.6294 7.99233 15.3181 8.68092C16.0068 9.36952 17.1234 9.36959 17.8122 8.68109L20.5259 5.96855C20.886 5.60859 21.5019 5.86483 21.4997 6.37395C21.4997 11.6422 21.5479 12.1675 20.3584 13.3567Z"
        //     stroke="currentColor"
        //     strokeWidth="1.5"
        //   />
        //   <path
        //     d="M13.5 14.5L7.32842 20.6716C6.22386 21.7761 4.433 21.7761 3.32843 20.6716C2.22386 19.567 2.22386 17.7761 3.32843 16.6716L9.5 10.5"
        //     stroke="currentColor"
        //     strokeWidth="1.5"
        //     strokeLinecap="round"
        //   />
        //   <path
        //     d="M5.50896 18.5H5.5"
        //     stroke="currentColor"
        //     strokeWidth="2"
        //     strokeLinecap="round"
        //     strokeLinejoin="round"
        //   />
        // </svg>
      ),
      'sign out': (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            d="M14 3.09502C13.543 3.03241 13.0755 3 12.6 3C7.29807 3 3 7.02944 3 12C3 16.9706 7.29807 21 12.6 21C13.0755 21 13.543 20.9676 14 20.905"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M21 12L11 12M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      'give feedback': (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            d="M8 13.5H16M8 8.5H12"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.09881 19C4.7987 18.8721 3.82475 18.4816 3.17157 17.8284C2 16.6569 2 14.7712 2 11V10.5C2 6.72876 2 4.84315 3.17157 3.67157C4.34315 2.5 6.22876 2.5 10 2.5H14C17.7712 2.5 19.6569 2.5 20.8284 3.67157C22 4.84315 22 6.72876 22 10.5V11C22 14.7712 22 16.6569 20.8284 17.8284C19.6569 19 17.7712 19 14 19C13.4395 19.0125 12.9931 19.0551 12.5546 19.155C11.3562 19.4309 10.2465 20.0441 9.14987 20.5789C7.58729 21.3408 6.806 21.7218 6.31569 21.3651C5.37769 20.6665 6.29454 18.5019 6.5 17.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      ),
      dev: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            d="M17 8L18.8398 9.85008C19.6133 10.6279 20 11.0168 20 11.5C20 11.9832 19.6133 12.3721 18.8398 13.1499L17 15"
            stroke={Dev ? 'palegreen' : 'cornflowerblue'}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 8L5.16019 9.85008C4.38673 10.6279 4 11.0168 4 11.5C4 11.9832 4.38673 12.3721 5.16019 13.1499L7 15"
            stroke={Dev ? 'palegreen' : 'cornflowerblue'}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.5 4L9.5 20"
            stroke={Dev ? 'palegreen' : 'cornflowerblue'}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    }
    return <div className="-icon">{icon[id]}</div>
  }

  const handlePress2 = () => {
    counter += 1
    if (counter >= 10) {
      setDevOption(true)
    }
  }

  function drop() {
    const item = (id, action = null) => (
      <div
        className="-f-a"
        onClick={() => (action ? action() + _profile_open(false) : alert(NA))}
      >
        {icons(id.toLowerCase())}
        <p>{id}</p>
      </div>
    )
    return (
      <div
        id="profile-menu"
        className="--f-j"
        style={{ height: mobile ? window.innerHeight + 'px' : '100vh' }}
        onMouseLeave={() => desktop && _profile_open(false)}
      >
        {mobile && (
          <div className="-X" onClick={() => _profile_open(false)}>
            <X />
          </div>
        )}
        <div className="menu-items-wrapper">
          {item('Profile')}
          {item('Messages', () => dispatch(rdx('MESSAGES', true)))}
          {item('My Wins')}
          {item('Settings')}
          {/* <div className="-f-a" onClick={() => theme_exe()}>
            <div id="-theme">
              <div
                className="-icon-"
                style={{ top: theme === 'dark' ? '2px' : '110%' }}
              >
                {icons('dark')}
              </div>
              <div
                className="-icon-"
                style={{ top: theme === 'dark' ? '110%' : 0 }}
              >
                {icons('light')}
              </div>
            </div>
            <p>Theme</p>
          </div> */}
          {item('Sign Out', () => _profile_open(false) + setLoggedIn(false))}
          <div className="separator" style={{ width: '100%' }}></div>
          {item(
            'Give Feedback',
            () => _profile_open(false) + _betaPortal(true)
          )}
          {mobile ? (
            <>
              <div
                className="-f-a"
                style={{ display: relapseOption ? 'flex' : 'none' }}
                // onClick={() => setOverlay('RESET')}
              >
                {icons('relapse')}
                <p
                  style={{
                    color: 'red',
                  }}
                >
                  RESET
                </p>
              </div>
              <div
                id="developer"
                className="-f-a"
                style={{ display: devOption ? 'flex' : 'none' }}
                onClick={() => setDev(true)}
              >
                {icons('dev')}
                <p
                  style={{
                    color: Dev ? 'palegreen' : 'cornflowerblue',
                  }}
                >
                  Developer
                </p>
              </div>
            </>
          ) : null}
        </div>
        {/* {mobile ? (
          <div
            className="relapse-box"
            onTouchStart={handlePress}
            onTouchEnd={handleRelease}
            // onMouseDown={(e) => handlePress(e)}
            // onMouseUp={handleRelease}
          ></div>
        ) : null} */}
      </div>
    )
  }

  return profile_open && drop()
}
