import React, { useEffect, useRef, useState } from 'react'

import { backend } from '../.core.js'
import { fun } from '../.fun.js'
import { DJANGO, user_id } from '../components/ROUTERJS.js'

import Toolbar from './Toolbar'
import _media_img from '../svg/Media-Img.js'
import _media_txt from '../svg/Media-Txt.js'

export default function CreateEntry({
  props: { entryType, _createEntry, _journal, handleEntries, handleJournals },
}) {
  const titleRef = useRef()
  const txtRef = useRef()
  const mediaRef = useRef()
  const getMediaRef = useRef()

  const [postType, _postType] = useState('txt')
  const [giveTitle, _giveTitle] = useState(false)
  const [editTitle, _editTitle] = useState(false)
  const [preview, _preview] = useState(null)

  const isEntry = entryType === 'entry'
  const isJournal = entryType === 'journal'

  function post_form() {
    console.log(mediaRef.current)
    const form_data = {
      user: user_id,
      date: fun().dateAgoIn(),
      score: 0,
      entry_type: postType,
      src: postType === 'media' && null,
      title: isEntry && titleRef.current.value,
      body: postType === 'txt' && txtRef.current.value,
      media: postType === 'media' && mediaRef.current,
    }
    console.log(form_data)
    if (isEntry) {
      if (DJANGO) {
      } else {
        handleEntries('240916093000', form_data)
      }
    } else if (isJournal) {
      if (DJANGO) {
        backend('__squad/add_journal/', { body: { form_data } }).then(
          (response) => {
            if (response.status === 200) {
              handleJournals('add', response.body.journal_id, form_data)
            }
          }
        )
      } else {
        handleJournals('add', 'dummy-id', form_data)
      }
    }
  }

  useEffect(() => {
    _journal(null)
    getMediaRef.current.addEventListener('change', function (event) {
      const file = event.target.files[0]
      if (file) {
        mediaRef.current = file
        const reader = new FileReader()
        reader.onload = function (e) {
          _preview(e.target.result)
        }
        reader.readAsDataURL(file)
      }
    })
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    if (giveTitle) {
      titleRef.current.focus()
    }
  }, [giveTitle])

  return (
    <div id="CreateEntry">
      <Toolbar
        pathway={() => _createEntry(false)}
        title={isJournal ? 'Journal Entry' : 'Create Post'}
      />
      <div className="form clear-toolbars-title">
        <div className="type">
          <div
            className={'click-me ' + (postType === 'txt' ? 'current' : '')}
            style={{ marginLeft: 'auto' }}
            onClick={() => _postType('txt')}
          >
            <div className="posrel">
              <_media_txt color={postType === 'txt'} />
              <p className="x">txt</p>
            </div>
          </div>
          <div
            className={'click-me ' + (postType === 'img' ? 'current' : '')}
            style={{ marginLeft: '5rem', marginRight: 'auto' }}
            onClick={() => _postType('img')}
          >
            <div className="posrel">
              <_media_img color={postType === 'img'} />
              <p className="x">media</p>
            </div>
          </div>
        </div>
        {isEntry && (
          <div className="posrel">
            <input
              ref={titleRef}
              className={
                'title ' +
                (!editTitle && titleRef.current?.value.length && 'hasTitle')
              }
              placeholder="Title..."
              type="text"
              // style={{ visibility: giveTitle ? 'visible' : 'hidden' }}
              onFocus={() => _editTitle(true)}
              onBlur={(e) =>
                _editTitle(false) +
                (!e.target.value.length && _giveTitle(false))
              }
            />
            {/* <p
              id="p-give-title"
              className="y rlink"
              style={{ display: giveTitle ? 'none' : 'block' }}
              onClick={() => _giveTitle(true)}
            >
              Give your post a title? (optional)
            </p> */}
          </div>
        )}
        <textarea
          ref={txtRef}
          className="body textarea"
          placeholder="What's on your mind today?"
          type="text"
          style={{ display: postType === 'txt' ? 'block' : 'none' }}
        />
        <div
          className={'img-upload' + (preview ? ' preview' : '')}
          style={{
            display: postType === 'img' ? (preview ? 'block' : 'flex') : 'none',
          }}
        >
          {!preview && (
            <button onClick={() => getMediaRef.current.click()}>
              Add Image
            </button>
          )}
          <input
            type="file"
            ref={getMediaRef}
            id="getProfileImage"
            name="getProfileImage"
            accept="image/*"
            required
            style={{ display: 'none' }}
          ></input>
          {preview && <img id="preview" alt="" src={preview} />}
        </div>
        <div className="-f- -submit">
          {preview && (
            <p
              id="change-img-CreateEntry"
              className="push-1"
              onClick={() => getMediaRef.current.click()}
            >
              Change
            </p>
          )}
          <p className="submit hover-low" onClick={() => post_form()}>
            Submit
          </p>
        </div>
      </div>
    </div>
  )
}
