import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rdx } from '../actions.js'

import { backend, NA } from '../.core.js'
import { fun } from '../.fun.js'
import { DJANGO, desktop, mobile, user_id, user_img } from './ROUTERJS'

import Messages from '../components-sub/Messages.js'

import _arrowReturn_ from '../svg/ArrowReturn.js'
import _msgDM_ from '../svg/MsgDM.js'
import X from '../svg/X.js'

export default function Profile({
  props: {
    community_id,
    profile_open,
    route,
    showFarewell,
    user,
    _showFarewell,
    _profile_open,
  },
}) {
  const convos = useRef()

  const { gChatRooms, gEntry, gFocus, gJournal, gLocation, gMessages } =
    useSelector((state) => ({
      gChatRooms: state.chatRooms,
      gEntry: state.entry,
      gFocus: state.focus,
      gJournal: state.journal,
      gLocation: state.location,
      gMessages: state.messages,
    }))
  const dispatch = useDispatch()

  const [showFarewellPrompt, _showFarewellPrompt] = useState(false)

  const tools = {
    Relapse_Report: {
      Component: <_arrowReturn_ />,
      display: showFarewellPrompt,
      isActive: showFarewell,
      fn: () => _showFarewell(true),
    },
    DMs: {
      Component: <_msgDM_ />,
      display: true,
      isActive: false,
      fn: () => dispatch(rdx('MESSAGES', !gMessages)),
    },
  }

  const shouldDisplay = () => {
    if (
      mobile &&
      (route === 'Community' || gChatRooms || gEntry || gFocus || gJournal)
    ) {
      return 'none'
    } else {
      return 'block'
    }
  }

  function get_tools() {
    const tool = (key, obj) => (
      <div
        id={'tool-' + key}
        className={
          'click-me ui-handle -pseudo-bottom' + (obj.isActive ? ' active' : '')
        }
        onClick={() => obj.fn()}
      >
        {obj.Component}
        {key !== 'DMs' && <p className="pseudo">{key.replace('_', ' ')}</p>}
      </div>
    )
    return (
      <div id="right-side-tools" className="top-tools">
        {Object.entries(tools).map(([key, val]) => {
          if (val.display) {
            return tool(key, val)
          }
        })}
      </div>
    )
  }

  function unset() {
    setOpen(false)
    _showFarewell(false)
  }

  useEffect(() => {
    if (DJANGO) {
      const convo_ids = Object.keys(user.convos ?? {})
      if (convo_ids.length) {
        backend('__messhall/get_convos/', {
          body: { convo_ids: convo_ids },
        }).then((respose) => {
          if (respose.status === 200) {
            console.log(respose.body)
            convos.current = respose.body.convos
          }
        })
      }
    }
  }, [])

  useEffect(() => {
    if (route === 'App') {
      const user_comm_data = user.communities_data[community_id]
      if (fun().msDiff([user_comm_data.joined], 'hours') < 24) {
        _showFarewellPrompt(true)
      }
    }
  }, [community_id, user])

  return (
    <>
      <div
        id="Profile"
        className="clickDrop"
        style={{
          display: shouldDisplay(),
        }}
      >
        <div onClick={() => _profile_open(true)}>{user_img}</div>
        {desktop && get_tools()}
      </div>
      {gMessages && <Messages convos={convos} user={user} />}
    </>
  )
}
