import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rdx } from '../actions.js'
import { src } from '../.core.js'
import users from '../data/REACT_APP.users.json'
import '../logo.css'

function LeftPanel({ props: { __JOURNALS__, handleJournals } }) {
  const journals_read = useRef(
    Object.keys(__JOURNALS__).filter((key) => 'read' in __JOURNALS__[key])
  )
  const journals_unread = useRef(
    Object.keys(__JOURNALS__).filter((key) => !('read' in __JOURNALS__[key]))
  )
  const prevJournal = useRef()

  const { gJournal } = useSelector((state) => ({
    gJournal: state.journal,
  }))
  const dispatch = useDispatch()

  const [read, _read] = useState(false)

  function handleJournal(id) {
    if (
      prevJournal.current &&
      journals_unread.current.includes(prevJournal.current)
    ) {
      const copyJournalsHistory = [...journals_read.current]
      const removed_item = journals_unread.current.splice(
        journals_unread.current.indexOf(prevJournal.current),
        1
      )[0]
      copyJournalsHistory.push(removed_item)
      journals_read.current = copyJournalsHistory
    }
    prevJournal.current = id
    handleJournals('read', id)
  }

  function get_journals() {
    let exportjournals = []
    const journal_item = (id, obj) => (
      <div
        className={'-f-a journal-entry' + (obj.blurb ? ' blurb' : '')}
        current={gJournal === id ? 'y' : 'n'}
        onClick={() => dispatch(rdx('JOURNAL', id)) + handleJournal(id)}
      >
        <img className="usr" src={src('img/' + users[obj.user].img)} alt="" />
        <div className="inline">
          <p className="username contrast">{users[obj.user].usn}</p>
          {obj.blurb && (
            <p className="blurb contrast">{obj.blurb.toLowerCase()}</p>
          )}
        </div>
      </div>
    )
    const journals_arr = read ? journals_read.current : journals_unread.current
    journals_arr.forEach((id) => {
      exportjournals.push(journal_item(id, __JOURNALS__[id]))
    })
    return exportjournals
  }

  useEffect(() => {
    if (gJournal) {
      if (__JOURNALS__[gJournal].read) {
        _read(true)
      }
      handleJournal(gJournal)
    }
  }, [])

  return (
    <div id="-left-panel" className="posrel">
      <div id="navigation" className="menu">
        <div className="separator">
          <p className="dim">journalled today</p>
        </div>
        <div className="-f- options">
          <p className={!read ? ' current' : ''} onClick={() => _read(false)}>
            unread
          </p>
          <p className={read ? ' current' : ''} onClick={() => _read(true)}>
            read
          </p>
        </div>
        <div className="grid-menu">{get_journals()}</div>
      </div>
    </div>
  )
}

export default LeftPanel
