import React from 'react'

export default function Chevron({ fill = false }) {
  return (
    <svg
      viewBox="0 0 80 50"
      className={
        'chevron' +
        [fill === true ? 'fill' : fill === 'regular' ? 'fill-regular' : '']
      }
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.879 0.583999L-1.7464e-06 9.463L39.955 49.416L39.999 49.37L40.045 49.416L80 9.463L71.122 0.583997L39.999 31.705L8.879 0.583999Z" />
    </svg>
  )
}
