import React, { useEffect, useState } from 'react'

export default function Battery({ cells = 4, animate = true }) {
  const [cell_all, _cell_all] = useState(true)
  const [cell_1, _cell_1] = useState(true)
  const [cell_2, _cell_2] = useState(true)
  const [cell_3, _cell_3] = useState(true)
  const [cell_4, _cell_4] = useState(true)

  let iPulse

  useEffect(() => {
    if (animate && cells === 1) {
      iPulse = setInterval(() => {
        _cell_1((prev_cell_1) => !prev_cell_1)
      }, 1000)
      return () => {
        clearInterval(iPulse)
      }
    }
    if (animate && cells === 4) {
      iPulse = setInterval(() => {
        _cell_1(false)
        _cell_2(false)
        _cell_3(false)
        _cell_4(false)
        setTimeout(() => {
          _cell_1(true)
          setTimeout(() => {
            _cell_2(true)
            setTimeout(() => {
              _cell_3(true)
              setTimeout(() => {
                _cell_4(true)
              }, 75)
            }, 75)
          }, 75)
        }, 75)
      }, 7000)
      return () => {
        clearInterval(iPulse)
      }
    }
  }, [])

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 13.5" fill="none">
      <path
        d="M17.8,4.2l1,0.2c0.7,0.1,1,0.2,1.3,0.3c0.3,0.2,0.5,0.4,0.6,0.7c0.1,0.3,0.1,0.6,0.1,1.3s0,1-0.1,1.3
	c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.2-1.3,0.3l-1,0.2"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M0.8,6.8c0-2.8,0-4.2,0.9-5.1s2.3-0.9,5.1-0.9h5c2.8,0,4.2,0,5.1,0.9s0.9,2.3,0.9,5.1c0,2.8,0,4.2-0.9,5.1
	s-2.3,0.9-5.1,0.9h-5c-2.8,0-4.2,0-5.1-0.9S0.8,9.6,0.8,6.8z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <g>
        <path
          d="M4.8,4.8v4"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          visibility={cell_1 && cell_all ? 'visible' : 'hidden'}
        />
        {cells > 1 ? (
          <>
            <path
              d="M7.8,4.8v4"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              visibility={cell_2 && cell_all ? 'visible' : 'hidden'}
            />
            <path
              d="M10.8,4.8v4"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              visibility={cell_3 && cell_all ? 'visible' : 'hidden'}
            />
            <path
              d="M13.8,4.8v4"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              visibility={cell_4 && cell_all ? 'visible' : 'hidden'}
            />
          </>
        ) : null}
      </g>
    </svg>
  )
}
