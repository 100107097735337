import React, { useEffect, useState } from 'react'
import _spinner_ from '../svg/Spinner'
import _spinner2_ from '../svg/Spinner2'

export default function DotDotDot({ props: { awaiting, type = 'dots' } }) {
  // const [awaiting, setAwaiting] = useState(true)
  const [dots, setDots] = useState('')

  let ms = 100,
    dotdotdot

  // useEffect(() => {
  //   setTimeout(() => {
  //     setAwaiting(false)
  //   }, 1500)
  // }, [])

  useEffect(() => {
    if (dots === '...') {
      clearTimeout(dotdotdot)
      ms = 1000
    } else {
      ms = 100
    }
    dotdotdot = setTimeout(() => {
      setDots((prevDots) => (prevDots === '...' ? '' : prevDots + '.'))
    }, ms)
    return () => clearTimeout(dotdotdot)
  }, [dots])

  useEffect(() => {
    if (!awaiting) {
      clearTimeout(dotdotdot)
      setDots('')
    }
  }, [awaiting])

  return (
    <p className="logging-in-status">
      {awaiting ? 'authenticating' : 'loading up'}
      {type === 'dots' ? (
        <span className="dots y">{dots}</span>
      ) : (
        <_spinner2_ />
      )}
    </p>
  )
}
