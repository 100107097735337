import React from 'react'

export default function X({ type = 'round' }) {
  return (
    <svg
      className="X"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.0005 4.99988L5.00045 18.9999M5.00045 4.99988L19.0005 18.9999"
        stroke="currentColor"
        stroke-width={type === 'block' ? '5' : '1.5'}
        stroke-linecap={type === 'round' ? 'round' : 'butt'}
        // stroke-linejoin={blocky ? '?' : "round"}
      />
    </svg>
  )
}
