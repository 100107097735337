import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleCHATROOMS, rdx } from '../actions'

import { fun } from '../.fun.js'
import { src } from '../.core.js'
import { mobile, IN_DEPLOYMENT, user_id } from '../components/ROUTERJS'

import lib from '../data/lib.json'

import users from '../data/REACT_APP.users.json'

import Preloader from './Preloader.js'

import _collapse_ from '../svg/Collapse.js'
import _expand_ from '../svg/Expand.js'

export default function Chat({
  props: {
    __CHAT__,
    chatRooms,
    loadingNew,
    squad_id,
    setChatRooms,
    // unsetChatRooms,
    chat_init,
  },
}) {
  const chatRef = useRef()

  const dispatch = useDispatch()

  const { gChatRooms } = useSelector((state) => ({
    gChatRooms: state.chatRooms,
  }))

  const [chat, _chat] = useState(__CHAT__.current)

  const mob_navbar_height = 77

  function backend(msg) {
    fetch('__user/write_chat/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        squad_id: squad_id,
        msg: msg,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        // return response.json()
      })
      // .then((data) => {
      //   // ...
      // })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error)
      })
  }

  function chatHandler(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (!chat) return
      const chat_entry = {
        user: user_id,
        msg: chatRef.current.value,
      }
      if (IN_DEPLOYMENT) {
        backend(chat_entry)
      }
      const copy_chat = { ...chat, chat_entry }
      __CHAT__.current.log = copy_chat.log
      chatRef.current.value = ''
      _chat(copy_chat)
    }
  }

  function get_chat(data) {
    const message = (obj) => {
      // const time = fun().niceDate(obj.date, 'T:t')
      const userID = obj.user
      const user = users[userID]
      return (
        <div className="-f- __msg">
          <img
            className="usr"
            src={src('img-compressed/' + user.img.split('.')[0] + '.webp')}
            alt=""
            onClick={() => dispatch(rdx('FOCUS', userID))}
          />
          <div className={'_msg'}>
            <p className="usn">
              <span
                className="span-usn"
                onClick={() => dispatch(rdx('FOCUS', userID))}
              >
                {user.usn}
              </span>
              {obj.special && <span>{lib.admin.emoji[userID]}</span>}
              {/* <span className="time-chat dim inline">{time}</span> */}
            </p>
            {obj.hasOwnProperty('src') ? (
              <img className="img-submission" src={src(obj.src)} alt="" />
            ) : (
              <p className={'msg' + (obj.special ? ' special' : '')}>
                {obj.msg}
              </p>
            )}
          </div>
        </div>
      )
    }
    return data.map((e) => message(e))
  }

  useEffect(() => {
    if (chat_init) {
      _chat(__CHAT__.current)
    } else {
      _chat(null)
    }
  }, [chat_init])

  useEffect(() => {
    if (!loadingNew && chat_init) {
      _chat(__CHAT__.current)
    }
  }, [loadingNew])

  return (
    <div className="posrel">
      {loadingNew || !chat ? <Preloader /> : null}
      <div
        id="CHAT"
        expand={gChatRooms ? 'true' : 'false'}
        // showMobChatRooms={showMobChatRooms ? 'true' : 'false'}
        style={{
          height: mobile
            ? window.innerHeight -
              (gChatRooms ? 0 : mob_navbar_height + 50) +
              'px'
            : `calc(100vh - ${gChatRooms ? 0 : '2rem'})`,
          visibility: loadingNew ? 'hidden' : 'visible',
        }}
      >
        <div className="-chat">
          <div id="chat">{chat ? get_chat(chat.log) : null}</div>
          <div className="-textarea background">
            <textarea
              ref={chatRef}
              type="text"
              placeholder="Chat..."
              onKeyDown={(e) => chatHandler(e)}
            />
            <div
              id="resize-chat"
              className="click-me tool"
              onClick={() => {
                dispatch(handleCHATROOMS())
                setChatRooms(!chatRooms)
              }}
            >
              {chatRooms ? <_collapse_ /> : <_expand_ />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
