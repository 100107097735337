import React from 'react'

export default function _spinner() {
  return (
    <svg
      className="Spinner"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.071 20.071"
    >
      <path
        d="M18.071,10.036c0,2.159-0.857,4.12-2.247,5.565c-0.348,0.362-0.36,0.93-0.037,1.315l0.003,0.004
	c0.381,0.456,1.079,0.486,1.49,0.058c1.728-1.802,2.792-4.247,2.792-6.941c0-5.163-3.899-9.416-8.913-9.974
	c-0.596-0.066-1.122,0.399-1.122,0.999v0c0,0.5,0.369,0.932,0.866,0.985C14.927,2.48,18.071,5.897,18.071,10.036z"
      />
    </svg>
  )
}
