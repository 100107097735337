import React, { useEffect, useRef, useState } from 'react'

import { backend, community_name, src } from '../.core'
import { DJANGO, user_id } from '../components/ROUTERJS.js'

import squad_nofap_NF12345 from '../data/squads/REACT_APP.squad.nofap.NF12345.json'
import REACT_APP_users from '../data/REACT_APP.users.json'

import lib from '../data/lib.json'

import Toolbar from './Toolbar'

import _spinner_ from '../svg/Spinner'
import _tick_ from '../svg/Tick'

export default function Farewell({
  props: { community_id, user, _showFarewell, operator },
}) {
  const _FAREWELLS_ = useRef()
  const _FAREWELL_USERS_ = useRef()

  const customTriggerRef = useRef()
  const textareaRef = useRef()

  const [trigger_option, _trigger_option] = useState()
  const [add_custom, _add_custom] = useState(false)
  const [char_count, _char_count] = useState(0)

  const [msg_exceeded, _msg_exceeded] = useState(false)

  const [submitted_trigger, _submitted_trigger] = useState(false)
  const [submitted_msg, _submitted_msg] = useState(false)
  const [submitted_all, _submitted_all] = useState(false)
  const [loading, _loading] = useState()

  const [initFarewells, _initFarewells] = useState(false)

  const msg_limit = 150

  const triggers = Object.keys(lib.triggers)

  const response_status = (type) =>
    loading === type ? (
      <div className="-Spinner -svg">
        <_spinner_ />
      </div>
    ) : (type === 'trigger' && submitted_trigger) ||
      (type === 'msg' && submitted_msg) ? (
      <div className="-Tick -svg">
        <_tick_ type="stroke" strokeWidth={2} />
      </div>
    ) : null

  const submitted = (type) => (
    <div id="submitted" className="-f-ja">
      {type === 'trigger' ? (
        <p>
          You relapsed because of:
          <span className="bold">{' ' + trigger_option + ' '}</span>
          <span className="smaller" style={{ color: 'orange' }}>
            (
            <span
              className="rlink"
              onClick={() =>
                _submitted_trigger(false) +
                _trigger_option(null) +
                _add_custom(false)
              }
            >
              change
            </span>
            )
          </span>
        </p>
      ) : (
        <>
          <div className="-f-a">
            <_tick_ />
            <p className="inline">
              {type === 'all' && 'Feedback '}Submitted
              {type === 'msg' && ' Message'}
            </p>
          </div>
        </>
      )}
    </div>
  )

  async function handle_trigger(method, elm, value = null) {
    if (elm === 'Add Custom') {
      if (method === 'click') {
        if (trigger_option !== 'addcustom') {
          _submitted_trigger(false)
        }
        _add_custom(true)
        _trigger_option('addcustom')
      }
      if (method === 'blur') {
        if (value.length === 0) {
          await post_form('trigger', null)
          _add_custom(false)
          // _submitted_trigger(false) ??
          _trigger_option(null)
          operator('Farewell', 'unset custom trigger')
        } else {
          await post_form('trigger', value)
          _submitted_trigger(true)
          operator('Farewell', value)
        }
      }
    } else {
      const unset_trigger = trigger_option === elm
      await post_form('trigger', unset_trigger ? null : elm)
      _trigger_option(unset_trigger ? null : elm)
      _submitted_trigger(unset_trigger ? false : true)
      operator('Farewell', elm)
    }
  }

  function post_form(type, value) {
    return new Promise((resolve) => {
      _loading(type)
      const then = () => {
        _loading(false)
        if (type === 'trigger') {
          return resolve(true)
        }
        if (type === 'msg') {
          _submitted_msg(true)
          operator('Farewell', 'msg')
        }
      }
      if (DJANGO) {
        backend('__squad/update_resign/', {
          body: {
            community_id: community_id,
            param: type,
            prev_squad: user.communities_data[community_id].prev_squad,
            user_id: user_id,
            value: value,
          },
        }).then((response) => {
          if (response.status === 200) {
            then()
          }
        })
      } else {
        setTimeout(() => {
          then()
        }, 500)
      }
    })
  }

  function get_triggers() {
    triggers.push('Add Custom')
    return (
      <div id="triggers-menu" className="-f-">
        {triggers.map((elm) => {
          const trigger = elm.replace(' ', '').toLowerCase()
          return (
            <div
              id={'trigger-' + trigger}
              className={
                'trigger-option ' + (trigger_option === trigger ? 'true' : '')
              }
              onClick={() => handle_trigger('click', elm)}
            >
              {elm === 'Add Custom' && add_custom ? (
                <p
                  ref={customTriggerRef}
                  contentEditable={true}
                  onKeyDown={(e) => {
                    _submitted_trigger(false)
                    e.key === 'Enter' &&
                      e.preventDefault() + customTriggerRef.current.blur()
                  }}
                  onBlur={(e) =>
                    !(
                      e.relatedTarget &&
                      e.relatedTarget.className === 'trigger-button'
                    ) && handle_trigger('blur', elm, e.target.innerText)
                  }
                ></p>
              ) : (
                <button className="trigger-button">{elm}</button>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  // ... 𝙡𝙤𝙖𝙙 𝙛𝙖𝙧𝙚𝙬𝙚𝙡𝙡𝙨
  useEffect(() => {
    if (false && DJANGO) {
      backend('__squad/get_farewells/', {
        body: {
          community_id: community_id,
          prev_squad_id: user.communities_data[community_id].prev_squad.id,
        },
      }).then((response) => {
        if (response.status === 200) {
          const farewells = deepParseJson(response.body.farewells)
          const farewell_users = deepParseJson(response.body.users)
          _FAREWELLS_.current = farewells
          _FAREWELL_USERS_.current = farewell_users
          _initFarewells(true)
        }
      })
    } else {
      _FAREWELLS_.current = squad_nofap_NF12345.farewells
      _FAREWELL_USERS_.current = REACT_APP_users
      _initFarewells(true)
    }
  }, [])

  // form handling
  useEffect(() => {
    add_custom && customTriggerRef.current.focus()
  }, [add_custom])
  useEffect(() => {
    if (char_count > msg_limit) {
      _msg_exceeded(true)
    } else {
      _msg_exceeded(false)
    }
  }, [char_count])
  useEffect(() => {
    const feedback =
      user.communities_data[community_id].prev_squad?.feedback ?? null
    if (feedback) {
      if (feedback === 'true') {
        _submitted_all(true)
      } else if (feedback === 'msg') {
        _submitted_msg(true)
      } else {
        _trigger_option(feedback)
        _submitted_trigger(true)
      }
    }
  }, [user])

  return (
    <div id="Farewell" className="overlay">
      <Toolbar pathway={() => _showFarewell(false)} />
      <div className="content clear-toolbars">
        {submitted_all ? (
          submitted('all')
        ) : (
          <>
            <p id="p-help-the-community" className="l-h">
              Help the community by sharing a couple things?
            </p>
            {submitted_trigger ? (
              submitted('trigger')
            ) : (
              <>
                <p className="l-h">
                  What was the "trigger" for your relapse?
                  {response_status('trigger')}
                </p>
                {get_triggers()}
              </>
            )}
            {submitted_msg ? (
              submitted('msg')
            ) : (
              <>
                <p id="p-leave-a-message" className="l-h">
                  Leave a message for your previous squad?
                </p>
                <div id="-leave-a-message">
                  <div className="-f-a textarea-tools">
                    <div
                      className="word-limiter"
                      style={{
                        width:
                          char_count === 0
                            ? '50px'
                            : msg_exceeded
                            ? (char_count / msg_limit) * 50 - 50 + 'px'
                            : 50 - (char_count / msg_limit) * 50 + 'px',
                        background: msg_exceeded ? 'orangered' : 'violet',
                      }}
                    ></div>
                    <p
                      id="submit-resign-msg"
                      className={msg_exceeded && 'msg_exceeded'}
                      onClick={() =>
                        !msg_exceeded &&
                        post_form('msg', textareaRef.current.value)
                      }
                    >
                      submit
                    </p>
                  </div>
                  <textarea
                    ref={textareaRef}
                    className="textarea"
                    name="message"
                    placeholder={'...'}
                    style={{ resize: 'none' }}
                    onChange={(e) => _char_count(e.target.value.length)}
                  ></textarea>
                  <p className="foot-note">
                    It can be anything, a message of encouragment or perhaps
                    sharing something you learned from your relapse. It will be
                    viewable by your previous squad for 1 day only.
                  </p>
                </div>
              </>
            )}
          </>
        )}
        <p id="p-some-parting-messages" className="l-h">
          Some parting messages from your old squadies...
        </p>
        <div
          id="farewells"
          className={!initFarewells ? '-f-j' : '' + ' scrollY'}
        >
          {initFarewells ? (
            Object.entries(_FAREWELLS_.current).map(([key, val]) => (
              <div className="-f- -farewell">
                <img
                  src={src(`img/${_FAREWELL_USERS_.current[key].img}`)}
                  alt=""
                />
                <p>{val}</p>
              </div>
            ))
          ) : (
            <div className="-Spinner">
              <_spinner_ />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
