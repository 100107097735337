import React, { useEffect, useRef } from 'react'
import { fun } from '../.fun'

export default function StartingIn({ iso }) {
  const hoursRef = useRef()
  const minutesRef = useRef()
  const secondsRef = useRef()

  let iCountdown

  function get_countdown(iso) {
    const dur = fun().msDiff(iso)
    if (dur.DD > 0) {
      return <p id="countdown">{dur.DD} Days</p>
    } else {
      return (
        <p id="countdown">
          <span ref={hoursRef}>{fun().pad(dur.HH)}</span>
          <span className="divider">:</span>
          <span ref={minutesRef}>{fun().pad(dur.MM)}</span>
          <span className="divider">:</span>
          <span ref={secondsRef}>{fun().pad(dur.SS)}</span>
        </p>
      )
    }
  }

  useEffect(() => {
    if (hoursRef.current && minutesRef && secondsRef) {
      iCountdown = setInterval(() => {
        const HH = hoursRef.current.innerText
        const MM = minutesRef.current.innerText
        const SS = secondsRef.current.innerText
        if (SS == 0) {
          if (MM == 0) {
            if (HH == 0) {
              alert('end')
              clearInterval(iCountdown)
            } else {
              hoursRef.current.innerText = fun().pad(HH - 1)
              minutesRef.current.innerText = 59
              secondsRef.current.innerText = 59
            }
          } else {
            minutesRef.current.innerText = fun().pad(MM - 1)
            secondsRef.current.innerText = 59
          }
        } else {
          secondsRef.current.innerText = fun().pad(SS - 1)
        }
      }, 1000)
    }
    return () => {
      iCountdown && clearInterval(iCountdown)
    }
  }, [])

  return get_countdown(iso)
}
