import React from 'react'

export default function Feed() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M2 11.4C2 10.2417 2.24173 10 3.4 10H20.6C21.7583 10 22 10.2417 22 11.4V12.6C22 13.7583 21.7583 14 20.6 14H3.4C2.24173 14 2 13.7583 2 12.6V11.4Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M2 3.4C2 2.24173 2.24173 2 3.4 2H20.6C21.7583 2 22 2.24173 22 3.4V4.6C22 5.75827 21.7583 6 20.6 6H3.4C2.24173 6 2 5.75827 2 4.6V3.4Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M2 19.4C2 18.2417 2.24173 18 3.4 18H20.6C21.7583 18 22 18.2417 22 19.4V20.6C22 21.7583 21.7583 22 20.6 22H3.4C2.24173 22 2 21.7583 2 20.6V19.4Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    //   <circle
    //     stroke="currentColor"
    //     stroke-width="1.5"
    //     cx="12"
    //     cy="12"
    //     r={12 - 1.5 / 2}
    //   />
    //   <path
    //     class="guts"
    //     d="M4.5,12h15"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     class="guts"
    //     d="M7.51,6h8.982"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     class="guts"
    //     d="M7.51,18h8.985"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  )
}
