import React, { useEffect, useRef, useState } from 'react'
import { fun } from '../.fun'
import { community_name, src } from '../.core'
import {
  DJANGO,
  IN_DEPLOYMENT,
  desktop,
  meta,
  mobile,
  user_id,
} from './ROUTERJS'

import lib from '../data/lib.json'

import Rules from '../components-sub/Rules'

import ArrowBack from '../svg/ArrowBack'

import _tick_ from '../svg/Tick'
import _beginner_ from '../svg/modifiers/Beginner'
import _event_ from '../svg/modifiers/Event'
import _star_ from '../svg/modifiers/Star'
import _wreath_ from '../svg/modifiers/Wreath'

// to do
//
//
//      remove beginner, setBeginner
//
//

export default function CommunityLanding({
  props: { community_id, user, _route, setSquad, join_squad },
}) {
  const [beginner, setBeginner] = useState(false)

  const community_lib = lib.communities[community_id]

  // const isJoined = cleanKeys()

  const types_lib = {
    community: <_star_ />,
    event: <_event_ />,
  }

  const behaviours_lib = {
    beginner: {
      svg: <_beginner_ />,
      ps: 'Beginner',
      onClick: () => setBeginner(!beginner),
    },
    elite: {
      svg: <_wreath_ color={'gold'} />,
      ps: 'Elite',
      ps_sub: '<5% Success Rate',
    },
  }

  const get_type = (type) => (
    <div id="type" className="--f-a">
      {/* <div className={`-mod type-${type}`}>{types_lib[type]}</div> */}
      <p>{type}</p>
    </div>
  )

  function get_behaviours() {
    const export_behaviours = []
    const behaviour = (e) => {
      const libItem = behaviours_lib[e]
      return <div className={`-mod type-${e}`}>{libItem.svg}</div>
    }
    export_behaviours.push(
      <p id="community-size" className="ps-bottom">
        {fun().simplifyNum(fun().randInt(300, 3000))}
      </p>
    )
    return <div className="behaviours">{export_behaviours}</div>
  }

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <>
      {mobile && (
        <ArrowBack
          type="sharp"
          pathway={() => _route('Home')}
          uiElement={true}
        />
      )}
      <div
        id="CommunityLanding"
        style={mobile ? { minHeight: window.innerHeight + 'px' } : {}}
      >
        {desktop && (
          <ArrowBack type="click-me" pathway={() => _route('Home')} />
        )}
        <div className="exhibit posrel">
          <div className="-credentials">
            <div className="-img">
              <div></div>
              <img
                id={community_lib.id}
                src={src(`community-icons/${community_lib.img}`)}
                alt=""
              />
              {community_id in user.communities &&
              !user.communities[community_id].includes('ex:') ? (
                <div className="continue isJoined">
                  <p className="joined">
                    <div className="-Tick push">
                      <_tick_ type="blocky" />
                    </div>
                    Joined
                  </p>
                  <p></p>
                  <p
                    className="go-to-squad rlink"
                    onClick={() =>
                      _route('App') + setSquad(user.communities[community_id])
                    }
                  >
                    My Squad
                  </p>
                  <p className="go-to-squad">{' >'}</p>
                </div>
              ) : (
                <p
                  className="continue button color"
                  onClick={() => join_squad('new')}
                >
                  Join
                </p>
              )}
            </div>
            <p className="name">
              {community_name(community_lib.name)}
              {beginner && <span className="beginner inline">- Beginner</span>}
            </p>
            {/* <p className="sub">{community_lib.sub}</p> */}
            <div className="-f-a -type">
              {get_type(community_lib.type)}
              {get_behaviours()}
            </div>
          </div>
        </div>
        <div className="community-about">
          <p className="about">
            {community_lib.hasOwnProperty('about')
              ? community_lib.about
              : beginner
              ? community_lib.beginner.sub
              : community_lib.sub}
          </p>
        </div>
        <Rules community_id={community_id} />
      </div>
    </>
  )
}
