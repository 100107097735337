import React, { Component } from 'react'

import { src } from '../.core.js'
import { fun } from '../.fun.js'

import {
  PROJECT_NAME,
  DJANGO,
  IN_DEPLOYMENT,
  desktop,
  meta,
  mobile,
  userAgent,
} from './ROUTERJS'

import DotDotDot from '../components-sub/DotDotDot.js'
import Join from '../components-sub/Join.js'

import _spinner_ from '../svg/Spinner.js'

import '../login.css'

const chars = [
  { min: 48, max: 57 },
  { min: 65, max: 90 },
]
const dials_alphabet = {
  2: 'ABC',
  3: 'DEF',
  4: 'GHI',
  5: 'JKL',
  6: 'MNO',
  7: 'PQRS',
  8: 'TUV',
  9: 'WXYZ',
}
const sys = {
  placeholder: 'Tester ID|',
  attemptsCatch: 'You have 2 attempts remaining',
  loginDenied: 'Try again in {time}',
}
// loginDenied:
//   'Try again in <timer/> or, \nsubmit a request for temporary access \n\n-Please be aware that your request may not be recieved before the timer has expired.',

let charWidth, currentKeyFill

let form_data = {
  username: null,
  country: null,
}

let iBlink, tTouchStart
let jhitKey = 0,
  jSecretEntrance = 0

// let attempts_remaining = 5

const log0 = (a) => fun().log0(a)
const log1 = (a, b) => fun().log1(a, b)
const log2 = (a, b) => fun().log2(a, b)

//  BEGIN DEV
const $$loginBy$$ = (a) => a === 'KEY' // ID | KEY | PIN
const $$bypassIntro$$ = false
const $$preventDefaultStartup$$ = false
const $$realLoginPage$$ = false
const $$x100ms$$ = 0.5
//  END DEV

export default class Login extends Component {
  constructor(props) {
    super(props)
    const { setLoggedIn } = props.props
    this.state = {
      attemptsCatch: false,
      awaiting: false,
      blinking: false,
      checking: null,
      err: null,
      input: '',
      keydown: false,
      left: 0,
      login: null,
      loggingIn: false,
      page: 'login', // <-- login | signIn  🟡
      pin_1: '',
      pin_2: '',
      pin_3: '',
      pin_4: '',
      pin_5: '',
      pressed: null,
      preType: true, // preType has 2 functions; input is empty and system is talking (@cDU)
      ran_intro: false,
      secret_entrance: false,
      username: '',
      width: null,
      init: false,
    }
    this.setLoggedIn = setLoggedIn
    // this.setUserID = setUserID
    this.inputRef = React.createRef()
    this.shadowInputRef = React.createRef()
    // this.countryRef = React.createRef()
  }

  intro = () => {
    return (
      <div id="intro" className="xy">
        <p className="input">{this.state.input}</p>
        <p className="shadow">{PROJECT_NAME.upperCase}</p>
      </div>
    )
  }
  delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  simulate = (callback) => {
    return new Promise((resolve) =>
      setTimeout(
        () => {
          const returnValue = callback()
          if (returnValue) {
            resolve(returnValue)
          } else {
            resolve(false)
          }
        },
        mobile ? 1600 : 500
      )
    )
  }
  setParentState = (obj) => {
    this.setState(obj)
  }

  fetchWithMinDelay = async (url, options) => {
    const fetchPromise = fetch(url, options)
    const delayPromise = this.delay(1600)

    const [response] = await Promise.all([fetchPromise, delayPromise])

    console.log(response)

    return response
  }

  async backend(url, PIN = null) {
    if (url === 'create') {
      if (DJANGO) {
        console.log('__user/create/...')
        fetch('__user/create/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(form_data),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            return response.json()
          })
          .then((data) => {
            log2('msg', data.msg)
            return
            if (data.created === true) {
              console.log('Profile Created')
              // this.setUserID('QWERTY321')
              this.setLoggedIn('QWERTY321')
            } else {
              console.log('Error creating user')
            }
          })
          .catch((error) => {
            console.error(
              'There was a problem with the fetch operation:',
              error
            )
          })
      } else {
        console.log('Profile Created')
        this.setLoggedIn('QWERTY321')
      }
    }
    if (url === 'login') {
      this.setState({ awaiting: true })
      const then = (user_id) => {
        console.log('then()')
        this.setState({ awaiting: false, loggingIn: true })
        if (IN_DEPLOYMENT) {
          this.fetchWithMinDelay('__beta/submit/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              tester: user_id,
              userAgent: userAgent,
              type: 'login',
              value: null,
            }),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok')
              }
              return response.json()
            })
            .then((data) => {
              this.setLoggedIn(user_id)
            })
            .catch((error) => {
              console.error(
                'There was a problem with the fetch operation:',
                error
              )
            })
        } else {
          this.simulate(() => {
            this.setLoggedIn(user_id)
          })
        }
      }
      const authenicate = async (USER_PWD) => {
        const fetchHandler = $$loginBy$$('PIN') ? this.fetchWithMinDelay : fetch
        return fetchHandler(
          `__user/authenticate?USER_PWD=${USER_PWD}&UA=${userAgent}`,
          meta
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            return response.json()
          })
          .then((rj) => {
            console.log(rj)
            if (rj.message === 'Authenticated') {
              if (rj.access_token) {
                localStorage.setItem('access_token', rj.access_token)
              }
              then(rj.user_id)
              return true
            } else {
              return false
            }
          })
          .catch((error) => {
            console.error(
              'There was a problem with the fetch operation:',
              error
            )
          })
      }
      const authenicate_react = (user_pwd) => {
        const IDS = process.env['REACT_APP_TESTER_IDS'].split(',')
        for (const x of IDS) {
          const [PWD, ID] = x.split(':')
          if (user_pwd === PWD) {
            then(ID)
            return true
          }
        }
        return false
      }
      const incorrect_login = () => {
        console.log('Incorrect Login')
        this.setState({ awaiting: false, login: false })
      }
      // ## PIN / KEY ##
      if (mobile && ($$loginBy$$('PIN') || $$loginBy$$('KEY'))) {
        const user_pwd = process.env[`REACT_APP_${PIN}`]
        if (DJANGO) {
          if ($$loginBy$$('KEY')) {
            authenicate_react(user_pwd)
          } else {
            const authenticated = await authenicate(user_pwd)
            if (!authenticated) {
              incorrect_login()
            }
          }
        } else {
          if ($$loginBy$$('KEY')) {
            authenicate_react(user_pwd)
          } else {
            const authenicated = await this.simulate(() =>
              authenicate_react(user_pwd)
            )
            if (!authenicated) {
              incorrect_login()
            }
          }
        }
        return
      }
      // ## INPUT ##
      const user_pwd = mobile
        ? this.inputRef.current.value
        : this.state.input.split('|')[1]
      if (user_pwd.length > 0) {
        if (DJANGO) {
          console.log(console.log('await authenicate(user_pwd)'))
          const authenticated = await authenicate(user_pwd)
          if (!authenticated) {
            console.log('incorrect_login()')
            incorrect_login()
          }
        } else {
          const authenicated = await this.simulate(() =>
            authenicate_react(user_pwd)
          )
          if (!authenicated) {
            console.log('789')

            incorrect_login()
          }
        }
      }
      return
    }
    if (url === 'request') {
      this.simulate(() => this.say('Request sent.'))
    }
    if (url === 'time') {
      let now = new Date()
      now.setHours(now.getHours() + 1)
      return now.toISOString()
    }
  }

  handleContextMenu = (e) => {
    console.log('EventListener >> contextmenu <<')
    e.preventDefault()
  }
  handleKeyDown = (e) => {
    const { init, input, left, login, page, preType, attemptsCatch, awaiting } =
      this.state
    if (
      (desktop && !init) ||
      awaiting ||
      login === 'denied' ||
      page === 'signIn'
    ) {
      return
    }
    if (attemptsCatch) {
      if (e.key === 'Enter') {
        this.setState({ attemptsCatch: false })
        this.say(sys.placeholder, true)
      } else {
        return
      }
    }

    const key = e.key
    const keyCode = e.keyCode || e.which
    let pressed = false

    if (key === 'Enter') {
      pressed = 'Enter'
    } else if (key === 'Backspace') {
      pressed = 'Back'
    } else if (key === 'Delete' || key === 'Escape') {
      pressed = 'Del'
    } else if (key === 'ArrowLeft' || key === 'ArrowRight') {
      if (key === 'ArrowLeft') {
        if (Math.abs(left) < input.length - sys.placeholder.length) {
          this.setState({ left: left - 1 })
        }
      } else {
        if (left < 0) {
          this.setState({ left: left + 1 })
        }
      }
    } else if (key === '-' || key === '_') {
      pressed = 'char'
    } else {
      for (const range of chars) {
        if (keyCode >= range.min && keyCode <= range.max) {
          pressed = 'char'
          break
        }
      }
    }

    if (mobile) {
      if (pressed) {
        switch (pressed) {
          case 'char':
            if (!isNaN(key)) {
              this.handlePIN(key)
            } else {
              const letterToNum = fun().letterToDialPad(key)
              if (letterToNum) {
                this.handlePIN(letterToNum)
              }
            }
            break
          case 'Back':
            this.handlePIN('del')
            break
          case 'Del':
            this.handlePIN('DEL')
            break
          case 'Enter':
            this.backend('login')
            break
          default:
          // default code block
        }
      }
      return
    }

    if (pressed) {
      switch (pressed) {
        case 'char':
          this.setState({ keydown: true, login: null })
          if (input.length > 33) {
            return
          }
          clearInterval(iBlink)
          this.setState({ blinking: false, preType: false })
          if (left < 0) {
            const position = input.length + left
            const modifiedStr =
              input.substring(0, position) + key + input.substring(position)
            this.setState({ input: modifiedStr })
          } else {
            this.setState({ input: input + key })
          }
          break
        case 'Back':
          if (preType) {
            return
          }
          this.setState({ blinking: false, keydown: true, login: null })
          if (left < 0) {
            if (Math.abs(left) === input.length - sys.placeholder.length) {
              const modifiedStr =
                sys.placeholder + input.slice(11, input.length)
              this.setState({ input: modifiedStr, left: left + 1 })
            } else {
              const position = input.length + left
              const modifiedStr =
                input.substring(0, position - 1) + input.substring(position)
              this.setState({ input: modifiedStr })
            }
          } else {
            this.setState({ input: input.slice(0, -1) })
          }
          break
        case 'Del':
          if (preType) {
            return
          }
          this.setState({ input: sys.placeholder, left: 0, login: null })
          break
        case 'Enter':
          if (preType || login === false) {
            console.log('@Enter preType || login === false')
            return
          }
          this.backend('login')
          break
        default:
        // default code block
      }
    }
  }
  handleKeyUp = (e) => {
    if (this.state.page === 'signIn') return
    if (mobile) {
      return
    }
    if (this.state.keydown && !(e.key === 'Backspace' && this.state.preType)) {
      this.setState({ blinking: true, keydown: false })
      // blink()
    }
  }

  handlePIN = (num) => {
    const { pin_1, pin_2, pin_3, pin_4, pin_5 } = this.state
    const PIN =
      pin_1.toString() +
      pin_2.toString() +
      pin_3.toString() +
      pin_4.toString() +
      pin_5.toString()
    const current_pin = PIN.length + 1
    if (num === 'del') {
      if (current_pin - 1 > 0) {
        this.setState({ login: null })
        this.setState({ [`pin_${current_pin - 1}`]: '' })
      }
    } else if (num === 'DEL') {
      for (let i = 1; i < 6; i++) {
        this.setState({ login: null })
        this.setState({ [`pin_${i}`]: '' })
      }
    } else {
      if (PIN.length === 5) {
        this.setState({ login: null })
        this.setState({
          pin_1: num,
          pin_2: '',
          pin_3: '',
          pin_4: '',
          pin_5: '',
        })
      } else {
        this.setState({ [`pin_${current_pin}`]: num })
        if (current_pin === 5) {
          this.backend('login', PIN + num.toString())
        }
      }
    }
  }

  get_width = (a) => {
    const print = sys[a]
    if (print.includes('\n')) {
      const split = print.split('\n')
      let biggest = 0
      split.forEach((element) => {
        if (element.length > biggest) {
          biggest = element.length
        }
      })
      return biggest * charWidth
    } else {
      return print.length * charWidth
    }
  }

  get_time = () => {
    const how_long = (release_date) => {
      const date = new Date()
      const futureDate = new Date(release_date)
      const diff = futureDate.getTime() - date.getTime()
      let mm = fun().pad(Math.floor(diff / (1000 * 60)))
      let ss = fun().pad(Math.floor((diff / 1000) % 60))
      return [mm, ss]
    }
    const release_date = this.backend('time')
    setInterval(() => {
      const [mm, ss] = how_long(release_date)
      this.setState({ input: `Try again in ${mm}:${ss}` })
    }, 1000)
    const [mm, ss] = how_long(release_date)
    return `${mm}:${ss}`
  }

  get_input = () => {
    const { input, login } = this.state
    const split = input.split('|')
    const cursor = (
      <div
        id="cursor"
        style={{
          visibility:
            this.state.awaiting || this.state.loggingIn
              ? 'hidden'
              : this.state.blinking
              ? 'visible'
              : 'hidden',
          // background: this.state.blinking ? 'blue' : 'red',
          left: `calc(100% + ${this.state.left * charWidth}px)`,
        }}
      ></div>
    )
    return (
      <>
        <span className="sys">
          {split[0]}
          {input.includes('|') ? ': ' : ''}
          {split[1] ? '' : cursor}
        </span>
        {split[1] ? (
          <span className="usr">
            {split[1]}
            {cursor}
          </span>
        ) : null}
      </>
    )
  }

  get_dials = () => {
    const dials = []
    const dial = (i) => (
      <div
        className={
          '-num noselect ' + (this.state.pressed === i ? 'pressed' : '')
        }
        // onTouchStart={() => this.setState({ pressed: null })}
        // onTouchEnd={() => this.setState({ pressed: i })}
        onClick={() => {
          this.handlePIN(i)
        }}
        // onPointerDown={() => navigator.vibrate?.(500)}
      >
        <p className="dig button">
          {i}
          {/* <span className="x alpha">{dials_alphabet[i]}</span> */}
        </p>
      </div>
    )
    for (let i = 1; i < 12; i++) {
      if (i === 10) {
        dials.push(<div></div>)
        dials.push(dial(0))
      } else if (i === 11) {
        dials.push(
          <div
            id="del"
            className={
              '-num button noselect ' +
              (this.state.pressed === i ? 'pressed' : '')
            }
            // onTouchStart={() => this.setState({ pressed: null })}
            // onTouchEnd={() => this.setState({ pressed: i })}
            onClick={() => this.handlePIN('del')}
          >
            <img src={src('svg/backspace.svg')} alt="" />
          </div>
        )
      } else {
        dials.push(dial(i))
      }
    }
    return dials
  }

  blink = (who) => {
    console.log(`@blink sender=${who}`)
    iBlink = setInterval(() => {
      console.log(
        this.state.blinking
          ? 'bleep (never turn off)'
          : 'bloop (never turn off)'
      )
      this.setState({ blinking: !this.state.blinking })
    }, 500)
  }

  say = async (str, doBlink = false, split_string = false) => {
    return new Promise((resolve) => {
      this.setState({ input: '', blinking: true })
      const chars = split_string ? str.split('') : str.split(' ')
      const bleep = setInterval(() => {
        this.setState({ blinking: false })
        this.setState({
          input:
            this.state.input +
            chars.shift() +
            [chars.length > 0 && !split_string ? ' ' : ''],
        })
      }, 100 * $$x100ms$$)
      setTimeout(() => {
        const bloop = setInterval(() => {
          if (chars.length === 0) {
            clearInterval(bleep)
            clearInterval(bloop)
            if (!this.state.init) {
              this.setState({ init: true })
            }
            if (doBlink) {
              this.blink('say()')
            }
            resolve(true)
          }
          this.setState({ blinking: true })
        }, 100 * $$x100ms$$)
      }, 50 * $$x100ms$$)
    })
  }

  // mobile $$loginBy$$ ID
  mobInput = (e) => {
    if (!this.state.awaiting) {
      this.setState({ login: null })
      this.setState({ input: e.target.value })
      this.shadowInputRef.current.innerText = e.target.value
    }
  }

  // mobile $$loginBy$$ KEY
  hitKey = () => {
    return {
      handleTouchStart: (a) => {
        if (a === 'Login') {
          if (this.state.secret_entrance) {
            jSecretEntrance++
            console.log(jSecretEntrance)
            if (jSecretEntrance >= 5) {
              this.backend('login', '55555')
            }
          }
        } else {
          tTouchStart = setTimeout(() => {
            if (currentKeyFill === 'blue') {
              this.backend('login', '00000')
            } else if (currentKeyFill === 'yellow') {
              this.setState({ secret_entrance: true })
            }
          }, 2000)
        }
      },
      handleTouchEnd: () => {
        clearTimeout(tTouchStart)
        this.setState({ secret_entrance: false })
        jSecretEntrance = 0
        const colors = [
          'red',
          'green',
          'blue',
          'yellow',
          'orange',
          'purple',
          'pink',
        ]
        const different_rand_col = () => {
          let choice = currentKeyFill
          while (choice === currentKeyFill) {
            if (jhitKey < 7) {
              choice = colors[Math.floor(Math.random() * colors.length)]
              if (choice === 'blue') {
                jhitKey = 0
              }
            } else {
              choice = 'blue'
            }
          }
          return choice
        }
        if (jhitKey < 7) {
          jhitKey++
        } else {
          jhitKey = 0
        }
        currentKeyFill = different_rand_col()
        document.getElementById('keySVGmacro').style.fill = currentKeyFill
      },
    }
  }

  componentDidMount() {
    if ($$preventDefaultStartup$$) {
      return
    }
    if (!$$realLoginPage$$) {
      if ($$bypassIntro$$) {
        this.setState({ ran_intro: true })
      } else {
        setTimeout(() => {
          const myAsyncFunction = async () => {
            await this.say(PROJECT_NAME.upperCase, false, true)
            setTimeout(() => {
              this.setState({ blinking: false, input: '', ran_intro: true })
            }, 1000)
          }
          myAsyncFunction()
        }, 500)
      }
    }
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
    document.addEventListener('contextmenu', this.handleContextMenu)
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log('@Login.js cDU()')
    // if (document.getElementById('test1')) {
    //   document.getElementById('test1').innerText = window.innerHeight
    //   document.getElementById('test2').innerText = document.body.clientHeight
    // }
    if (desktop) {
      // if (!prevState.init && this.state.init) {
      //   this.setState({ width: document.querySelector('p').offsetWidth })
      // }
      if (
        !this.state.preType &&
        this.state.input.length === sys.placeholder.length
      ) {
        this.setState({ preType: true })
        this.blink('cDU')
      }
    }
    if (prevState.page !== 'login' && this.state.page === 'login') {
      this.blink('cDU')
    }
    if (prevState.page === 'login' && this.state.page !== 'login') {
      clearInterval(iBlink)
    }
    if (!prevState.ran_intro && this.state.ran_intro) {
      if (desktop) {
        charWidth = 10.45
        setTimeout(() => {
          this.say(sys.placeholder, true)
        }, 500)
      }
    }
  }
  componentWillUnmount() {
    clearInterval(iBlink)
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
    document.removeEventListener('contextmenu', this.handleContextMenu)
  }

  render() {
    const {
      attemptsCatch,
      awaiting,
      init,
      input,
      login,
      loggingIn,
      page,
      pin_1,
      pin_2,
      pin_3,
      pin_4,
      pin_5,
      ran_intro,
      width,
    } = this.state

    return (
      <>
        {desktop && $$realLoginPage$$ && (
          <>
            <div id="-logo">
              <img id="logo-negative" src={src('logo-negative.svg')} alt="" />
            </div>
            <div
              id="signIn"
              className="button"
              onClick={() => this.setState({ page: 'signIn' })}
            >
              Sign In
            </div>
          </>
        )}
        {page === 'login' ? (
          <div
            className="Login"
            style={{ height: window.innerHeight + 'px' }}
            onTouchStart={() => this.hitKey().handleTouchStart('Login')}
          >
            {/* 📐 */}
            {/* <div id="y"></div> */}
            {/* 📐 */}
            {mobile &&
              (ran_intro ? (
                <>
                  {$$loginBy$$('ID') && (
                    <div className="ctr">
                      <div
                        className={
                          '-input' + [login === false ? ' incorrect' : '']
                        }
                      >
                        <input
                          ref={this.inputRef}
                          type="text"
                          value={input}
                          onChange={(e) => this.mobInput(e)}
                          placeholder="Tester ID"
                        />
                        <div id="shadow-input" className="-f-a">
                          <p
                            ref={this.shadowInputRef}
                            style={{ visibility: 'hidden' }}
                          ></p>
                          {awaiting ? (
                            <div className="-spinner">
                              <_spinner_ />
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="button"
                        // onTouchStart={() => this.backend('login')}
                        onClick={() => this.backend('login')}
                      >
                        Login
                      </div>
                      {!IN_DEPLOYMENT ? (
                        <div
                          id="join-mob"
                          className="button"
                          // onTouchStart={() => this.backend('login')}
                          onClick={() => this.handleProgress(1)}
                        >
                          Join
                        </div>
                      ) : null}
                    </div>
                  )}
                  {$$loginBy$$('PIN') && (
                    <div id="PIN">
                      <p
                        id="enter-PIN"
                        style={{ opacity: awaiting || loggingIn ? 0.1 : 1 }}
                      >
                        Enter PIN
                      </p>
                      <div className="-user-PIN">
                        {(awaiting || loggingIn) && (
                          <DotDotDot
                            props={{ awaiting: awaiting, type: 'spinner' }}
                          />
                        )}
                        <div
                          className={
                            'user-PIN ' + [login === false ? 'login-false' : '']
                          }
                          style={{
                            visibility:
                              awaiting || loggingIn ? 'hidden' : 'visible',
                          }}
                        >
                          <p
                            className={
                              pin_1
                                ? login === false
                                  ? ''
                                  : 'animate'
                                : 'placeholder'
                            }
                          >
                            {pin_1 || '●'}
                          </p>
                          <p
                            className={
                              pin_2
                                ? login === false
                                  ? ''
                                  : 'animate'
                                : 'placeholder'
                            }
                          >
                            {pin_2 || '●'}
                          </p>
                          <p
                            className={
                              pin_3
                                ? login === false
                                  ? ''
                                  : 'animate'
                                : 'placeholder'
                            }
                          >
                            {pin_3 || '●'}
                          </p>
                          <p
                            className={
                              pin_4
                                ? login === false
                                  ? ''
                                  : 'animate'
                                : 'placeholder'
                            }
                          >
                            {pin_4 || '●'}
                          </p>
                          <p
                            className={
                              pin_5
                                ? login === false
                                  ? ''
                                  : 'animate'
                                : 'placeholder'
                            }
                          >
                            {pin_5 || '●'}
                          </p>
                        </div>
                      </div>
                      <div
                        className="dial-pad"
                        style={{ opacity: awaiting || loggingIn ? 0.05 : 1 }}
                      >
                        {this.get_dials()}
                      </div>
                    </div>
                  )}
                  {$$loginBy$$('KEY') && (
                    <div id="KEY">
                      <div
                        id="-keySVGmacro"
                        onTouchStart={() =>
                          awaiting || loggingIn
                            ? null
                            : this.hitKey().handleTouchStart()
                        }
                        onTouchEnd={() =>
                          awaiting || loggingIn
                            ? null
                            : this.hitKey().handleTouchEnd()
                        }
                      >
                        {(awaiting || loggingIn) && (
                          <DotDotDot
                            props={{ awaiting: awaiting, type: 'spinner' }}
                          />
                        )}
                        <svg
                          id="keySVGmacro"
                          viewBox="0 0 11 5"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            display: awaiting || loggingIn ? 'none' : 'block',
                          }}
                        >
                          <path d="M4.99984 3.00086H3.99996V4.00043H3.00008V5H0.999882V4.00043H0V1.00129H0.999882V0H3.00008V1.00129H3.99996V2.00086H11V4.00043H10.0001V5H8.9998V3.00086H7.99992V4.00043H7.00004V3.00086H4.99984ZM0.999882 3.00086H2.0002V2.00086H0.999882V3.00086Z" />
                        </svg>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                this.intro()
              ))}
            {desktop &&
              (ran_intro ? (
                attemptsCatch || login === 'denied' ? (
                  <p
                    id="system-msg"
                    className="xy"
                    style={{
                      display:
                        attemptsCatch || login === 'denied' ? 'block' : 'none',
                      width:
                        [
                          attemptsCatch
                            ? this.get_width('attemptsCatch')
                            : this.get_width('loginDenied'),
                        ] + 'px',
                    }}
                  >
                    {this.get_input()}
                  </p>
                ) : (
                  <div
                    id="-login"
                    className={
                      '-f-a y ' +
                      (awaiting || loggingIn ? 'awaiting ' : '') +
                      (login === false ? 'incorrect' : '')
                    }
                    style={{
                      display:
                        attemptsCatch || login === 'denied' ? 'none' : 'flex',
                      width: init ? width + 'px' : 'auto',
                      left: `calc(50% - ${
                        charWidth * (sys.placeholder.length + 1)
                      }px)`,
                    }}
                  >
                    <p>{this.get_input()}</p>
                    {awaiting || loggingIn ? (
                      <div
                        className={'-spinner ' + (loggingIn ? 'loggingIn' : '')}
                      >
                        <_spinner_ />
                      </div>
                    ) : null}
                  </div>
                )
              ) : (
                this.intro()
              ))}
          </div>
        ) : null}
        {page === 'signIn' ? (
          <Join
            props={{
              awaiting: awaiting,
              loggingIn: loggingIn,
              setParentState: this.setParentState,
              backend: this.backend,
            }}
          />
        ) : null}
      </>
    )
  }
}
