import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '../store'
import { deepParseJson } from 'deep-parse-json'

import { fun } from '../.fun.js'
import { soundTheAlert, src } from '../.core.js'

// import lib from '../data/lib.json'

import beta_json from '../beta/data/beta.json'
import dev from '../dev/data/dev.json'

//    🡇 🡇 🡇  Ｍ Ｏ Ｄ Ｕ Ｌ Ｅ Ｓ  🡇 🡇 🡇

import squad_nofap_NF12344 from '../data/squads/REACT_APP.squad.nofap.NF12344.json'
import squad_nofap_NF12345 from '../data/squads/REACT_APP.squad.nofap.NF12345.json'
// import squad_calisthenics28_CA12345 from '../data/squads/REACT_APP.squad.calisthenics28.CA12345.json'
import squad_centurion_CN12345 from '../data/squads/REACT_APP.squad.centurion.CN12345.json'
// import squad_iceman_IM12345 from '../data/squads/REACT_APP.squad.iceman.IM12345.json'

import REACT_APP_users from '../data/REACT_APP.users.json'

//    🡅 🡅 🡅  Ｍ Ｏ Ｄ Ｕ Ｌ Ｅ Ｓ  🡅 🡅 🡅

import App from './App'
import CommunityLanding from './CommunityLanding'
import Home from './Home'
import Home2 from './Home-2'
import Login from './Login'
import Logo from './^Logo'
import LogoTools from './^LogoTools'
import Profile from './^Profile'
import Profile2 from './^Profile-2'

import MobNavBar from '../components-sub/MobNavBar.js'

import BetaLanding from '../beta/Landing.js'
import BetaPortal from '../beta/Portal.js'

import DevWindow from '../dev/DevWindow.js'

export let DJANGO = false
export let IN_DEPLOYMENT = false
export let HIDE_NOFAP = false
export const PROJECT_NAME = {
  capitalized: fun().capitalize(beta_json.PROJECT_NAME),
  upperCase: beta_json.PROJECT_NAME.toUpperCase(),
}

export let userAgent
export let userAgentType
export let desktop
export let mobile
export let meta = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
}

export let user_id
export let user_img

function ROUTERJS() {
  const accessed_communities_ref = useRef([])

  // const { deepParseJson } = require('deep-parse-json')

  // Navigation
  const [route, _route] = useState('Home')
  const [community_id, _community_id] = useState(null)
  const [squad_id, _squad_id] = useState(null)
  const [location, _location] = useState('my_squad')
  const [userPathway, _userPathway] = useState([])

  // -> <Component/>
  const [joinReport, _joinReport] = useState({})
  const [showFarewell, _showFarewell] = useState(false)
  const [profile_open, _profile_open] = useState(false)

  const [LoggedIn, setLoggedIn] = useState(false)
  const [theme, setTheme] = useState('dark')

  const [user, _user] = useState(null)

  const [squad_batch, _squad_batch] = useState(null)

  const [init, setInit] = useState(false)

  const [preferences, _preferences] = useState({
    minimalist: false,
  })

  // < β >
  const [beta, _beta] = useState(true) // <BetaLanding />
  const [betaPortal, _betaPortal] = useState(false)

  // > DEV
  const [Dev, setDev] = useState(false) // <-- false 🟡
  const [$$determineStateAtInit$$, _$$determineStateAtInit$$] = useState(false) // <-- false 🟡
  const $$disableWebToken$$ = true
  // < DEV

  const href = window.location.href
  DJANGO = !href.includes(':3000')
  IN_DEPLOYMENT = href.includes('rhenium-42742')

  const squads_modules = {
    NF12344: squad_nofap_NF12344,
    NF12345: squad_nofap_NF12345,
    // CA12345: squad_calisthenics28_CA12345,
    CN12345: squad_centurion_CN12345,
    // IM12345: squad_iceman_IM12345,
  }

  const { notice, log1, log2 } = fun()
  const { blue, lightblue, yellow } = fun().logMethods

  function exe() {
    return {
      theme: () => {
        console.log('@exe().theme()')
        let isLight = theme === 'light'
        const background = isLight ? '#171717' : '#F7F7F7'
        setTheme(isLight ? 'dark' : 'light')
        document.querySelector('body').style.background = background
      },
    }
  }

  async function backend(url, options = null) {
    if (!DJANGO) {
      // makeFunnyNoise
      return
    }
    blue(`@backend ROUTERJS ${url}`)
    console.log(options)
    const meta = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (options) {
      if (options.headers) {
        meta.headers = { ...meta.headers, ...options.headers }
      }
      if (options.body) {
        meta.method = 'POST'
        meta.body = JSON.stringify(options.body)
      }
    }
    return fetch(url, meta)
      .then(async (response) => {
        if (!response.ok) {
          console.log('%c!response.ok', 'color:red;font-size:1rem')
          console.log(response)
          const reponse_json = await response.json()
          throw new Error(reponse_json)
        }
        const reponse_json = await response.json()
        console.log(reponse_json)
        return { body: reponse_json, status: response.status }
      })
      .catch((error) => {
        soundTheAlert(
          `Something's gone horribly wrong. @ROUTERJS backend(${url})`
        )
        console.log(error)
      })
  }

  function operator(arg1, arg2) {
    switch (arg1) {
      case 'Farewell':
        const value = () => {
          if (arg2 === 'unset custom trigger') {
            return null
          } else {
            const alreadySubmitted =
              user.communities_data[community_id].prev_squad.feedback ?? null
            if (
              (alreadySubmitted === 'msg' && arg2 !== 'msg') ||
              (alreadySubmitted !== 'msg' && arg2 === 'msg')
            ) {
              return 'true'
            } else {
              return arg2
            }
          }
        }
        const user_comms_data = user.communities_data
        user_comms_data[community_id].prev_squad['feedback'] = value()
        _user({ ...user, communities_data: user_comms_data })
        break
      default:
        break
    }
  }

  async function join_squad(method, prev_squad = null) {
    return new Promise((resolve) => {
      const then = (new_comm_data, squad) => {
        const update_comms = () => {
          return {
            ...user.communities,
            [community_id]: squad.squad,
          }
        }
        const update_comms_data = () => {
          const copy_comms_data = user.communities_data || {}
          return {
            ...copy_comms_data,
            [community_id]: new_comm_data,
          }
        }
        const xjoinReport = {
          [squad.squad]: method,
        }
        // console.log(xjoinReport)
        _joinReport(xjoinReport)
        _route('App')
        _squad_batch({
          ...squad_batch,
          [community_id]: squad,
        })
        _squad_id(squad.squad)
        _user((prevUser) => ({
          ...prevUser,
          communities: update_comms(),
          communities_data: update_comms_data(),
        }))
        resolve(true)
      }
      if (DJANGO) {
        prev_squad =
          prev_squad ??
          user.communities_data[community_id]?.prev_squad?.squad ??
          null
        backend('__squad/join_squad/', {
          body: {
            user_id: user_id,
            community_id: community_id,
            prev_squad: prev_squad,
            IN_DEPLOYMENT: IN_DEPLOYMENT,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              const responseJson = deepParseJson(response.body)
              then(responseJson.new_comm_data, responseJson.squad)
            }
          })
          .catch((error) => {
            console.log('join_squad catch(error)')
            console.log(error)
            // let errorData
            // try {
            //   errorData = JSON.parse(error.message)
            // } catch (e) {
            //   errorData = { status: 500, body: 'An unexpected error occurred' }
            // }
            // console.log('errorData -> ', errorData)
            // console.error(
            //   'There was a problem with the fetch operation:',
            //   error
            // )
          })
      }
    })
  }
  async function leave_squad(MY_SQUAD_id, exit = true) {
    return new Promise((resolve) => {
      const then = (event_id) => {
        if (exit) {
          const { communities, communities_data: communities_data } = user
          const copy_squad_batch = { ...squad_batch }
          delete communities[community_id]
          communities_data[community_id].prev_squad = {
            squad: MY_SQUAD_id,
            event_id: event_id,
          }
          delete copy_squad_batch[community_id]
          _community_id(null)
          _location('my_squad')
          _route('Home')
          _squad_batch(copy_squad_batch)
          _squad_id(null)
          _user((prevUser) => ({
            ...prevUser,
            communities: communities,
            communities_data: communities_data,
          }))
          // _user((prevUser) => ({
          //   ...prevUser,
          //   communities: communities,
          // }))
          notice()
          console.log('CHECK HERE TOO')
          console.log({
            copy_squad_batch: copy_squad_batch,
            user: updated_user,
          })
        }
        resolve()
      }
      if (DJANGO) {
        backend('__squad/leave_squad/', {
          body: {
            user_id: user_id,
            community_id: community_id,
            squad_id: MY_SQUAD_id,
            IN_DEPLOYMENT: IN_DEPLOYMENT,
          },
        }).then((response) => {
          if (response.status === 200) {
            then(response.body.event_id)
          }
        })
      } else {
        then(null)
      }
    })
  }

  function pathway() {
    const last = userPathway[userPathway.length - 1]
    switch (last) {
      case 'focus':
        // ...
        break
      case 'entry':
        // ...
        break
      default:
        break
    }
  }

  // init
  useEffect(() => {
    if ($$determineStateAtInit$$ && $$determineStateAtInit$$ !== 'determined') {
      setLoggedIn('1')
      _beta(false)
      // _betaPortal(true)

      _route('App')
      _community_id('c.nofap')
      _squad_id('NF12344')
      // _location('community')

      _$$determineStateAtInit$$('determined')
      return
    }
    const link = document.querySelector("link[rel*='icon']")
    if (link) {
      link.href = IN_DEPLOYMENT
        ? '/static/fav-Rh.png'
        : DJANGO
        ? '/static/fav-django.png'
        : '/fav-react.ico'
    }
    // 𝙪𝙨𝙚𝙧 𝙖𝙜𝙚𝙣𝙩
    const getUserAgent = () => {
      let detected
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        detected = 'Mobile'
      } else if (/iPad/i.test(navigator.userAgent)) {
        detected = 'Tablet'
      }
      if (!detected) {
        const screenWidth = window.innerWidth
        if (screenWidth < 768) {
          detected = 'Mobile'
        } else if (screenWidth < 1024) {
          detected = 'Tablet'
        } else {
          detected = 'Desktop'
        }
      }
      return detected
    }
    userAgent = getUserAgent()
    userAgentType =
      userAgent === 'Mobile' || userAgent === 'Tablet' ? 'mobile' : 'desktop'
    mobile = window.innerHeight > window.innerWidth
    desktop = window.innerWidth > window.innerHeight
    const token = localStorage.getItem('access_token')
    console.log({
      DJANGO: DJANGO,
      IN_DEPLOYMENT: IN_DEPLOYMENT,
      userAgent: userAgent,
      userAgentType: userAgentType,
      token: token,
    })
    setInit(true)
    // 𝙧𝙚𝙖𝙪𝙩𝙝𝙚𝙣𝙩𝙞𝙘𝙖𝙩𝙚
    if (DJANGO && token && !$$disableWebToken$$) {
      const then = (user_id) => {
        if (IN_DEPLOYMENT) {
          backend('__beta/submit/', {
            body: {
              tester: user_id,
              userAgent: userAgent,
              type: 'login',
              value: null,
            },
          }).then((response) => {
            console.log(response)
            if (parseInt(response.status) === 200) {
              setLoggedIn(user_id)
              _beta(false)
            } else {
              console.error('Unexpected response:', response)
            }
          })
        } else {
          setLoggedIn('1')
          _beta(false)
        }
      }
      backend('__user/reauthenticate/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(({ body, status }) => {
        console.log(`${parseInt(status)} : ${body.message}`)
        if (body.message === 'Authenticated') {
          then(body.user_id)
        } else {
          console.log(`%creauthenticate/ ${body.message}`, 'color:green')
        }
      })
    }
  }, [$$determineStateAtInit$$])

  useEffect(() => {
    console.log('@ROUTERJS useEffect [LoggedIn]')
    if (LoggedIn) {
      user_id = LoggedIn
      const then = (user, squad_batch) => {
        console.log(user)
        console.log(squad_batch)
        user_img = (
          <img
            className="global-user-img"
            src={src('img/' + user.img)}
            alt=""
          />
        )
        HIDE_NOFAP = dev.hide_nofap.includes(user_id)
        _user(user)
        // squad_batch['c.centurion'].meta.inception = fun().dateAgoIn('3 days')
        _squad_batch(squad_batch)
      }
      if (DJANGO) {
        backend('__squad/get_user/', {
          body: {
            user_id: user_id,
            IN_DEPLOYMENT: IN_DEPLOYMENT,
          },
        }).then((response) => {
          const xuser = deepParseJson(response.body.users_data)

          notice('blue')
          console.log('xuser compiled @ login after __squad/get_user/')
          console.log(xuser)

          const xsquad_batch = deepParseJson(response.body.squad_batch)
          then(xuser[user_id], xsquad_batch)
        })
      } else {
        const xuser = REACT_APP_users[LoggedIn]
        const xsquad_batch = {}
        Object.entries(xuser.communities).forEach(([k, v]) => {
          xsquad_batch[k] = squads_modules[v]
        })
        then(xuser, xsquad_batch)
      }
    }
  }, [LoggedIn])

  // clean-up
  useEffect(() => {
    if (!init) {
      return
    }
    if (route === 'Home') {
      window.scroll(0, 0)
      _community_id(null)
      _showFarewell(false)
    }
  }, [route])

  // 🔍 Routes

  //  <App
  //  <BetaLanding
  //  <BetaPortal
  //  <CommunityLanding
  //  <Home2
  //  <Logo
  //  <MobNavBar
  //  <Profile
  //  <Profile2

  return init ? (
    <div
      id="-ROUTERJS-"
      theme={theme}
      minimalist={preferences.minimalist ? 'y' : 'n'}
    >
      <Provider store={store}>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                LoggedIn ? (
                  user ? (
                    <>
                      {beta && (
                        <BetaLanding
                          props={{
                            beta: beta,
                            user: user,
                            _beta: _beta,
                            _betaPortal: _betaPortal,
                          }}
                        />
                      )}
                      <BetaPortal
                        props={{
                          betaPortal: betaPortal,
                          usn: user.usn,
                          _beta: _beta,
                          _betaPortal: _betaPortal,
                        }}
                      />
                      {mobile && <MobNavBar route={route} />}
                      <Logo
                        props={{
                          route: route,
                          _route: _route,
                        }}
                      />
                      <Profile
                        props={{
                          community_id: community_id,
                          profile_open: profile_open,
                          route: route,
                          showFarewell: showFarewell,
                          user: user,
                          _profile_open: _profile_open,
                          _showFarewell: _showFarewell,
                        }}
                      />
                      <Profile2
                        props={{
                          Dev: Dev,
                          theme: theme,
                          profile_open: profile_open,
                          _profile_open: _profile_open,
                          _betaPortal: _betaPortal,
                          setLoggedIn: setLoggedIn,
                          setDev: setDev,
                          theme_exe: exe().theme,
                        }}
                      />
                      {/* 🔍 Routes */}
                      {route === 'Home' && (
                        <Home2
                          props={{
                            community_id: community_id,
                            user: user,
                            squad_batch: squad_batch,
                            _community_id: _community_id,
                            _route: _route,
                            _squad_id: _squad_id,
                          }}
                        />
                      )}
                      {route === 'Community' && (
                        <CommunityLanding
                          props={{
                            community_id: community_id,
                            user: user,
                            _route: _route,
                            setSquad: _squad_id,
                            join_squad: join_squad,
                          }}
                        />
                      )}
                      {route === 'App' && (
                        <App
                          props={{
                            accessed_communities_ref: accessed_communities_ref,
                            community_id: community_id,
                            joinReport: joinReport,
                            location: location,
                            showFarewell: showFarewell,
                            // showFarewellPrompt: showFarewellPrompt,
                            squad_id: squad_id,
                            user: user,
                            _community_id: _community_id,
                            _location: _location,
                            _route: _route,
                            _squad_id: _squad_id,
                            _showFarewell: _showFarewell,
                            backend: backend,
                            operator: operator,
                            join_squad: join_squad,
                            leave_squad: leave_squad,
                          }}
                        />
                      )}
                      {Dev ? (
                        <DevWindow
                          props={{
                            setDev: setDev,
                          }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div id="_404">
                      <p className="xy">No User</p>
                    </div>
                  )
                ) : (
                  <>
                    <div
                      id="deployment-indicator"
                      style={{ background: dev.deployment_indicator }}
                    ></div>
                    <Login
                      props={{
                        setLoggedIn: setLoggedIn,
                        // _userID: _userID,
                      }}
                    />
                  </>
                )
              }
            />
            <Route exact path="/dev" element={<Dev />} />
          </Routes>
        </Router>
      </Provider>
    </div>
  ) : null
}

export default ROUTERJS

/* <Route component={NotFound} /> */
