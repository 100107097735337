import { BATCH, CHATROOMS, LOCATION } from './actions'

const initialState = {
  chatRooms: false,
  entry: null,
  focus: null,
  location: 'Home',
  messages: false,
  prevLocation: 'Home',
}

const myReducer = (state = initialState, action) => {
  switch (action.type) {
    case BATCH:
      // alert(`type=${action.type.toLowerCase()}\npayload=${action.payload}`)
      return { ...state, ...action.payload }
    case CHATROOMS:
      return { ...state, chatRooms: !state.chatRooms }
    case LOCATION:
      return {
        ...state,
        prevLocation: state.location,
        location: action.payload,
      }
    default:
      // alert(`type=${action.type.toLowerCase()}\npayload=${action.payload}`)
      return { ...state, [action.type.toLowerCase()]: action.payload }
  }
}

export default myReducer
