import React, { useEffect, useState } from 'react'

export default function Comment({ journal }) {
  // const [focus, setFocus] = useState(false)
  const [comment, _comment] = useState(false)

  // useEffect(() => {
  //   const textarea = document.getElementById('comment')
  //   const container_textarea = document.getElementById('textarea')
  //   if (focus) {
  //     container_textarea.classList.remove('pre')
  //     if (textarea.value.length === 0) {
  //       textarea.style.height = '120px'
  //       container_textarea.style.height =
  //         textarea.getBoundingClientRect().height + 'px'
  //       setTimeout(() => {
  //         container_textarea.style.height = 'auto'
  //         container_textarea.classList.add('inited')
  //       }, 500)
  //     }
  //   } else {
  //     if (textarea && textarea.value.length === 0) {
  //       container_textarea.style.height = 0
  //       container_textarea.classList = '-textarea pre'
  //       textarea.style.height = '1em'
  //     }
  //   }
  //   return () => {}
  // }, [focus])

  useEffect(() => {
    _comment(false)
  }, [journal])

  return comment ? (
    <textarea
      id="comment"
      className="textarea"
      name="comment"
      placeholder="Comment..."
      // onFocus={() => setFocus(true)}
      // onBlur={() => setFocus(false)}
    ></textarea>
  ) : (
    <p id="add_comment" className="low-light" onClick={() => _comment(true)}>
      Comment
    </p>
  )
}
