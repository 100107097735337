import React from 'react'

export default function SquadKO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 21.5">
      <defs>
        <mask id="guts">
          <rect width="100%" height="100%" fill="white" />
          <path
            d="M14.75,12.25c0,0-2.946-3-4-3c-1.054,0-4,3-4,3"
            fill="none"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </mask>
      </defs>
      <circle
        cx="10.75"
        cy="10.75"
        r="10"
        fill="gold"
        stroke="currentColor"
        stroke-width="1.5"
        mask="url(#guts)"
      />
    </svg>
  )
}
