import React, { useEffect } from 'react'
import _spinner_ from '../svg/Spinner'

export default function Preloader({ xy = true }) {
  useEffect(() => {
    const err = setTimeout(() => {
      console.log("Something's gone horribly wrong.")
    }, 3000)

    return () => {
      clearTimeout(err)
    }
  }, [])

  return (
    <div className={'-Spinner ' + (xy === false ? '' : 'xy')}>
      <_spinner_ />
    </div>
  )
}
