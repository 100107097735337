import React from 'react'
import { mobile } from '../components/ROUTERJS'
import ArrowLeft from './ArrowLeft'

export default function ArrowBack({
  type = mobile ? 'sharp' : 'block',
  uiElement = false,
  pathway = null,
}) {
  const isBlock = type === 'block' || type === 'click-me'
  return (
    <div
      className={
        '-ArrowBack noselect ' +
        (type === 'click-me' ? 'click-me low-light ' : '') +
        (uiElement ? 'ui-element' : 'ui-handle' + (isBlock ? ' --fill' : ''))
      }
      onClick={pathway ? () => pathway() : null}
    >
      {isBlock ? (
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" />
        </svg>
      ) : type === 'sharp' ? (
        <ArrowLeft type="sharp" />
      ) : null}
    </div>
  )
}
