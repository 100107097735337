import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rdx } from '../actions'
// import { deepParseJson } from 'deep-parse-json'

import { community_name, soundTheAlert, src } from '../.core.js'
import { fun } from '../.fun.js'

import {
  DJANGO,
  IN_DEPLOYMENT,
  desktop,
  meta,
  mobile,
  userAgent,
  user_id,
} from './ROUTERJS'
import { $$preventDefaultStartup$$ } from './ROUTERJS'

import lib from '../data/lib.json'

import dev_json from '../dev/data/dev.json'

//    🡇  🡇  🡇   Ｍ Ｏ Ｄ Ｕ Ｌ Ｅ Ｓ   🡇  🡇  🡇

// NOFAP
import nofap_NF12344 from '../data/squads/REACT_APP.squad.nofap.NF12344.json'
import nofap_NF12345 from '../data/squads/REACT_APP.squad.nofap.NF12345.json'
import nofap_2 from '../data/squads/REACT_APP.squad.nofap.division_2.json'
import nofap_3 from '../data/squads/REACT_APP.squad.nofap.division_3.json'
import nofap_NF12345_chat from '../data/REACT_APP.chat.nofap.NF12345.json'
import nofap_2_chat from '../data/REACT_APP.chat.nofap.division_2.json'

// CENTURION
import centurion_CN12345 from '../data/squads/REACT_APP.squad.centurion.CN12345.json'

import entries from '../data/REACT_APP.entries.json'
import journals from '../data/REACT_APP.journals.json'

import REACT_APP_events from '../data/REACT_APP.events.json'
import REACT_APP_likes from '../data/REACT_APP.likes.json'
import REACT_APP_users from '../data/REACT_APP.users.json'

//    🡅  🡅  🡅   Ｍ Ｏ Ｄ Ｕ Ｌ Ｅ Ｓ   🡅  🡅  🡅

import Community from './Community.js'
import LeftPanel from './^LeftPanel.js'
import LogoTools from './^LogoTools.js'
import Triggers from './Triggers.js'
import UserProfile from './UserProfile.js'

import Chat from '../components-sub/Chat.js'
import Comment from '../components-sub/Comment.js'
import CreateEntry from '../components-sub/CreateEntry.js'
import Farewell from '../components-sub/Farewell.js'
import GlobalRankInfo from '../components-sub/GlobalRankInfo.js'
import PopUp from '../components-sub/PopUp.js'
import Preloader from '../components-sub/Preloader.js'
import Resetting from '../components-sub/Resetting.js'
import SquadActivity from '../components-sub/SquadActivity.js'
import StartingIn from '../components-sub/StartingIn.js'
import Toolbar from '../components-sub/Toolbar.js'
import Trigger from '../components-sub/Trigger.js'
import Users from '../components-sub/Users.js'

import ArrowUp from '../svg/ArrowUp.js'
import ArrowDown from '../svg/ArrowDown.js'
import ArrowBack from '../svg/ArrowBack.js'
// import ArrowLeft from '../svg/ArrowLeft.js'
import _chevron_ from '../svg/Chevron.js'
import _cookie_ from '../svg/Cookie.js'
import _feed_ from '../svg/Feed.js'
import _journal_ from '../svg/Journal.js'
import _media_all_ from '../svg/Media-All.js'
import _media_img_ from '../svg/Media-Img.js'
import _media_txt_ from '../svg/Media-Txt.js'
// import _msg from '../svg/Msg.js'
import _pickUp_ from '../svg/PickUp.js'
import _promoted_ from '../svg/Promoted.js'
import _smile_ from '../svg/Smile.js'
import _squad_ from '../svg/Squad.js'
import X from '../svg/X.js'

import Elimination from '../svg/modifiers/Elimination.js'
import Ranked from '../svg/modifiers/Ranked.js'

import Rulers from '../dev/Rulers.js'

function App({
  props: {
    accessed_communities_ref,
    user,
    community_id,
    joinReport,
    location,
    showFarewell,
    squad_id,
    _community_id,
    _location,
    _route,
    _showFarewell,
    _squad_id,
    backend,
    join_squad,
    leave_squad,
    operator,
  },
}) {
  const { deepParseJson } = require('deep-parse-json')

  // const __SQUAD__ = useRef({
  //   true: null,
  //   false: null,
  // })
  const __MY_SQUAD__ = useRef()
  const __GLOBAL_SQUAD__ = useRef()
  const __CHAT__ = useRef()
  const __USERS__ = useRef()
  // const __ENTRIES__ = useRef()

  // const fading_ref = useRef() sponge
  // const journals_ref = useRef()
  const segmented_users_arr_ref = useRef()
  const global_users_arr_ref = useRef()

  const upvoted = useRef([]) // user activity
  const downvoted = useRef([]) // user activity

  const observerRef = useRef(null)

  const videoRefs = useRef([])
  const entriesRefs = useRef([]) // generated entry DOM refs

  const toolbarRef = useRef()
  const squadCredentialsRef = useRef()

  // scroll refs
  const scrollY = useRef(0)

  //dev refs
  const RESET_COUNTER = useRef(0)
  const get_users_counter_ref = useRef(0)

  const { gChatRooms, gEntry, gFocus, gJournal, gMessages } = useSelector(
    (state) => ({
      gChatRooms: state.chatRooms,
      gEntry: state.entry,
      gFocus: state.focus,
      gJournal: state.journal,
      gMessages: state.messages,
    })
  )
  const dispatch = useDispatch()

  //            State

  const [__ENTRIES__, setENTRIES] = useState()
  const [__JOURNALS__, setJOURNALS] = useState(null)

  // navigation
  const [userPathway, setUserPathway] = useState([])

  // content state
  const [overlay, _overlay] = useState(null)
  const [relapsing, setRelapsing] = useState(false)
  const [loading, _loading] = useState(false)

  const [g, _g] = useState(false)
  const [_300_, set_300_] = useState(0)
  const [globalDivision, _globalDivision] = useState(null)
  // const [squad_expand, _squad_expand] = useState(false)
  // const [squad_collapse, _squad_collapse] = useState(mobile ? true : false)

  // content obj
  const [info, setInfo] = useState(null)
  const [entry, _entry] = useState(null)
  const [journal, _journal] = useState(null)

  const [createEntry, _createEntry] = useState(false)

  const [showPopUp, _showPopUp] = useState(false)

  // const [localTime, setLocalTime] = useState(null)
  const [init, setInit] = useState(false)
  const [initFeed, _initFeed] = useState([])

  // ERR
  const [ERRloading, setERRloading] = useState(false)

  // -> <Chat />
  const [chatRooms, setChatRooms] = useState(false)
  const [chatRoom, setChatRoom] = useState(null)
  const [showMobChatRooms, setShowMobChatRooms] = useState(false)
  const [usersHeight, setUsersHeight] = useState('auto')
  const [chat_init, set_chat_init] = useState(false) // <- polymorphic

  // -> <SquadActivity />
  const [initSquadActivity, _initSquadActivity] = useState(0)

  // -> Triggers
  const [triggerID, setTriggerID] = useState(null)

  // -> <Users />
  const [initUsers, _initUsers] = useState(0)

  const prevChatRoom = useRef(chatRoom)
  const prevChatRooms = useRef(chatRooms)
  const prevCreateEntry = useRef(createEntry)
  const prevEntry = useRef(gEntry)
  const prevFocus = useRef(gFocus)
  const prevJournal = useRef(gJournal)
  const prevOverlay = useRef(overlay)
  const prevTriggerID = useRef(triggerID)

  // begin dev
  const $$preventDefaultStartup$$ = false
  const $$swipeToSwitchSquad$$ = false
  // end dev

  const __SQUAD__ = g ? __GLOBAL_SQUAD__.current : __MY_SQUAD__.current

  // intervals & timeouts

  const { lastElm, randFromArr, randInt, removeFromArr, notice, log1, log2 } =
    fun()
  const { blue, lightblue, steelblue, yellow } = fun().logMethods

  const Overview_Components = {
    up_24: <_promoted_ />,
    down_24: (
      <div>
        <p className="dim">24</p>
      </div>
    ), //<ArrowDown />,
    change_since_login: <ArrowDown />,
    current: <_squad_ />,
  }

  const community_sid = community_id.split('.')[1]
  const thisCommunityLib = lib.communities[community_id]
  const feedIsInited = initFeed.includes(location)

  let catchLoading
  let tRelapse, tSquadSelection

  const listenKeyDown = (e) => {
    if (e.key === 'r') {
      tRelapse = setTimeout(() => {
        setRelapsing(true)
        // RESET()
      }, 500)
    }
  }
  const listenKeyUp = (e) => {
    if (e.key === 'r') {
      clearTimeout(tRelapse)
      setRelapsing(false)
    }
  }

  const handleEntries = (entry_id, form_data) => {
    setENTRIES((prevENTRIES) => ({
      ...prevENTRIES,
      [entry_id]: form_data,
    }))
  }

  const handleJournals = (method, journal_id, form_data) => {
    if (method === 'read') {
      setJOURNALS((prevJournals) => ({
        ...prevJournals,
        [journal_id]: {
          ...prevJournals[journal_id],
          read: true,
        },
      }))
    } else if (method === 'add') {
      setJOURNALS((prevJournals) => ({
        ...prevJournals,
        [journal_id]: form_data,
      }))
      _createEntry(false)
      dispatch(rdx('JOURNAL', journal_id))
    }
  }

  const handleScore = (id) => {
    const elm = document.getElementById(id)
    const current = elm.innerText
    const upvote = () => {
      return {
        add: (id) => {
          upvoted.current = [...upvoted.current, id]
        },
        remove: (id) => {
          upvoted.current.splice(upvoted.current.indexOf(id), 1)
        },
      }
    }
    const downvote = () => {
      return {
        add: (id) => {
          downvoted.current = [...downvoted.current, id]
        },
        remove: (id) => {
          downvoted.current.splice(downvoted.current.indexOf(id), 1)
        },
      }
    }
    if (upvoted.current.includes(id)) {
      upvote().remove(id)
      elm.innerText = Number(current) - 1
      elm.classList.remove('upvoted')
    } else {
      let amount = 1
      if (downvoted.current.includes(id)) {
        downvote().remove(id)
        amount++
      }
      upvote().add(id)
      elm.innerText = Number(current) + amount
      elm.classList = 'score upvoted'
    }
  }

  const handleStatus = (method, option, ___) => {
    const then = (event_id) => {
      switch (method) {
        case 'create':
          const new_activity_item = {
            iso: fun().dateAgoIn(),
            user: user_id,
            mood: ___,
            users_arr: [],
          }
          __MY_SQUAD__.current[option] = {
            ...__MY_SQUAD__.current[option],
            [event_id]: new_activity_item,
          }
          _showPopUp(false)
          break
        case 'delete':
          delete __MY_SQUAD__.current[option][___]
          break
        case 'apply':
          __MY_SQUAD__.current[option][___].applied = true
          break
        case 'unapply':
          __MY_SQUAD__.current[option][___].applied = false
          break
        default:
          break
      }
      _initSquadActivity((prev) => prev + 1)
    }
    if (DJANGO) {
      backend('__squad/handle_activity/', {
        body: {
          method: method,
          community_id: community_id,
          squad_id: squad_id,
          column: option,
          user_id: user_id,
          ___: ___,
        },
      }).then((response) => {
        if (response.status === 200) {
          const event_id = method === 'create' && response.body.event_id
          then(event_id)
        }
      })
    } else {
      const event_id = method === 'create' && option + '_event_id'
      then(event_id)
    }
  }

  function get_entry(
    sender = null,
    id = gEntry || gJournal,
    type = gEntry ? 'entry' : 'journal',
    idx = null
  ) {
    const isEntry = type === 'entry'
    const isJournal = type === 'journal'
    const obj = () => {
      if (id.includes('dummy')) {
        return {
          user: '2',
          date: '2024-04-04T07:26:23.763425',
          entry_type: 'media:jpg',
          src: id.split(':')[1],
          score: 99,
        }
      } else {
        return isJournal ? __JOURNALS__[id] : __ENTRIES__[id]
      }
    }
    const proper = idx === null
    const entry = (id, obj, idx) => {
      const isSquadie = __SQUAD__.users.includes(obj.user)
      const mediaExtension =
        obj.entry_type !== 'txt' && obj.entry_type.split(':')[1]
      const mediaType =
        obj.entry_type === 'txt' ? 'txt' : fun().mediaType(mediaExtension)[1]
      const lines = obj.body && obj.body.split('\n')
      const className = () => {
        const class_arr = ['entry-user']
        if (sender) {
          class_arr.push(sender)
        }
        if (proper) {
          if (desktop) {
            class_arr.push('clear-toolbars')
          }
        } else {
          class_arr.push('--entry-preview -soft-reveal')
        }
        if (isJournal) {
          class_arr.push('isJournal')
        }
        if (mediaType !== 'txt') {
          class_arr.push('media')
        }
        if (!lines) {
          class_arr.push('borderless')
        }
        return class_arr.join(' ')
      }
      const author = () => {
        const _ = __USERS__.current[obj.user]
        return {
          img: () =>
            isSquadie ? (
              <img
                className="usr usr-border push"
                onClick={(e) =>
                  e.stopPropagation() + dispatch(rdx('FOCUS', obj.user))
                }
                src={src(_.img, 'compressed')}
                alt=""
              />
            ) : (
              <div
                className="dummy-img push"
                style={{ background: fun().randCol() }}
              ></div>
            ),
          usn: () => (isSquadie ? _.usn : `dummy-${randInt(1, 100)}`),
        }
      }
      const bullet = null // <p className="bullet">∙</p>
      // const flair = lib.flairs[obj.flair] ?? null
      return (
        <>
          <div
            className={className()}
            onClick={() => !proper && dispatch(rdx('ENTRY', id))}
          >
            <div className="-submission-info posrel">
              <div className="-f-a submission-info">
                {isEntry && (
                  <div
                    className="-f-ja -score hover-low pointer"
                    onClick={(e) => e.stopPropagation() + handleScore(id)}
                  >
                    <p id={id} className="score">
                      {obj.score}
                    </p>
                  </div>
                )}
                {sender !== 'UserProfile' && (
                  <div className="-f-a -author hover-low pointer">
                    {author().img()}
                    <p
                      onClick={(e) =>
                        e.stopPropagation() + dispatch(rdx('FOCUS', obj.user))
                      }
                    >
                      {author().usn()}
                    </p>
                    {isSquadie ? (
                      <span className="isSquadie ps-top-mini">
                        <img src={src('squadie.svg')} alt="" />
                      </span>
                    ) : null}
                  </div>
                )}
                {isEntry && (
                  <>
                    {bullet}
                    <p className="inline push">
                      {fun().agoIn(obj.date, dev_json.write_date)}
                    </p>
                    {bullet}
                    <div className="-f-a -replies hover-low pointer">
                      <p className="replies">{0} replies</p>
                    </div>
                  </>
                )}
              </div>
              {sender === 'UserProfile' || sender === 'Squad' ? null : (
                <div
                  id={`entry-${id}-menu`}
                  className="-f- y entry-menu soft-reveal"
                  onMouseLeave={() =>
                    document
                      .getElementById(`entry-${id}-menu`)
                      .classList.remove('open')
                  }
                  onClick={(e) =>
                    e.stopPropagation() +
                    document
                      .getElementById(`entry-${id}-menu`)
                      .classList.add('open')
                  }
                >
                  <div className="entry-menu-items">
                    <div className="-f-a tray">
                      <div className="pin ico-interactive ps-top">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          color="#000000"
                          fill="none"
                        >
                          <path
                            d="M3 21L8 16"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.2585 18.8714C9.51516 18.0215 5.97844 14.4848 5.12853 10.7415C4.99399 10.1489 4.92672 9.85266 5.12161 9.37197C5.3165 8.89129 5.55457 8.74255 6.03071 8.44509C7.10705 7.77265 8.27254 7.55888 9.48209 7.66586C11.1793 7.81598 12.0279 7.89104 12.4512 7.67048C12.8746 7.44991 13.1622 6.93417 13.7376 5.90269L14.4664 4.59604C14.9465 3.73528 15.1866 3.3049 15.7513 3.10202C16.316 2.89913 16.6558 3.02199 17.3355 3.26771C18.9249 3.84236 20.1576 5.07505 20.7323 6.66449C20.978 7.34417 21.1009 7.68401 20.898 8.2487C20.6951 8.8134 20.2647 9.05346 19.4039 9.53358L18.0672 10.2792C17.0376 10.8534 16.5229 11.1406 16.3024 11.568C16.0819 11.9955 16.162 12.8256 16.3221 14.4859C16.4399 15.7068 16.2369 16.88 15.5555 17.9697C15.2577 18.4458 15.1088 18.6839 14.6283 18.8786C14.1477 19.0733 13.8513 19.006 13.2585 18.8714Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="report ico-interactive ps-top">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          color="#000000"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M11.992 15H12.001"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 12L12 8"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="-chevron ui-handle --fill">
                    <_chevron_ />
                  </div>
                </div>
              )}
            </div>
            <div className="-submission-content">
              {isEntry && obj.title ? (
                <div className="-f- -title">
                  <p className={'title ' + [proper ? 't1' : 't2']}>
                    {obj.title}
                  </p>
                </div>
              ) : null}
              {mediaType !== 'txt' ? (
                <div className={'media-wrapper ' + mediaType}>
                  {mediaType === 'image' ? (
                    <>
                      <img
                        className="backdrop"
                        alt="alt"
                        src={src('media-bank/' + obj.src)}
                      />
                      <img alt="alt" src={src('media-bank/' + obj.src)} />
                    </>
                  ) : mediaType === 'video' ? (
                    <video
                      controls
                      ref={(videoRef) => videoRefs.current.push(videoRef)}
                    >
                      <source
                        src={src('media-bank/' + obj.src)}
                        type={'video/' + mediaExtension}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                </div>
              ) : null}
              {lines && proper ? (
                <div
                  ref={
                    !proper ? (ref) => (entriesRefs.current[idx] = ref) : null
                  }
                  className="txt-wrapper"
                >
                  {lines.map((line, index) => (
                    <p className="body" key={index}>
                      {line}
                    </p>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          {proper ? (
            <div id="-replies-">
              <Comment journal={gJournal} />
              <div id="-replies" className={obj.replies ? null : '-f-j'}>
                {obj.replies ? (
                  obj.replies.map((e) => <p className="reply">{e}</p>)
                ) : (
                  <p id="no-comments" className="ph2">
                    No comments...
                  </p>
                )}
              </div>
            </div>
          ) : null}
        </>
      )
    }
    return entry(id, obj(), idx)
  }

  // todo also load user journals in UserProfile
  // todo check entries_id against current iso. if < yesterday disregard
  function get_entries(sender) {
    // console.log('get_entries()')
    let entries = []
    // const challenge_entry = (elm) => (
    //   <div className="-f-j -entry- entry-challenge">
    //     <p className="challenge-title ph">{elm.title}</p>
    //   </div>
    // )
    const get_entries_arr = () => {
      switch (sender) {
        case 'feed':
          return Object.keys(__ENTRIES__).sort()
        case 'UserProfile':
          return (
            __USERS__.current[gFocus].communities_data?.[community_id]
              .entries ?? []
          )
        default:
          alert('get_entries().get_entries_arr() invalid "sender"')
      }
    }
    const entries_arr = get_entries_arr()
    if (entries_arr.length) {
      const nonMatchingValues =
        __ENTRIES__ &&
        entries_arr.filter((val) => !Object.keys(__ENTRIES__).includes(val))
      if (nonMatchingValues.length) {
        loadFeed(location, nonMatchingValues)
      } else {
        entries_arr.forEach((id, index) => {
          const last_index = entries_arr.length - 1
          entries.push(get_entry(sender, id, 'entry', index))
          if (index !== last_index) {
            entries.push(<div className="separator"></div>)
          }
        })
      }
    } else {
      if (sender === 'UserProfile') {
        entries.push(
          <div id="smile" className="xy">
            <_smile_ />
          </div>
        )
      } else {
        entries.push(<p className="dim">Nothing yet...</p>)
      }
    }
    return entries
  }

  function get_squad_overview() {
    let overview = []
    const overview_item = (type, value) => (
      <div id={'overview-' + type} className="-f-a overview-item ps-bottom">
        <p className="stat">{fun().simplifyNum(value)}</p>
        <div className="dim">{Overview_Components[type]}</div>
      </div>
    )
    let activity_counter = 0
    thisCommunityLib.activities.forEach((id) => {
      activity_counter++
      switch (id) {
        case 'up_24':
          if (g && __SQUAD__.squad !== 'g') {
            overview.push(overview_item('up_24', __SQUAD__.stat_up_24))
          }
          break
        case 'down_24':
          if (!g) {
            overview.push(overview_item('down_24', __SQUAD__.stat_down_24))
          }
          break
        case 'change_since_login':
          if (!g) {
            overview.push(
              overview_item(
                'change_since_login',
                user.communities_data[community_id].last_current -
                  __SQUAD__.stat_current
              )
            )
          }
          break
        case 'current':
          overview.push(overview_item('current', __SQUAD__.stat_current))
      }
    })
    return (
      <div
        id="squad_overview"
        className="sticky"
        style={{ visibility: loading ? 'hidden' : 'visible' }}
      >
        <div ref={squadCredentialsRef} className="-f-ja --header">
          <img
            src={src(`community-icons/negative/negative.${community_sid}.svg`)}
            alt=""
            onError={(e) => (e.target.src = src(`logo-negative-white.svg`))}
          />
          <p className="squad-id inline">
            {g ? (
              <>
                {globalDivision ? (
                  <span> Div. {globalDivision[0]}</span>
                ) : (
                  'Global'
                )}
              </>
            ) : (
              ` #${squad_id}`
            )}
          </p>
          {g && globalDivision && (
            <div
              id="squad_activity_global_rank"
              className="global-rank"
              onClick={() => _overlay('global rank info')}
            >
              {Array.from({ length: globalDivision[1] }, (_, index) => (
                <div
                  className={globalDivision[0] == index + 1 && 'current'}
                ></div>
              ))}
            </div>
          )}
        </div>
        {/* @overview */}
        <div
          id="-overview-"
          className="-f-ja --body"
          style={{
            gridTemplateColumns: `repeat(${overview.length}, 1fr)`,
          }}
        >
          {overview}
        </div>
        {/* awards section */}
      </div>
    )
  }

  function get_squad_journals() {
    let journals_read = []
    let journals_unread = []
    const isJournals = () => journals_read.length || journals_unread.length
    const journal_item = (id, obj, read) => {
      const isBlurb = obj.blurb
      return (
        <div
          className={
            (read ? 'read' : '-f-a journal-entry') + (isBlurb ? ' blurb' : '')
          }
          onClick={() => dispatch(rdx('JOURNAL', id))}
        >
          <img
            className="usr usr-border"
            src={src(__USERS__.current[obj.user].img, 'compressed')}
            alt=""
            onClick={(e) =>
              !read && e.stopPropagation() + dispatch(rdx('FOCUS', obj.user))
            }
          />
          {!read && (
            <div className="inline">
              <p className={'username' + (isBlurb ? '' : ' color')}>
                {__USERS__.current[obj.user].usn}
              </p>
              {isBlurb && (
                <p className="blurb color">{obj.blurb.toLowerCase()}</p>
              )}
            </div>
          )}
        </div>
      )
    }
    for (let [journal_id, journalObj] of Object.entries(__JOURNALS__)) {
      if (journalObj.read) {
        journals_read.push(journal_item(journal_id, journalObj, true))
      } else {
        journals_unread.push(journal_item(journal_id, journalObj, false))
      }
    }
    return journals_read.length || journals_unread.length ? (
      <div
        id="journalled_today"
        className="sticky"
        style={{ visibility: loading ? 'hidden' : 'visible' }}
      >
        <div className="-f-ja --header">
          <_journal_ />
          <p className="inline minimalist">Journalled Today</p>
        </div>
        <div id="sticky-journals-read" className="-f-ja">
          {journals_read}
        </div>
        <div id="sticky-journals-unread">{journals_unread}</div>
      </div>
    ) : (
      <div className="nothing-to-see">
        <p className="--header">No-One Journalled Today</p>
        <div
          id="add_journal"
          className="-f-ja"
          onClick={() => _createEntry('journal')}
        >
          <_journal_ />
          <p className="nomarj inline rlink">Make an entry</p>
        </div>
      </div>
    )
  }

  function get_squad_recent() {
    let media_bin = []
    let entries = []
    const entry_item = (id, entry_obj, index) => {
      const entryType = entry_obj.entry_type
      // const hot = entry_obj.score > 33
      return get_entry('Squad', id, 'entry', index)
    }
    __MY_SQUAD__.current.entries.forEach((entry_id, index) => {
      const entry_obj = __ENTRIES__[entry_id]
      const entryType =
        entry_obj.entry_type === 'txt'
          ? 'txt'
          : fun().mediaType(entry_obj.entry_type.split(':')[1])[1]
      if (entryType === 'txt') {
        entries.push(entry_item(entry_id, entry_obj, index))
      } else {
        media_bin.push({
          id: entry_id,
          entryType: entryType,
          entry_obj: entry_obj,
        })
      }
    })
    if (community_sid === 'nofap') {
      media_bin = get_dummy_squad_image_posts()
    }
    return media_bin.length || entries.length ? (
      <div
        id="recent_submissions"
        className="sticky"
        style={{ visibility: loading ? 'hidden' : 'visible' }}
      >
        <div className="-f-ja --header">
          <_feed_ />
          <p className="inline">Recent</p>
        </div>
        {media_bin.length ? (
          <div
            id="gallery"
            className={media_bin.length > 3 ? 'wrapped' : ''}
            style={{ maxWidth: `calc(100px * 3 + 2 * 8px)` }}
          >
            {media_bin.map((e) => (
              <div
                className="-f-a -media"
                onClick={() => dispatch(rdx('ENTRY', e.id))}
              >
                {e.type === 'image' ? (
                  <img src={src('media-bank/' + e.obj.src)} alt="" />
                ) : (
                  <video src={src('media-bank/' + e.obj.src)} alt="" />
                )}
              </div>
            ))}
          </div>
        ) : null}
        {entries.length ? <div className="--body">{entries}</div> : null}
      </div>
    ) : (
      <p className="nothing-to-see">No Recent Posts From This Squad</p>
    )
  }

  function loadChat() {
    if (IN_DEPLOYMENT) {
      blue('__squad/get_chat/...')
      fetch(`__squad/get_chat?squad=${squad_id}`, meta)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          console.log('%% data FROM __squad/get_chat/ %%')
          console.log(data.log_chat)
          __CHAT__.current = data.log_chat
          set_chat_init(chat_init ? chat_init + 1 : 1)
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error)
        })
    } else {
      const modules = {
        nofap_NF12345_chat,
        nofap_2_chat,
      }
      const chat_module = `${community_sid}_${squad_id}_chat`
      if (chat_module in modules) {
        __CHAT__.current = modules[chat_module]
      } else {
        __CHAT__.current = {
          meta: {},
          log: [],
        }
      }
      set_chat_init(chat_init ? chat_init + 1 : 1)
    }
  }

  function loadFeed(location, arr) {
    // if (DJANGO) {
    // } else {
    let data = { ...entries }
    data = Object.fromEntries(
      Object.entries(data).filter(([k, v]) => v.community === community_id)
    )
    const getArrSet = () => {
      switch (location) {
        case 'feed':
          return new Set(Object.keys(data))
          break
        case 'my_squad':
        case 'global_squad':
          //
          //  ~~~  how it wants to work but creating dummies below instead  ~~~
          //
          // const OneDayAgo = fun().dateAgoIn('-1 days')
          // data = Object.fromEntries(
          //   Object.entries(data).filter(
          //     ([k, v]) => v.squad === squad_id && v.date > OneDayAgo
          //   )
          // )
          data = Object.fromEntries(
            Object.entries(data).filter(([k, v]) => v.squad === squad_id)
          )
          // dummy -> instead of managing write_date, get random entries from all community's entries
          const posts_arr = Object.keys(data)
          const len_posts_arr = posts_arr.length
          if (len_posts_arr) {
            const len_dummy_arr =
              location === 'my_squad'
                ? Math.round(len_posts_arr / 10)
                : Math.round(len_posts_arr / 3)
            const dummy_arr = randFromArr(posts_arr, len_dummy_arr)
            const dummySet = new Set(dummy_arr)
            data = Object.fromEntries(
              Object.entries(data).filter(([key]) => dummySet.has(key))
            )
            return dummySet
          } else {
            return new Set(Object.keys(data))
          }
          break
        default:
          break
      }
    }
    const arrSet = getArrSet()
    const additionalEntries = Object.fromEntries(
      Object.entries(data).filter(([key]) => arrSet.has(key))
    )
    setENTRIES((prevEntries = {}) => ({
      ...prevEntries,
      ...additionalEntries,
    }))
    if (!initFeed.includes(location)) {
      if (location === 'my_squad') {
        __MY_SQUAD__.current.entries = Object.keys(data)
      }
      _initFeed([...initFeed, location])
    }
    // }
  }

  function loadJournals() {
    if (DJANGO) {
      blue('__squad/get_journals/')
      backend(`__squad/get_journals?squad_id=${squad_id}`).then((response) => {
        if (response.status === 200) {
          const journals = deepParseJson(response.body.journals)
          setJOURNALS(journals)
        }
      })
    } else {
      let data = { ...journals }
      data = Object.fromEntries(
        Object.entries(data).filter(([k, v]) => v.squad === squad_id)
      )
      if (!data) {
        setJOURNALS({})
      } else {
        let journals = {}
        let users_bin = []
        Object.entries(data).forEach(([k, v]) => {
          if (!users_bin.includes(v.user)) {
            users_bin.push(v.user)
            journals[k] = v
          }
        })
        setJOURNALS(journals)
      }
    }
  }

  // to do: split into __MY_USERS__ and __GLOBAL_USERS__
  function loadSquad() {
    const g = String(squad_id).length === 1
    if (init) {
      _loading(true)
    }
    // ... async 𝙡𝙤𝙖𝙙 𝙘𝙝𝙖𝙩, 𝙟𝙤𝙪𝙧𝙣𝙖𝙡𝙨, 𝙛𝙚𝙚𝙙
    const then = (jogComponents = false) => {
      loadChat()
      loadJournals()
      loadFeed('my_squad')
      // 𝙖𝙙𝙙𝙞𝙩𝙞𝙤𝙣𝙖𝙡 𝙥𝙧𝙤𝙘𝙚𝙨𝙨𝙞𝙣𝙜 ...
      null
      // 𝙛𝙞𝙣𝙖𝙡𝙡𝙮
      if (jogComponents) {
        _initSquadActivity((prev) => prev + 1)
      }
      _g(g)
      setInit(true)
      _initUsers((prev) => prev + 1)
      _loading(false)
      _location(g ? 'global_squad' : 'my_squad')
    }
    // ... 𝙡𝙤𝙖𝙙 𝙨𝙦𝙪𝙖𝙙, 𝙪𝙨𝙚𝙧𝙨
    if (DJANGO) {
      if (
        (g && !(__GLOBAL_SQUAD__.current?.squad == squad_id)) ||
        (!g && !(__MY_SQUAD__.current?.squad === squad_id))
      ) {
        console.log('%cReloading squad...', 'color:lightgreen;')
        backend('__squad/get_squad/', {
          body: {
            user_id: user_id,
            communities: {
              [community_id]: squad_id,
            },
            users_arr: 'all',
            IN_DEPLOYMENT: IN_DEPLOYMENT,
            UPDATE_LAST_CURRENT:
              !g && !accessed_communities_ref.current.includes(community_sid),
          },
        })
          .then((response) => {
            // ~~~ 𝙥𝙧𝙤𝙘𝙚𝙨𝙨 𝙨𝙦𝙪𝙖𝙙 ~~~ : squad object rendered server side
            let jogComponents
            let xsquad = deepParseJson(response.body.squad_data)
            xsquad = xsquad[community_id]
            if (g) {
              if (!isNaN(xsquad.squad)) {
                _globalDivision([
                  __MY_SQUAD__.current.global,
                  thisCommunityLib.global.divisions,
                ])
              }
              const [dummy_users_vol, dummies] = get_dummies()
              xsquad.stat_current = dummy_users_vol
              xsquad.users = dummies
              // try {
              __GLOBAL_SQUAD__.current = structuredClone(xsquad)
              // } catch (e) {
              //   console.warn('structuredClone() is not available')
              //   __GLOBAL_SQUAD__.current = JSON.parse(JSON.stringify(xsquad))
              // }
            } else {
              // try {
              jogComponents = true
              accessed_communities_ref.current.push(community_sid)
              __MY_SQUAD__.current = structuredClone(xsquad)
              // } catch (e) {
              //   console.warn('structuredClone() is not available')
              //   __MY_SQUAD__.current = JSON.parse(JSON.stringify(xsquad))
              // }
              segment_users()
            }

            // ~~~ 𝙥𝙧𝙤𝙘𝙚𝙨𝙨 𝙪𝙨𝙚𝙧𝙨 ~~~ : users object rendered server side

            const xusers = deepParseJson(response.body.users_data)

            // notice()
            // console.log(xusers)

            __USERS__.current = xusers

            then(jogComponents)
          })
          .catch((error) => {
            soundTheAlert(
              "Something's gone horribly wrong. @App.js loadSquad() .. processing the response from '__squad/get_squad/'"
            )
            console.error(error)
          })
      } else {
        then()
      }
    } else {
      const modules = {
        nofap_2,
        nofap_3,
        nofap_NF12344,
        nofap_NF12345,
        centurion_CN12345,
      }
      if (g || (!g && !(__MY_SQUAD__.current?.squad === squad_id))) {
        const xsquad = modules[`${community_sid}_${squad_id}`]
        if (g) {
          if (__GLOBAL_SQUAD__.current?.squad == squad_id) {
            const [dummy_users_vol, dummies] = get_dummies(
              __GLOBAL_SQUAD__.current.stat_current
            )
            __GLOBAL_SQUAD__.current.users = dummies
          } else {
            // to do: re-assess neccessity to clone
            console.log('%cReloading squad...', 'color:lightgreen;')
            if (!isNaN(xsquad.squad)) {
              _globalDivision([
                __MY_SQUAD__.current.global,
                thisCommunityLib.global.divisions,
              ])
            }
            const [dummy_users_vol, dummies] = get_dummies()
            xsquad.stat_current = dummy_users_vol
            xsquad.users = dummies
            // try {
            __GLOBAL_SQUAD__.current = structuredClone(xsquad)
            // } catch {
            //   console.warn('structuredClone() is not available')
            //   __GLOBAL_SQUAD__.current = JSON.parse(JSON.stringify(xsquad))
            // }
          }
        } else {
          // try {
          const get_resigned = () => {
            const resigned = Object.fromEntries(
              Object.entries(REACT_APP_events).filter(
                ([k, v]) => v.squad === xsquad.squad && v.type === 'resigned'
              )
            )
            if (Object.keys(resigned).length > 0) {
              Object.entries(resigned).forEach(([k, v]) => {
                if (
                  k in REACT_APP_likes &&
                  REACT_APP_likes[k].user_id === user_id
                ) {
                  resigned[k].applied = true
                }
              })
              xsquad.resigned = resigned
            }
          }
          get_resigned()
          __MY_SQUAD__.current = structuredClone(xsquad)
          // } catch {
          //   console.warn('structuredClone() is not available')
          //   __MY_SQUAD__.current = JSON.parse(JSON.stringify(xsquad))
          // }
          segment_users()
        }
        __USERS__.current = REACT_APP_users
      }
      then()
    }
  }

  function moreSquad() {
    const [dummy_users_vol, dummies] = get_dummies(
      __GLOBAL_SQUAD__.current.stat_current
    )
    __GLOBAL_SQUAD__.current.users = dummies
    _initUsers((prev) => prev + 1)
  }

  function switchSquad(toLocation) {
    console.log(toLocation)

    if (
      (g && toLocation === 'global_squad') ||
      (!g && toLocation === 'my_squad')
    ) {
      console.log('caught switch to same toLocation.')
      return
    }
    if (toLocation === 'my_squad') {
      _squad_id(__MY_SQUAD__.current.squad)
    }
    if (toLocation === 'global_squad') {
      _squad_id(__MY_SQUAD__.current.global)
    }
  }

  function RESET() {
    catchLoading = setTimeout(() => {
      setERRloading(true)
      alert('something went wrong')
    }, 10000)
    async function leaveAndJoinSquad() {
      if (DJANGO) {
        _loading(true)
        await leave_squad(__MY_SQUAD__.current.squad, false)
        await join_squad('RESET', __MY_SQUAD__.current.squad)
        clearTimeout(catchLoading)
      }
    }
    // unset()
    const reset_initFeed = removeFromArr(['my_squad', 'global_squad'], initFeed)
    _initFeed(reset_initFeed)
    leaveAndJoinSquad()
  }

  function pathway(manual_change = null) {
    log2('userPathway', userPathway)
    if (manual_change) {
      if (!IN_DEPLOYMENT) {
        alert('@pathway() found "manual_change"')
      }
      let updatedUserPathway = removeFromArr(manual_change, userPathway)
      updatedUserPathway =
        updatedUserPathway.length === 0 ? [] : updatedUserPathway
      setUserPathway(updatedUserPathway)
    } else {
      const last = userPathway[userPathway.length - 1]
      switch (last) {
        case 'createEntry':
          _createEntry(false)
          break
        case 'entry':
          dispatch(rdx('ENTRY', null))
          break
        case 'focus':
          dispatch(rdx('FOCUS', null))
          break
        case 'journal':
          dispatch(rdx('JOURNAL', null))
          break
        case 'overlay':
          _overlay(null)
          break
        case 'trigger':
          setTriggerID(null)
          break
        default:
          break
      }
    }
  }

  function unset() {
    dispatch(rdx('FOCUS', null))
    _entry(null)
    setInfo(null)
    _journal(null)
    _overlay(null)
    setTriggerID(null)
  }

  // function unsetChatRooms() {
  //   setChatRoom(null)
  //   setChatRooms(false)
  //   setShowMobChatRooms(false)
  // }

  // dev & post fetch processing

  function get_dummies(stat_current = null) {
    let global_users_vol = stat_current
    let dummies = []

    if (!stat_current) {
      const global_squad_ranges = {
        g: [3000, 5000],
        1: [200, 400],
        2: [1000, 2000],
        3: [3000, 5000],
      }

      const global_squad_range =
        global_squad_ranges[__MY_SQUAD__.current.global]

      global_users_vol = randInt(global_squad_range[0], global_squad_range[1])
    }

    // gen 300 dummies

    const arrOf300 = []

    for (let i = 0; i < 300; i++) {
      arrOf300.push(i)
      dummies.push(`dummy-${i}`)
    }

    const representation = Math.floor(
      (__MY_SQUAD__.current.stat_current / global_users_vol) * 300
    )

    if (representation > 0) {
      const randRepdUsers = randFromArr(
        __MY_SQUAD__.current.users,
        representation
      )
      const rand_UNREPD_Dummies = randFromArr(arrOf300, representation)

      for (let i = 0; i < representation; i++) {
        dummies[rand_UNREPD_Dummies[i]] = randRepdUsers[i]
      }
    }

    dummies = fun().shuffle(dummies)

    return [global_users_vol, dummies]
  }

  function get_dummy_squad_image_posts() {
    const image_filenames = [
      'jwdsdldlldk.jpeg',
      '1697868627077613.jpg',
      'jsdjsdjsd.jpg',
      'vj9uf1f1utt41.jpg',
      // 'm2-res_720p.mp4',
      // 'videoplayback.mp4',
      'found-this-on-r-meme-v0-oa16mw5qwb7b1.png',
      'jdkjkdjfd.webp',
      'people-are-not-addicted-v0-pggo7mv7qcsc1.webp',
      'jdkjkdjfd.webp',
      'people-are-not-addicted-v0-pggo7mv7qcsc1.webp',
      'words-from-peter-v0-88dyqng8k9ac1.webp',
    ]
    const images_vol = randInt(1, 4)
    const images_arr = randFromArr(image_filenames, images_vol)
    return Array.from({ length: images_vol }, (_, index) => {
      const image = images_arr[index]
      return {
        id: `dummy:${image}`,
        type: 'image',
        obj: {
          src: image,
        },
      }
    })
  }

  // todo: sort. it. out! segmented_users_arr_ref.current = [[]]
  function segment_users() {
    const blockSize = 300
    // if (g) {
    //   let arr = [...__GLOBAL_SQUAD__.current.users]
    //   arr = fun().shuffle(arr)
    //   global_users_arr_ref.current = arr.slice(0, blockSize)
    // } else {
    const arrBlocks = []
    const arr = [...__MY_SQUAD__.current.users]
    if (arr.length === 0) {
      segmented_users_arr_ref.current = [[]]
    } else {
      for (let i = 0; i < arr.length; i += blockSize) {
        arrBlocks.push(arr.slice(i, i + blockSize))
      }
      segmented_users_arr_ref.current = arrBlocks
    }
    // }
  }

  // eventListeners: touchstart touchend keydown keyup
  useEffect(() => {
    if ($$swipeToSwitchSquad$$) {
      let startX
      let startY
      let threshold = desktop ? 200 : 50

      function handleTouchStart(event) {
        const touch = event.touches[0]
        startX = touch.clientX
        startY = touch.clientY
      }

      function handleTouchEnd(event) {
        const usersContainer = document.getElementById('-users-')
        let currentElement = event.target

        while (currentElement) {
          if (currentElement === usersContainer) {
            // alert('Scrolling inside #-users-')
            return
          }
          currentElement = currentElement.parentElement
        }

        const touch = event.changedTouches[0]
        const endX = touch.clientX
        const endY = touch.clientY
        const distX = endX - startX
        const distY = endY - startY

        if (Math.abs(distX) > Math.abs(distY) && Math.abs(distX) > threshold) {
          if (distX > 0) {
            // console.log(`Swipe Left ${Math.abs(distX)}`)
            switchSquad('squad')
          } else {
            // console.log(`Swipe Right ${Math.abs(distX)}`)
            switchSquad('global')
          }
          clearTimeout(tSquadSelection)
          const elm = document.getElementById('squad-selection')
          // elm.classList.remove('animate')
          elm.style.display = 'flex'
          // elm.classList.add('animate')
          tSquadSelection = setTimeout(() => {
            elm.style.display = 'none'
            // elm.classList.remove('animate')
          }, 2000)
        }
      }

      const swipeArea = document.getElementById('swipeArea')
      swipeArea.addEventListener('touchstart', handleTouchStart)
      swipeArea.addEventListener('touchend', handleTouchEnd)

      return () => {
        swipeArea.removeEventListener('touchstart', handleTouchStart)
        swipeArea.removeEventListener('touchend', handleTouchEnd)
      }
    }

    // window.addEventListener('keydown', listenKeyDown)
    // window.addEventListener('keyup', listenKeyUp)

    return () => {
      // window.removeEventListener('keydown', listenKeyDown)
      // window.removeEventListener('keyup', listenKeyUp)
      _location('my_squad')
    }
  }, [])

  useEffect(() => {
    loadSquad()
  }, [squad_id])

  // useEffect(() => {
  //   if (init) {
  //     if (
  //       __MY_SQUAD__.current.meta.status === 'starting soon' &&
  //       joinReport[squad_id] === 'RESET'
  //     ) {
  //       _showPopUp('RESET')
  //     }
  //   }
  // }, [init, squad_id])

  // scrollIntoView & auto-play & entries fade
  useEffect(() => {
    if (desktop && (location === 'my_squad' || location === 'global_squad')) {
      setTimeout(() => {
        window.scrollTo({ top: window.innerHeight - 150 })
      }, 100)
      // if (squadCredentialsRef.current) {
      //   squadCredentialsRef.current.scrollIntoView({
      //     block: 'center',
      //   })
      // }
    } else {
      window.scroll(0, 0)
    }
    if (location === 'feed') {
      if (!feedIsInited) {
        loadFeed(location)
      } else {
        const handleLoadedData = (video) => {
          observerRef.current.observe(video)
        }
        const observerOptions = {
          rootMargin: '-50% 0px',
        }
        observerRef.current = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            console.log('doing stuff...')
            const video = entry.target
            if (entry.isIntersecting) {
              video.play()
            } else {
              video.pause()
            }
          })
        }, observerOptions)
        videoRefs.current.forEach((video) => {
          if (video) {
            console.log('addingEventListener')
            video.addEventListener('loadeddata', () => handleLoadedData(video))
            return () => {
              console.log('removing')
              video.removeEventListener('loadeddata', () =>
                handleLoadedData(video)
              )
            }
          }
        })
        // f a d e
        const containers = entriesRefs.current
        containers.forEach((container) => {
          if (container.scrollHeight > 19.6 * 9) {
            container.classList.add('fade-container')
          }
        })
        return () => {
          console.log('@return ➂ useEffect [location]')
          if (observerRef.current) {
            console.log('observerRef.current.disconnecting...')
            observerRef.current.disconnect()
          }
        }
      }
    }
  }, [location, initFeed])

  // userPathway & window.scrollTo(0, scrollY.current)
  useEffect(() => {
    // console.log('@userPath useEffect')
    const dependencies = [
      // { val: chatRoom, prev: prevChatRoom.current, str: 'chatRoom' },
      // { val: chatRooms, prev: prevChatRooms.current, str: 'chatRooms' },
      // { val: createEntry, prev: prevCreateEntry.current, str: 'createEntry' },
      { val: gEntry, prev: prevEntry.current, str: 'entry' },
      { val: gFocus, prev: prevFocus.current, str: 'focus' },
      { val: gJournal, prev: prevJournal.current, str: 'journal' },
      { val: overlay, prev: prevOverlay.current, str: 'overlay' },
    ]
    let path = [...userPathway]
    let action
    const eval_dependency = (e) => {
      if (e.val) {
        if (path.includes(e.str)) {
          path = removeFromArr(e.str, path)
        }
        path.push(e.str)
        return 'pushed'
      }
      if (!e.val) {
        path = removeFromArr(e.str, path)
        return 'popped'
      }
    }
    for (let i = 0; i < dependencies.length; i++) {
      const dep = dependencies[i]
      if (dep.prev !== dep.val) {
        action = eval_dependency(dep)
        switch (dep.str) {
          case 'chatRoom':
            prevChatRoom.current = dep.val
            break
          case 'chatRooms':
            prevChatRooms.current = dep.val
            break
          // case 'createEntry':
          //   prevCreateEntry.current = dep.val
          //   if (action === 'pushed' && journal) {
          //     path = removeFromArr('journal', path)
          //     prevJournal.current = journal
          //     _journal(null)
          //   }
          //   break
          case 'entry':
            prevEntry.current = dep.val
            break
          case 'focus':
            prevFocus.current = gFocus
            break
          case 'journal':
            prevJournal.current = dep.val
            break
          case 'overlay':
            prevOverlay.current = dep.val
            break
          default:
            break
        }
      }
    }
    if (action) {
      setUserPathway(path)
      if (!path.length) {
        // window.scrollTo(0, scrollY.current)
        window.scroll(0, scrollY.current)
      } else {
        window.scroll(0, 0)
      }
    }
  }, [gEntry, gFocus, gJournal, overlay])

  // dev checks
  useEffect(() => {
    if (init) {
      if (
        __MY_SQUAD__.current?.stat_current !==
        __MY_SQUAD__.current?.users.length
      ) {
        alert("see 'dev checks' #1")
      }
    }
  }, [init])

  // 🔍

  //  <Chat
  //  <Farewell
  //  <LeftPanel
  //  <LogoTools
  //  <PopUp
  //  <Toolbar
  //  <UserProfile

  //  id = 'entry_proper'

  //  location === 'community'
  //  location === 'my_squad'
  //  location === 'feed'
  //  location === 'ladder'
  //  location === 'triggers'
  //  location === 'about'

  //  @squad
  //      <Chat
  //      <SquadActivity
  //      <Users

  return (
    <div
      id="swipeArea"
      className="App"
      user-agent={userAgent}
      location={location}
      style={{
        display: gMessages ? 'none' : 'block',
        height: desktop ? '100vh' : window.innerHeight + 'px',
        overflow:
          gChatRooms ||
          info ||
          loading ||
          overlay ||
          showFarewell ||
          (location === 'feed' && !feedIsInited)
            ? 'hidden'
            : 'initial',
      }}
    >
      {/* 📐 */}
      {/* <Rulers show="x" /> */}
      {/* 📐 */}
      {!init ? (
        <Preloader />
      ) : (
        <>
          <LogoTools
            props={{
              community_id: community_id,
              createEntry: createEntry,
              g: g,
              location: location,
              overlay: overlay,
              route: 'App',
              showPopUp: showPopUp,
              userPathway: userPathway,
              _community_id: _community_id,
              _createEntry: _createEntry,
              _location: _location,
              _route: _route,
              _showPopUp: _showPopUp,
              handleStatus: handleStatus,
              switchSquad: switchSquad,
              pathway: pathway,
              unset: unset,
            }}
          />
          {showPopUp && (
            <PopUp
              props={{
                showPopUp: showPopUp,
                _showPopUp: _showPopUp,
              }}
            />
          )}
          {overlay === 'global rank info' && (
            <GlobalRankInfo
              props={{
                thisCommunityLib: thisCommunityLib,
                currentDivision: __SQUAD__.squad,
              }}
            />
          )}
          {chatRooms ? (
            <Chat
              props={{
                __CHAT__: __CHAT__,
                chatRooms: chatRooms,
                loading: loading,
                squad_id: squad_id,
                // unsetChatRooms: unsetChatRooms,
                usersHeight: usersHeight,
                chat_init: chat_init,
                setChatRooms: setChatRooms,
              }}
            />
          ) : null}
          {showFarewell && (
            <Farewell
              props={{
                community_id: community_id,
                user: user,
                _showFarewell: _showFarewell,
                operator: operator,
              }}
            />
          )}
          {gFocus && lastElm(userPathway) === 'focus' ? (
            <UserProfile
              props={{
                __SQUAD__: __SQUAD__,
                focus_user_id: gFocus,
                focus_user: __USERS__.current[gFocus],
                location: location,
                get_entries: get_entries,
              }}
            />
          ) : null}
          {gEntry || gJournal ? (
            <div id="entry_proper" className="quick-view">
              {get_entry()}
            </div>
          ) : null}
          {desktop && gJournal && (
            <LeftPanel
              props={{
                __JOURNALS__: __JOURNALS__,
                handleJournals: handleJournals,
              }}
            />
          )}
          {createEntry && (
            <CreateEntry
              props={{
                entryType: createEntry,
                _createEntry: _createEntry,
                _journal: _journal,
                handleEntries: handleEntries,
                handleJournals: handleJournals,
              }}
            />
          )}
          {desktop && userPathway.length > 0 && <Toolbar pathway={pathway} />}
          {location === 'community' ? (
            <Community
              props={{
                __MY_SQUAD__: __MY_SQUAD__,
                community_id: community_id,
                _showPopUp: _showPopUp,
                leave_squad: leave_squad,
                pathway: pathway,
                RESET: RESET,
              }}
            />
          ) : null}
          {location === 'my_squad' || location === 'global_squad' ? (
            __SQUAD__.meta.status === false ? (
              <p className="xy">Squad has ended.</p>
            ) : (
              <div
                style={{
                  display:
                    gChatRooms ||
                    createEntry ||
                    gEntry ||
                    gFocus ||
                    gJournal ||
                    overlay
                      ? 'none'
                      : 'block',
                }}
              >
                {/* <div id="squad-selection" className="-f-">
                  <div className={'-f-ja -mod squad ' + (g ? 'color' : 'yes')}>
                    <Elimination />
                    <p>Elimination</p>
                  </div>
                  <div
                    className={
                      '-f-ja inline-3 -mod global ' + (g ? 'yes' : 'color')
                    }
                  >
                    <Ranked />
                    <p>Ranked</p>
                  </div>
                </div> */}
                <div id="__SQUAD__">
                  <Chat
                    props={{
                      __CHAT__: __CHAT__,
                      chatRooms: chatRooms,
                      loading: loading,
                      squad_id: squad_id,
                      // unsetChatRooms: unsetChatRooms,
                      usersHeight: usersHeight,
                      chat_init: chat_init,
                      setChatRooms: setChatRooms,
                    }}
                  />
                  {__SQUAD__.meta.status === 'starting soon' ? (
                    <div id="StartingIn">
                      <p id="p-starting-in">Starting In</p>
                      <StartingIn iso={__SQUAD__.meta.inception} />
                    </div>
                  ) : g || __SQUAD__.meta.status === true ? (
                    <>
                      {get_squad_overview()}
                      {location === 'my_squad' && (
                        <>
                          <SquadActivity
                            props={{
                              __MY_SQUAD__: __MY_SQUAD__,
                              __USERS__: __USERS__,
                              initSquadActivity: initSquadActivity,
                              loading: loading,
                              _location: _location,
                              setTriggerID: setTriggerID,
                              handleStatus: handleStatus,
                            }}
                          />
                          {__JOURNALS__ ? (
                            get_squad_journals()
                          ) : (
                            <div id="await_journals" className="-f-j sticky">
                              <Preloader xy={false} />
                            </div>
                          )}
                          {feedIsInited ? (
                            get_squad_recent()
                          ) : (
                            <div className="posrel">
                              <Preloader />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
                <Users
                  props={{
                    thisCommunityLib: thisCommunityLib,
                    __SQUAD__: __SQUAD__,
                    __USERS__: __USERS__,
                    global_users_arr_ref: global_users_arr_ref,
                    segmented_users_arr_ref: segmented_users_arr_ref,
                    _300_: _300_,
                    chatRooms: chatRooms,
                    g: g,
                    loading: loading,
                    squad_id: squad_id,
                    setUsersHeight: setUsersHeight,
                    moreSquad: moreSquad,
                    switchSquad: switchSquad,
                    initUsers: initUsers,
                  }}
                />
              </div>
            )
          ) : null}
          {location === 'feed' ? (
            <div
              id="FEED"
              className="clear-toolbars"
              style={{
                display:
                  gFocus || gEntry || overlay || createEntry ? 'none' : 'block',
              }}
            >
              <div id="toolbar">
                <div id="global-filters" className="-f-a">
                  <div className="hoverDrop">
                    <div className="-f-a -filter">
                      <p className="mtb0">Sort: </p>
                      <div className="-f-a filter selected">
                        <_cookie_ />
                        <p className="mtb0">Hot</p>
                      </div>
                    </div>
                    <div className="drop">
                      <div className="-f-a filter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M14 16C13.4271 16.6318 12.7395 17 12 17C11.2605 17 10.5729 16.6318 10 16"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M9 11.5C8.71357 11.1841 8.36974 11 8 11C7.63026 11 7.28643 11.1841 7 11.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M17 11.5C16.7136 11.1841 16.3697 11 16 11C15.6303 11 15.2864 11.1841 15 11.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M12 2C10.3431 2 9 3.34315 9 5C9 6.65685 10.3431 8 12 8C12.7684 8 13.4692 7.71115 14 7.23611"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                        <p className="mtb0">New</p>
                      </div>
                      <div className="-f-a filter">
                        <svg
                          id="top"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M9.5 10.5C9.99153 9.9943 11.2998 8 12 8M14.5 10.5C14.0085 9.9943 12.7002 8 12 8M12 8V16"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p className="mtb0">Top</p>
                      </div>
                    </div>
                  </div>
                  <div className="hoverDrop inline">
                    <div className="-f-a -filter">
                      <p className="mtb0">Type: </p>
                      <div className="-f-a filter selected">
                        <_media_all_ />
                        <p className="mtb0">All</p>
                      </div>
                    </div>
                    <div className="drop">
                      <div className="-f-a filter">
                        <_media_img_ />
                        <p className="mtb0">Media</p>
                      </div>
                      <div className="-f-a filter">
                        <_media_txt_ />
                        <p className="mtb0">Text</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p id="p-create-post" onClick={() => _createEntry('entry')}>
                  Create Post
                </p>
              </div>
              {initFeed.includes(location) ? (
                get_entries(location)
              ) : (
                <Preloader />
              )}
            </div>
          ) : null}
          {location === 'triggers' ? (
            <Triggers
              props={{
                __SQUAD__: __SQUAD__,
                triggerID: triggerID,
                user: user,
                setTriggerID: setTriggerID,
                pathway: pathway,
              }}
            />
          ) : null}
        </>
      )}
    </div>
  )
}

// <div id="happening-now">
//   <p className="h2">From the site</p>
//   <Happening />
// </div>

export default App
