import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rdx } from '../actions'

import { src } from '../.core'
import { fun } from '../.fun'

import { desktop, mobile } from '../components/ROUTERJS'

import Preloader from './Preloader.js'

import _collapse_ from '../svg/Collapse.js'
import _expand_ from '../svg/Expand.js'
import _reload_ from '../svg/Reload.js'
import _squad_ from '../svg/Squad.js'
import _squadKO_ from '../svg/SquadKO.js'

import Elimination from '../svg/modifiers/Elimination.js'
import Ranked from '../svg/modifiers/Ranked.js'

const { notice } = fun()
const { yellow } = fun().logMethods

let get_users_pin = null

export default function Users({
  props: {
    thisCommunityLib,
    __SQUAD__,
    __USERS__,
    segmented_users_arr_ref,
    _300_,
    chatRooms,
    g,
    loading,
    squad_id,
    setUsersHeight,
    moreSquad,
    switchSquad,
    initUsers,
  },
}) {
  const usersRef = useRef()
  const mobTouchedUser = useRef()

  const get_users_counter_ref = useRef(0)

  const { gEntry, gFocus, gJournal } = useSelector((state) => ({
    gEntry: state.entry,
    gFocus: state.focus,
    gJournal: state.journal,
  }))
  const dispatch = useDispatch()

  const [div_users, _div_users] = useState()
  const [squad_collapse, _squad_collapse] = useState(mobile ? true : false)
  const [squad_expand, _squad_expand] = useState(false)

  const handle_collapse = () => {
    _squad_collapse(true)
    _squad_expand(false)
    usersRef.current.scroll(0, 0)
  }

  function get_users() {
    get_users_counter_ref.current = get_users_counter_ref.current + 1
    yellow(`@get_users() invocations: ${get_users_counter_ref.current}`)

    let alreadyGotDummies = (g && get_users_pin) || (!g && !get_users_pin)

    // console.log({ g: g, get_users_pin: get_users_pin })
    // console.log('alreadyGotDummies -> ', alreadyGotDummies)

    // notice('reg')
    // console.log('__SQUAD__.users')
    // console.log(__SQUAD__.users)
    // notice('green')
    // console.log('__USERS__.current')
    // console.log(__USERS__.current)

    let pool = []

    let dummy_users = {}
    let hasDummyUsers

    const isBlock = !squad_expand

    let rows
    let diameter
    let collapse
    let width
    let height

    const def_rows = (users_vol) => {
      if (desktop) {
        let rows = Math.round(Math.sqrt(users_vol) / 2)
        return rows > 7 ? 7 : rows
        // const users_width = 600 - 32
        // const user_width = 36
        // const max_columns = Math.floor(users_width / user_width)
        // return max_columns
      } else {
        let rows = Math.round(Math.sqrt(users_vol) / 1.5)
        return rows > 14 ? 14 : rows
      }
    }
    const user = (obj, idx) => {
      const style = () => {
        const column = Math.floor(idx / rows) // into function
        return {
          left: column * (diameter - collapse) + 'px',
          top: (idx % rows) * (diameter - collapse) + 'px',
        }
      }
      const img = () => {
        if (obj.id.includes('dummy')) {
          return (
            <div className="dummy-img">
              <div style={{ background: obj.col }}></div>
            </div>
          )
        } else {
          const [filename, extension] = obj.img.split('.')
          return (
            <img
              src={src(
                'img-compressed/' +
                  filename +
                  [extension === 'gif' ? '.gif' : '.webp']
              )}
              alt={obj.usn}
            />
          )
        }
      }
      return (
        <div
          id={`u${idx}`}
          className="-user"
          style={isBlock ? style() : null}
          onClick={() =>
            desktop
              ? dispatch(rdx('FOCUS', obj.id))
              : mobTouchedUser.current === obj.id
              ? dispatch(rdx('FOCUS', obj.id))
              : (mobTouchedUser.current = obj.id)
          }
        >
          {img()}
          <p className={'username x ' + (!isBlock ? 'rlink' : '')}>{obj.usn}</p>
        </div>
      )
    }
    const dummy = (id) => ({
      id: id,
      usn: id,
      col: fun().randCol(),
      flag: 'US',
    })

    const users_arr = isBlock
      ? g
        ? __SQUAD__.users
        : segmented_users_arr_ref.current[_300_]
      : __SQUAD__.users

    const users_vol = users_arr.length

    // to do: when in global users order wants to be scrambled

    // notice('blue')
    // console.log('users_arr')
    // console.log(users_arr)

    if (isBlock) {
      rows = def_rows(users_vol)
      diameter = (mobile ? 2.5 : 2) * 16 + 4
      collapse = Math.round(diameter / 3)
      width = (diameter - collapse) * Math.ceil(users_vol / rows) + collapse
      height = rows * (diameter - collapse) + collapse

      for (let i = 0; i < users_vol; i++) {
        const obj = (id) => {
          if (id.includes('dummy') && !alreadyGotDummies) {
            hasDummyUsers = true
            const $dummy = dummy(id)
            dummy_users[id] = $dummy
            return $dummy
          } else {
            return { ...__USERS__.current[id], id: id }
          }
        }
        const id = users_arr[i]
        pool.push(user(obj(id), i))
      }
    } else {
      const users_dict = {}
      const usernames_arr = []
      for (let i = 0; i < users_vol; i++) {
        const obj = (id) => {
          if (id.includes('dummy')) {
            hasDummyUsers = true
            const $dummy = {
              id: id,
              usn: id,
              col: fun().randCol(),
              flag: 'US',
            }
            dummy_users[id] = $dummy
            return $dummy
          } else {
            return { ...__USERS__.current[id], id: id }
          }
        }
        const id = users_arr[i]
        const $user = obj(id)
        const usn = $user.usn
        usernames_arr.push(usn)
        users_dict[usn] = $user
      }
      usernames_arr.sort()
      for (let i = 0; i < users_vol; i++) {
        const user_to_push = users_dict[usernames_arr[i]]
        pool.push(user(user_to_push, i))
      }
    }

    if (hasDummyUsers) {
      yellow('hasDummyUsers')
      __USERS__.current = { ...__USERS__.current, ...dummy_users }
    }

    get_users_pin = g

    const div_users = (
      <div
        id="-users"
        className={desktop && width > 600 - 16 * 2 ? 'overflow' : ''}
        style={{
          visibility: loading ? 'hidden' : 'visible',
          width: width + 'px',
          height: height + 'px',
        }}
      >
        {pool}
      </div>
    )

    _div_users(div_users)
  }

  if (mobile) {
    useEffect(() => {
      if (squad_collapse) {
        document.body.classList.remove('noscroll')
      } else {
        document.body.classList.add('noscroll')
      }
    }, [squad_collapse])
  }

  useEffect(() => {
    get_users()
  }, [initUsers, squad_expand])

  useEffect(() => {
    if (div_users) {
      setUsersHeight(usersRef.current.clientHeight)
    }
  }, [div_users])

  useEffect(() => {
    if (g) {
      const iUsers = setInterval(() => {
        const randInt = fun().randInt(0, 299)
        const randDummy = document.querySelector(
          `#u${randInt} .dummy-img > div`
        )
        if (randDummy) {
          randDummy.style.background = fun().randCol()
        } else {
          console.log(`no #u${randInt}`)
        }
      }, 2000)
      return () => {
        clearInterval(iUsers)
      }
    }
  }, [g])

  return (
    <>
      {/* squad ko */}
      <div
        id="show-squad"
        className={'-f-j ' + (squad_collapse && 'squad-hidden')}
        style={{ display: chatRooms ? 'none' : 'flex' }}
      >
        <div
          onClick={() => {
            _squad_collapse(false)
          }}
        >
          <_squadKO_ />
        </div>
      </div>
      {desktop ? (
        <div
          ref={usersRef}
          id="_users_"
          className={squad_expand && 'expand'}
          style={{
            display:
              !squad_collapse && !gFocus && !gEntry && !gJournal && !chatRooms
                ? 'block'
                : 'none',
          }}
        >
          <div id="wrapper" className="-f-ja">
            {loading && <Preloader />}
            {div_users}
          </div>
          <div id="squad-handles">
            <div
              className={
                '-mod squad click-me low-light ps-left ' + (g ? '' : 'yes')
              }
              onClick={() => switchSquad('my_squad')}
            >
              <Elimination />
            </div>
            <div
              className={
                '-mod global click-me low-light stack ps-left ' +
                (g ? 'yes' : '')
              }
              onClick={() => switchSquad('global_squad')}
            >
              <Ranked />
            </div>
          </div>
          <div
            className="--f-j right-side-tools"
            style={{ visibility: loading ? 'hidden' : 'visible' }}
          >
            <div id="squad-min-max" className="-f-a">
              <div
                className="click-me-after"
                onClick={() => _squad_expand(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 4V20M20 12H4"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="click-me-after inline"
                onClick={() =>
                  squad_expand ? _squad_expand(false) : _squad_collapse(true)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20 12L4 12"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="-f-a -squad-stroke">
              <_squad_ />
              <p className="nomarj inline dim">{__SQUAD__.stat_current}</p>
            </div>
            {g && (
              <div
                id="_reload"
                className="click-me-after"
                onClick={() => moreSquad()}
              >
                <_reload_ />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          ref={usersRef}
          id="_users_"
          className={
            squad_collapse ? 'squad-hidden' : squad_expand ? 'expand' : 'block'
          }
          style={{
            display:
              !gFocus && !gEntry && !gJournal && !chatRooms ? 'block' : 'none',
            height: window.innerHeight + 'px',
          }}
        >
          <div
            id="credentials"
            className={'-f-a ' + (g ? 'global' : 'squad')}
            onClick={() => handle_collapse()}
          >
            <img src={src('community-icons/' + thisCommunityLib.img)} alt="" />
            <p className="squad-id inline">
              {g ? `Div. ${2}` : `#${squad_id}`}
            </p>
            {g && (
              <div className="global-rank inline">
                <div></div>
                <div className={'current'}></div>
                <div></div>
              </div>
            )}
          </div>
          <div id="wrapper" className="-f-ja">
            {div_users}
          </div>
          <div className="tools">
            <div className="ret">
              <div
                id="_expand_"
                className={
                  'click-me dim-bg noselect' + (squad_expand ? ' active' : '')
                }
                onClick={() => {
                  _squad_expand(!squad_expand)
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 16"
                  fill="none"
                >
                  <path stroke-width="2" stroke-linecap="round" d="M7,1h12" />
                  <path stroke-width="2" stroke-linecap="round" d="M1,1h2" />
                  <path stroke-width="2" stroke-linecap="round" d="M7,8h12" />
                  <path stroke-width="2" stroke-linecap="round" d="M1,8h2" />
                  <path stroke-width="2" stroke-linecap="round" d="M7,15h12" />
                  <path stroke-width="2" stroke-linecap="round" d="M1,15h2" />
                </svg>
              </div>
            </div>
            <div id="squad-handles">
              <div
                className={'click-me dim-bg squad ' + (g ? '' : 'yes')}
                onClick={() => switchSquad('my_squad')}
              >
                <Elimination />
              </div>
              <div
                className={
                  'click-me dim-bg global inline-2 ' + (g ? 'yes' : '')
                }
                onClick={() => switchSquad('global_squad')}
              >
                <Ranked />
              </div>
            </div>
            <div
              id="_collapse_"
              className={
                'click-me noselect' + (squad_collapse ? '' : ' active')
              }
              onClick={() => {
                handle_collapse()
              }}
            >
              <_collapse_ />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
