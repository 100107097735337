import React from 'react'

export default function Promoted() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.5 17.5">
      <path
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        d="M14.8,0.8h-12"
      />
      <path
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        d="M8.8,4.8v12"
      />
      <path
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        d="M12.7,8.8c0,0-2.9-4-4-4c-1.1,0-4,4-4,4"
      />
    </svg>
  )
}
