import React from 'react'

export default function Ranked() {
  return (
    <svg
      className="mod global"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.4 17.4"
      fill="none"
    >
      <path strokeWidth="3" d="M12.2,12.1l-3.5,3.5l-3.5-3.5" />
      <path strokeWidth="3" d="M8.7,15.6V1.8" />
      <path strokeWidth="3" d="M5.2,5.3l3.5-3.5l3.5,3.5" />
    </svg>
  )
}
