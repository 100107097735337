import React, { useEffect, useState, useRef } from 'react'
import { fun } from '../.fun.js'
import Ladder from '../components-sub/Ladder.js'

// 📍

export default function Triggers({
  props: { __SQUAD__, triggerID, user_rip, setTriggerID, pathway },
}) {
  const [display, _display] = useState('Triggers')

  const log1 = (a, b) => fun().log1(a, b)
  const log2 = (a, b) => fun().log2(a, b)

  return triggerID ? null : (
    <div id="TRIGGERS">
      {display === 'Triggers' && (
        <div className="xy">
          <p className="ph2">{'< Triggers />'}</p>
          <p className="ph2" onClick={() => _display('Ladder')}>
            {'< Ladder />'}
          </p>
        </div>
      )}
      {display === 'Ladder' && (
        <Ladder
          props={{
            __SQUAD__: __SQUAD__,
            triggerID: triggerID,
            user_rip: user_rip,
            setTriggerID: setTriggerID,
          }}
        />
      )}
    </div>
  )
}
