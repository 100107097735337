import React, { useState, useRef } from 'react'
import StartingIn from './components-sub/StartingIn'
import _battery_ from './svg/Battery'

export function I() {
  return {
    add_activity: (payload) => {
      const statusRef = useRef()
      const [status_option, _status_option] = useState(false)
      const [bzz, _bzz] = useState(false)
      function handleStatus() {
        if (statusRef.current.value.length > 0) {
          payload.handleStatus('create', status_option, statusRef.current.value)
        } else {
          _bzz(true)
        }
      }
      return (
        <div>
          {status_option ? (
            <>
              {status_option === 'charged' ? (
                <>
                  <p style={{ margin: 0 }}>
                    Describe your activity, e.g. "working out"
                  </p>
                  <p className="sub">
                    Your activity will stay active for 30 mins.
                  </p>
                </>
              ) : (
                <>
                  <p style={{ margin: 0 }}>
                    Tell others why, e.g. "feeling low"
                  </p>
                  <p className="sub">
                    Your activity will stay active for 6 hours.
                  </p>
                </>
              )}
              <input
                ref={statusRef}
                className={bzz ? 'bzz' : ''}
                type="text"
                placeholder={bzz ? 'Required' : '...'}
                onChange={() => _bzz(false)}
              />
              <p id="status-submit" onClick={() => handleStatus()}>
                submit
              </p>
            </>
          ) : (
            <div className="-f-a">
              <div
                className="status-option"
                onClick={() => _status_option('help')}
              >
                <div id="-add-fading" className="-f-ja -svg">
                  <_battery_ cells={1} animate={false} />
                </div>
                <p>Fading</p>
              </div>
              <div
                className="status-option"
                style={{ marginLeft: '4rem' }}
                onClick={() => _status_option('charged')}
              >
                <div id="-add-activity" className="-f-ja -svg">
                  <_battery_ cells={4} animate={false} />
                </div>
                <p>Active</p>
              </div>
            </div>
          )}
        </div>
      )
    },
    RESET: (payload) => (
      <>
        <p className="mt0 color">Squads are launched every day.</p>
        <p className="color">You failed the squad of today.</p>
        <p className="color">
          You are now waiting in squad{' '}
          <span className="squad-id">
            #{payload.__MY_SQUAD__.current.squad}
          </span>
          .
        </p>
        <p className="color">
          Your squad launches in{' '}
          {<StartingIn iso={payload.__MY_SQUAD__.current.meta.inception} />}
        </p>
      </>
    ),
  }
}
