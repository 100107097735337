import React from 'react'

export default function Tick({ type = 'stroke', strokeWidth = 1.5 }) {
  return (
    <>
      {type === 'stroke' && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            d="M5 14L8.5 17.5L19 6.5"
            stroke="currentColor"
            stroke-width={strokeWidth}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
      {type === 'blocky' && (
        <svg
          className="_tick_"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 253 204"
        >
          <polygon points="253,43 92,204 0,113 43,69 92,118 210,0 " />
        </svg>
      )}
    </>
  )
}
