import React from 'react'

export default function About() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 21.5">
      <circle
        stroke="currentColor"
        stroke-width="1.5"
        cx="10.8"
        cy="10.8"
        r="10"
      />
      <path
        className="guts"
        d="M10.992,15.75v-5c0-0.471,0-0.707-0.146-0.854C10.699,9.75,10.464,9.75,9.992,9.75"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        className="guts"
        d="M10.742,6.75h0.009"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
