import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rdx } from '../actions'
import { fun } from '../.fun'
import { backend, src } from '../.core'
import { DJANGO, IN_DEPLOYMENT } from '../components/ROUTERJS'
import { user_id } from '../components/ROUTERJS'
import ArrowBack from '../svg/ArrowBack'
import PlusMinus from '../svg/PlusMinus'

// temp
import REACT_APP_users from '../data/REACT_APP.users.json'

export default function Messages({ convos, user }) {
  const msgs_ref = useRef({})
  const chatRef = useRef()

  const dispatch = useDispatch()

  const [openMsg, _openMsg] = useState(null)
  const [chat, _chat] = useState(null)

  const REACT_APP_convos = [
    {
      conv_id: 'dummy_1',
      party: ['1', '2'],
      msg_count: 2,
    },
  ]
  const REACT_APP_msgs = {
    dummy_1: {
      msg_1: {
        iso: null,
        usr: '1',
        msg: 'Hi',
      },
      msg_2: {
        iso: null,
        usr: '2',
        msg: 'How you doing?',
      },
      msg_3: {
        iso: null,
        usr: '1',
        msg: 'Not bad... You?',
      },
    },
  }

  function handleChat(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (!chat) return
      const chat_entry = {
        user: user_id,
        msg: chatRef.current.value,
      }
      if (IN_DEPLOYMENT) {
        backend(chat_entry)
      }
      const copy_chat = { ...chat, chat_entry }
      __CHAT__.current.log = copy_chat.log
      chatRef.current.value = ''
      _chat(copy_chat)
    }
  }

  function get_convos(params) {
    // SELECT COUNT(*)
    // FROM messages
    // WHERE chat_id = ? AND id > ?
    const convo = (obj, unread) => (
      <>
        <div
          className="-f- message"
          onClick={() => _openMsg(obj.conv_id) + get_chat(obj.conv_id)}
        >
          <p className="username">
            {obj.party.map(
              (u, index) =>
                u !== user_id && (
                  <>
                    {REACT_APP_users[u].usn}
                    {index < obj.party.length - 1 ? ', ' : ''}
                  </>
                )
            )}
          </p>
          {unread > 0 && <div className="indicator-unread">{unread}</div>}
        </div>
        <div className="separator"></div>
      </>
    )
    const data = DJANGO ? convos.current : REACT_APP_convos
    return data.map((c) => {
      const unread = c.msg_count - user.convos[c.conv_id].last_current
      return convo(c, unread)
    })
  }

  function get_chat(id) {
    const message = (m) => {
      // const time = fun().niceDate(m.date, 'T:t')
      const u_id = m.usr
      const u = REACT_APP_users[u_id]
      return (
        <div className="-f- __msg">
          {/* <img
            className="usr"
            src={src('img-compressed/' + u.img.split('.')[0] + '.webp')}
            alt=""
            onClick={() => dispatch(rdx('FOCUS', u_id))}
          /> */}
          <div className={'_msg'}>
            <p className="usn">
              <span
                className="span-usn"
                onClick={() => dispatch(rdx('FOCUS', u_id))}
              >
                {u.usn}
              </span>
              {/* <span className="time-chat dim inline">{time}</span> */}
            </p>
            {m.hasOwnProperty('src') ? (
              <img className="img-submission" src={src(m.src)} alt="" />
            ) : (
              <p className="msg">{m.msg}</p>
            )}
          </div>
        </div>
      )
    }
    const then = () => {
      const chat = Object.values(msgs_ref.current[id]).map((m) => message(m))
      _chat(chat)
    }
    if (DJANGO) {
      backend(`__messhall/get_chat?conv_id=${id}`).then((response) => {
        if (response.status === 200) {
          const new_msgs = response.body.chat
          if (!msgs_ref.current[id]) {
            msgs_ref.current[id] = new_msgs
          } else {
            Object.assign(msgs_ref.current[id], new_msgs)
          }
          then()
        }
      })
    } else {
      msgs_ref.current[id] = REACT_APP_msgs[id]
      then()
    }
  }

  return (
    <div id="Messages">
      <div id="-left-panel" className="posrel">
        <div id="navigation" className="menu">
          <div id="newMsg" className="click-me low-light ui-handle">
            <PlusMinus type="Plus" />
          </div>
          <div className="messages-menu">{get_convos()}</div>
        </div>
      </div>
      <div className={'conversation-window' + (openMsg ? ' openMsg' : '')}>
        <div id="toolbar">
          <ArrowBack pathway={() => dispatch(rdx('MESSAGES', false))} />
        </div>
        <div className="-chat">
          <div id="chat">{chat}</div>
          <div className="-textarea background">
            <textarea
              ref={chatRef}
              type="text"
              placeholder="Chat..."
              onKeyDown={(e) => handleChat(e)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
