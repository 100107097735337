import React from 'react'

export default function Globe({ type = null }) {
  return (
    <div id="-earth">
      {type === 'line' ? null : (
        <svg
          id="earth-solid"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21.5 21.472"
        >
          <path
            d="M18.126,13.661c-0.718,0.412-1.536,0.88-2.522,0.682c-2.195-0.44-3.042-0.213-3.367,0.053
    	c-0.137,0.113-0.319,0.339-0.319,0.999c0,1.834-0.032,1.96-0.845,3.357c-0.419,0.72-0.319,1.042,0.946,2.665
    	c4.548-0.533,8.269-3.888,9.238-8.417C19.5,12.918,18.932,13.2,18.126,13.661z"
          />
          <path
            d="M6.399,4.481c1.06,1.12,2.222,1.509,3.104,1.041c0.392-0.207,0.424-0.386,0.501-0.82c0.104-0.584,0.263-1.468,1.572-1.986
    	c0.263-0.104,0.436-0.268,0.516-0.488c0.089-0.246,0.056-0.565-0.442-2.187C11.351,0.017,11.052,0,10.75,0
    	C8.13,0,5.661,0.946,3.699,2.647C5.217,3.249,5.782,3.829,6.399,4.481z"
          />
          <path
            d="M9.775,17.998c0.641-1.103,0.641-1.103,0.641-2.604c0-0.974,0.284-1.68,0.87-2.159c0.872-0.715,2.292-0.827,4.611-0.363
    	c0.414,0.081,0.834-0.141,1.482-0.512c0.811-0.465,1.815-1.023,4.088-0.828c0.018-0.26,0.032-0.521,0.032-0.783
    	c0-4.977-3.419-9.275-8.203-10.435c0.388,1.333,0.39,1.914,0.204,2.426c-0.227,0.622-0.714,1.109-1.373,1.37
    	c-0.532,0.211-0.564,0.387-0.648,0.856c-0.095,0.531-0.239,1.333-1.277,1.882C9.728,7.1,9.221,7.223,8.702,7.223
    	c-1.135,0-2.329-0.587-3.392-1.71C4.575,4.736,4.242,4.44,2.552,3.795C0.902,5.736,0,8.167,0,10.75
    	c0,5.74,4.52,10.427,10.188,10.722C9.254,20.178,9.038,19.267,9.775,17.998z"
          />
        </svg>
      )}
      <svg
        id="earth"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21.5 21.5"
      >
        <path
          style={{
            fill: 'none',
            stroke: 'currentColor',
            strokeWidth: 1.5,
            strokeLinejoin: 'round',
          }}
          d="M10.75,20.75c-5.523,0-10-4.477-10-10c0-2.793,1.145-5.318,2.99-7.132 M10.75,20.75
    c-0.963-0.714-0.809-1.545-0.326-2.375c0.743-1.277,0.743-1.277,0.743-2.98s1.012-2.501,4.583-1.787
    c1.605,0.321,2.774-1.896,4.857-1.164 M10.75,20.75c4.946,0,9.053-3.59,9.857-8.307 M20.607,12.443
    c0.094-0.55,0.143-1.116,0.143-1.693c0-4.881-3.498-8.946-8.123-9.824 M12.627,0.926c0.511,0.94,0.305,2.06-0.774,2.487
    c-1.76,0.697-0.5,1.98-1.999,2.773c-1,0.528-2.499,0.396-3.999-1.188C5.065,4.163,4.59,3.708,3.74,3.618 M12.627,0.926
    C12.019,0.81,11.391,0.75,10.75,0.75c-2.73,0-5.205,1.094-7.01,2.868"
        />
      </svg>
    </div>
  )
}
