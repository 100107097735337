import React from 'react'

export default function MediaImg({ color = false }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <circle
        cx="7.5"
        cy="7.5"
        r="1.5"
        stroke={color ? 'palegoldenrod' : 'currentColor'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
        stroke={color ? 'cornflowerblue' : 'currentColor'}
        stroke-width="1.5"
      />
      <path
        d="M5 20.9999C9.37246 15.775 14.2741 8.884 21.4975 13.5424"
        stroke={color ? 'tomato' : 'currentColor'}
        stroke-width="1.5"
      />
    </svg>
  )
}
