import React from 'react'

export default function Elimination() {
  return (
    <svg
      className="mod squad"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path stroke="currentColor" strokeWidth="3" d="M17,8v5h-5" />
      <path
        stroke="currentColor"
        strokeWidth="3"
        d="M17,13l-5-5c-0.883-0.883-1.324-1.324-1.865-1.373c-0.09-0.008-0.18-0.008-0.269,0C9.324,6.676,8.883,7.117,8,8
	S6.676,9.324,6.135,9.373c-0.09,0.008-0.18,0.008-0.269,0C5.324,9.324,4.883,8.883,4,8L1,5"
      />
    </svg>
  )
}
