import React from 'react'

// thicker spinner
export default function Spinner2() {
  return (
    <svg
      className="Spinner"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.6 23.6"
    >
      <path
        d="M23.6,11.8C23.6,5.3,18.3,0,11.8,0c0,0,0,0,0,0v5c0,0,0,0,0,0c3.7,0,6.8,3,6.8,6.8c0,2.2-1.1,4.2-2.7,5.4l3,4
		C21.7,19.1,23.6,15.7,23.6,11.8z"
      />
    </svg>
  )
}
