import React from 'react'
import ArrowBack from '../svg/ArrowBack'

export default function Toolbar({ pathway, title = null }) {
  return (
    <div id="toolbar" className={title && 'with-title'}>
      <ArrowBack pathway={pathway} />
      {title && (
        <>
          <p className="t">{title}</p>
          <div className="ret"></div>
        </>
      )}
    </div>
  )
}
