import React, { useEffect, useState, useRef } from 'react'

import { user_id } from '../components/ROUTERJS'

import { src, backend } from '../.core'
import { fun } from '../.fun'

// backend
import users from '../data/REACT_APP.users.json'

import lib_users from '../data/lib.users.json'

import _about from '../svg/About'
import _arrowBack from '../svg/ArrowBack'
import _calendar from '../svg/Calendar'
import X from '../svg/X'

import _beginner from '../svg/modifiers/Beginner'
import _elimination from '../svg/modifiers/Elimination'
import _star from '../svg/modifiers/Star'

const users_lib_entries = Object.entries(lib_users)

const Modifiers = {
  event: '1',
  community: <_star />,
}

const modifiers_lib = {
  types: {
    type: ['community', 'event'],
  },
  info: {
    community:
      'A start-anytime challenge or commitment. Every community has a global ranked squad comprising the entire community base. A ranked squad consists of seperate ranks and users or squads that fail the conditions of the community are demoted or promoted through these ranks. A community also has the option to host elimination squads. In an elimination squad users who fail the conditions of the community are eliminated and automatically re-entered into a new squad.',
    event:
      'A 1 time challenge. There is 1 global squad and no respawns. Select this option for 1 Offs and Monthly Challenges, e.g. Veganuary.',
  },
}

const intervals_arr = ['1 Day', '3 Days']

// ## todo
//
// ## make common form elements a grid for better automatic height uniformity. Requires changes to 'Launch' form-field which is a flex-align.
//
// ## 'Ranked / Global'(?) Divisions form field. Plus input division intervals.
//
// ## Event. add reinvocation interval

export default function CreateCommunity({ props: { _create_community } }) {
  const form_data_ref = useRef({})

  const getImageRef = useRef()
  const inputNameRef = useRef()
  const inputBioRef = useRef()
  const inputCustomRef = useRef()
  const errorCustomRef = useRef()
  const tagsRef = useRef()

  const inputModAdminRef = useRef()
  const inputModModRef = useRef()

  const dummyImg_ = useRef()
  const selectedImage_ = useRef()

  // const [img_upload, _img_upload] = useState(null)

  const [checked_name_bio, _checked_name_bio] = useState([false, false])
  const [word_limiter, _word_limiter] = useState(50)
  const [show_word_limiter, _show_word_limiter] = useState(false)

  // modifiers

  const [selected_type, _selected_type] = useState(null)
  const [elimination_squads, _elimination_squads] = useState(false)
  // const [selected, _selected] = useState({
  //   community: false,
  //   event: false,
  // })
  const [hovered, _hovered] = useState(null)

  const [duration, _duration] = useState()
  const [custom, _custom] = useState(false)
  const [inFocusCustom, _inFocusCustom] = useState(false)
  const [interval, _interval] = useState('1 Day')

  const [tags, _tags] = useState([])

  // mods

  const [mods_admin, _mods_admin] = useState([])
  const [mods_mod, _mods_mod] = useState([])

  const [add_admin, _add_admin] = useState(false)
  const [add_moderator, _add_moderator] = useState(false)

  const [bzz, _bzz] = useState(null)

  const [form_check, _form_check] = useState(false)

  //   function get_duration_options(params) {
  //     const options = {
  //       infinity: (
  //         <svg
  //           id="infinity"
  //           className={
  //             'value push-1 ' + (duration === 'infinity' ? 'selected' : '')
  //           }
  //           xmlns="http://www.w3.org/2000/svg"
  //           viewBox="0 0 21.5 11.5"
  //           fill="none"
  //           onClick={() => _duration('infinity')}
  //         >
  //           <path
  //             strokeWidth="2"
  //             d="M10.8,5.8c0,0-2.7,5-5.5,5s-4.5-2.2-4.5-5c0-2.8,1.7-5,4.5-5S10.8,5.8,10.8,5.8z M10.8,5.8c0,0,2.7,5,5.5,5
  // s4.5-2.2,4.5-5c0-2.8-1.7-5-4.5-5S10.8,5.8,10.8,5.8z"
  //           />
  //         </svg>
  //       ),
  //       '1_month': (
  //         <p
  //           className={
  //             'value push-1 ' + (duration === '1 month' ? 'selected' : '')
  //           }
  //           style={{ display: duration === '1 month' ? 'inline-block' : 'none' }}
  //           onClick={() => _duration('1 month')}
  //         >
  //           1 Month
  //         </p>
  //       ),
  //       Custom: (
  //         <div
  //           className={
  //             'value push-1 ' + (duration === 'custom' ? 'selected' : '')
  //           }
  //           style={{ display: duration === 'custom' ? 'inline-block' : 'none' }}
  //           // onClick={() => _duration('')}
  //         >
  //           <input
  //             ref={inputCustomRef}
  //             type="text"
  //             placeholder="i.e 100 Days"
  //             style={{ display: custom ? 'block' : 'none' }}
  //             onBlur={() =>
  //               inputCustomRef.current.value.length === 0
  //                 ? _custom(false)
  //                 : _duration('custom')
  //             }
  //           />
  //           <p
  //             className="value"
  //             style={{ display: custom ? 'none' : 'block' }}
  //             onClick={() => _custom(true)}
  //           >
  //             Custom
  //           </p>
  //         </div>
  //       ),
  //     }
  //     return Object.entries(options).map(([k, v]) => v)
  //   }

  function get_moderators(type) {
    const modVar =
      type === 'me' ? [user_id] : type === 'admin' ? mods_admin : mods_mod
    const export_mods = []
    const moderator = (_user_id, _user_, index) => (
      <div
        className={
          '-f-a moderator ' + [bzz === `${type}-${_user_id}` ? 'bzz' : null]
        }
      >
        {type !== 'me' && (
          <div
            className="-X y click-me push"
            onClick={() => set_moderators(type, 'Del', index)}
          >
            <X type="block" />
          </div>
        )}
        <img
          src={src(`img-compressed/${_user_.img.split('.')[0]}.webp`)}
          alt=""
        />
        <p className="usn inline">
          {_user_.usn}
          {type === 'me' && <span className="inline">superadmin</span>}
        </p>
      </div>
    )
    modVar.forEach((user, index) => {
      export_mods.push(moderator(user, users[user], index))
    })
    return export_mods
  }

  function get_src() {
    if (selectedImage_.current) {
      return selectedImage_.current
    } else {
      if (dummyImg_.current) {
        return src(`img-dummy/dummy-img-${dummyImg_.current}.jpg`)
      } else {
        const dummy_img = fun().randInt(6)
        dummyImg_.current = dummy_img
        return src(`img-dummy/dummy-img-${dummy_img}.jpg`)
      }
    }
  }

  function get_title(str) {
    const replacedStr = str.replace(/_/g, ' ')

    const capitalizedStr = replacedStr
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')

    return capitalizedStr
  }

  // function set_interval(elm) {
  //   _intervals([
  //     elm,
  //     ...intervals_arr.slice(0, intervals_arr.indexOf(elm)),
  //     ...intervals_arr.slice(intervals_arr.indexOf(elm) + 1),
  //   ])
  // }

  function set_moderators(type, action, index = null) {
    const vars = {
      admin: {
        opposite: 'moderator',
        inputRef: inputModAdminRef,
        mods: mods_admin,
        _mods: _mods_admin,
        add: add_admin,
        _add: _add_admin,
      },
      moderator: {
        opposite: 'admin',
        inputRef: inputModModRef,
        mods: mods_mod,
        _mods: _mods_mod,
        add: add_moderator,
        _add: _add_moderator,
      },
    }
    if (action === 'add') {
      const applicant = vars[type].inputRef.current.value
      for (const [user, id] of users_lib_entries) {
        if (applicant === user) {
          if (!vars[type].mods.includes(id)) {
            if (!vars[vars[type].opposite].mods.includes(id)) {
              vars[type]._mods([...vars[type].mods, id])
              vars[type]._add(false)
              vars[type].inputRef.current.value = ''
              return
            } else {
              _bzz(`${vars[type].opposite}-${id}`)
              return
            }
          } else {
            _bzz(`${type}-${id}`)
            return
          }
        }
      }
      alert(`No such user, ${applicant}`)
    }
    if (action === 'Del') {
      const $mods = [...vars[type].mods]
      $mods.splice(index, 1)
      vars[type]._mods($mods)
      _bzz(null)
    }
  }

  // function set_modifiers(type, mod) {
  //   const $selected = { ...selected }
  //   const current_value = $selected[mod]
  //   if (type === 'type' && !current_value) {
  //     $selected.elimination = false
  //     $selected.ranked = false
  //   }
  //   $selected[mod] = !current_value
  //   _selected($selected)
  // }

  function set_tags(method, index) {
    const regex_test = (str) => {
      const regex = /[^a-zA-Z0-9]/
      if (regex.test(str)) {
        alert('please enter alphanumeric characters only.')
        return false
      } else {
        return true
      }
    }
    if (method === 'Del') {
      const $tags = [...tags]
      $tags.splice(index, 1)
      _tags($tags)
      return
    }
    if (method === 'Edit') {
      const elm = document.getElementById(`tag-${index}`)
      const $tags = [...tags]
      if (elm.innerHTML === '') {
        console.log('no value')
        set_tags('Del', index)
      } else {
        if (regex_test(elm.innerText)) {
          $tags[index] = elm.innerText
          _tags($tags)
          elm.blur()
        }
      }
      return
    }
    if (method === 'Blur') {
      tagsRef.current.value = ''
      return
    }
    if (method === 'Add' || method === 'Submit') {
      const tag = tagsRef.current.value
      if (tag.includes(' ')) {
        alert('tag must not include whitespace.')
      } else {
        if (regex_test(tag)) {
          _tags([...tags, tag])
          tagsRef.current.value = ''
        }
      }
    }
  }

  function set_word_limiter() {
    const words_remaining = 50 - inputBioRef.current.value.length
    _word_limiter(words_remaining)
  }

  function handle_input_custom(method) {
    const elm = inputCustomRef.current
    errorCustomRef.current.innerText = ''
    if (elm.value.length === 0) {
      _inFocusCustom(false)
      _custom(false)
      _duration('infinity')
    } else {
      let [num, unit] = elm.value.split(' ')
      if (!unit) {
        let num_string = ''
        const chars = elm.value.split('')
        for (let i = 0; i < chars.length; i++) {
          const char = chars[i]
          if (!isNaN(parseInt(char, 10))) {
            num_string += char.toString()
          } else {
            num = num_string
            unit = elm.value.slice(i, elm.value.length)
            break
          }
        }
      }
      const accepted_units = ['day', 'week', 'month']
      const processInput = (num, unit) => {
        if (num && unit) {
          let matchedUnit
          num = parseInt(num, 10)
          unit = unit.toLowerCase()
          if (!isNaN(num) && num > 0) {
            if (
              accepted_units.some((e) => {
                if (unit.includes(e)) {
                  matchedUnit = e
                  return true
                }
              })
            ) {
              return {
                value: [
                  num,
                  fun().capitalize(matchedUnit) + (num > 1 ? 's' : ''),
                ],
              }
            } else {
              return { error: 'unit not accepted' }
            }
          }
          return { error: '!isNaN(num) && num > 0' }
        } else {
          if (!num) {
            return { error: 'no num' }
          }
          if (!unit) {
            return { error: 'no unit' }
          }
        }
      }
      const return_value = processInput(num, unit)
      if (return_value.error) {
        errorCustomRef.current.innerText = return_value.error
        if (method === 'blur') {
          _inFocusCustom(false)
          _duration('custom')
        }
      } else {
        elm.value = return_value.value.join(' ')
        elm.blur()
        _inFocusCustom(false)
        _duration('custom')
      }
    }
  }

  function check_name_bio(type) {
    if (type === 'name') {
      if (inputNameRef.current.value.length > 10) {
        _checked_name_bio([true, checked_name_bio[1]])
      } else {
        _checked_name_bio([false, checked_name_bio[1]])
      }
    }
    if (type === 'bio') {
      if (inputBioRef.current.value.length > 0) {
        _checked_name_bio([checked_name_bio[0], true])
      } else {
        _checked_name_bio([checked_name_bio[0], false])
      }
    }
  }

  function search_users() {
    for (const [user, id] of users_lib_entries) {
      if (user.includes(applicant)) {
        found_ids.append([user, id])
      }
    }
  }

  function launch_community() {
    alert('currently unavailable')
    // retreive all form data
    // 'img',
    const required_fields = ['name', 'sub', 'tags']
    const form_data = {
      meta: {
        type: selected_type,
      },
      community_lib: {
        name: inputNameRef.current.value,
        sub: inputBioRef.current.value,
        img: form_data_ref.current.img,
        elimination: elimination_squads, // replaces behaviours
        global: {
          // divisions: required
          // parameters: required
        },
        // rules: required
        mods: {
          admins: [user_id, ...mods_admin],
          mods: mods_mod,
        },
        resources: null, // replaces nav_links
        tags: tags,
      },
    }
    required_fields.forEach((field) => {
      if (!form_data.community_lib[field]) {
        console.log('incomplete form')
        console.log(`requires: ${field}`)
        _form_check(`${field} is required.`)
        return
      }
    })
    if (form_data.community_lib.global.divisions > 1) {
      // parameters required
    }
    // post it
    console.log(form_data)
    return
    backend('__squad/create_community/', { body: form_data }).then(
      (response_json) => {
        console.log('%ccommunity created', 'color:palegreen')
      }
    )
  }

  useEffect(() => {
    function getTodayFormatted() {
      const today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
      const day = String(today.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    }
    const yyyy_mm_dd = getTodayFormatted()
    getImageRef.current.addEventListener('change', function (event) {
      const file = event.target.files[0]
      if (file) {
        form_data_ref.current.img = file
        const reader = new FileReader()
        reader.onload = function (e) {
          selectedImage_.current = e.target.result
          const preview = document.getElementById('communityImage')
          preview.src = e.target.result
        }
        reader.readAsDataURL(file)
      }
    })
    document.getElementById('calendar').addEventListener('click', function () {
      document.getElementById('input-date').showPicker()
    })
    document
      .getElementById('input-date')
      .addEventListener('change', function () {
        let selectedDate = this.value
        switch (selectedDate) {
          case '':
            selectedDate = 'Now'
            break
          case yyyy_mm_dd:
            selectedDate = 'Now'
            break
          default:
            selectedDate = fun().niceDate(selectedDate, 'mD')
        }
        if (selectedDate === '') {
          selectedDate = 'Now'
        }
        document.getElementById('selected-date').textContent = selectedDate
      })

    return () => {
      // second
    }
  }, [])

  useEffect(() => {
    if (selected_type === 'community') {
      _duration('infinity')
    }
    if (selected_type === 'event') {
      _duration('1 month')
    }
  }, [selected_type])

  useEffect(() => {
    if (custom) {
      inputCustomRef.current.focus()
      _inFocusCustom(true)
    } else {
      errorCustomRef.current.innerText = ''
    }
  }, [custom])

  useEffect(() => {
    if (add_admin) {
      inputModAdminRef.current.focus()
    }
    if (add_moderator) {
      inputModModRef.current.focus()
    }
  }, [add_admin, add_moderator])

  return (
    <div id="CreateCommunity">
      <div id="toolbar">
        <_arrowBack pathway={_create_community} />
        <p id="Create-Community">Create</p>
        <p
          id="launch"
          className={'color ' + (form_check === true ? 'ready' : '')}
          onClick={() => launch_community()}
        >
          Launch
        </p>
      </div>
      <div className="form">
        <p className="section-label">Type</p>
        <div className="-modifiers">
          {Object.entries(modifiers_lib.types).map(([type, arr]) => (
            <div className={'-f-a group ' + type + (hovered ? 'hovered' : '')}>
              {arr.map((e) => (
                <div
                  className={
                    `-mod hover-low type-${e} ` +
                    (selected_type === e ? 'selected' : '')
                  }
                  onClick={() =>
                    _selected_type(selected_type === e ? null : e) +
                    _hovered(null)
                  }
                  onMouseEnter={() => _hovered(e)}
                  onMouseLeave={() => _hovered(null)}
                >
                  <div className="--f-a">
                    {Modifiers[e]}
                    <p className="title">{get_title(e)}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div
            className="info-modifiers"
            style={{ display: hovered ? 'block' : 'none' }}
          >
            {/* <p className="info-title">{hovered && get_title(hovered)}</p> */}
            <p className="info-desc">
              {modifiers_lib.info[hovered ? hovered : selected_type]}
            </p>
          </div>
        </div>
        <div
          style={{
            display: selected_type ? (hovered ? 'none' : 'block') : 'none',
          }}
        >
          <img
            id="communityImage"
            src={get_src()}
            alt=""
            onClick={() => getImageRef.current.click()}
          />
          <input
            type="file"
            ref={getImageRef}
            id="getProfileImage"
            name="getProfileImage"
            accept="image/*"
            required
            style={{ display: 'none' }}
          ></input>
          {/* <div className="-f-a selected-modifiers">
            {Object.entries(selected).map(
              ([k, v]) =>
                v && (
                  <div className={'-mod ps-bottom-mini ' + [k]}>
                    {Modifiers[k]}
                  </div>
                )
            )}
          </div> */}
          <p className="section-label">Name & Bio</p>
          {/* <p
          className={
            'section-label ' +
            (checked_name_bio.every((e) => e === true) ? 'checked' : '')
          }
        >
          <span className={checked_name_bio[0] ? 'checked' : ''}>Name</span>
          {' & '}
          <span className={checked_name_bio[1] ? 'checked' : ''}>Bio</span>
        </p> */}
          <input
            ref={inputNameRef}
            id="name"
            type="text"
            placeholder={fun().capitalize(selected_type) + ' Name?'}
            onChange={() => check_name_bio('name')}
          />
          <div className="-bio posrel">
            <div
              id="limiter"
              style={{
                visibility: show_word_limiter ? 'visible' : 'hidden',
                width: `${word_limiter}px`,
                background: 'blue',
              }}
            ></div>
            <input
              ref={inputBioRef}
              id="bio"
              placeholder="Bio?"
              maxLength={50}
              onChange={() => {
                set_word_limiter()
                check_name_bio('bio')
              }}
              onFocus={() => _show_word_limiter(true)}
              onBlur={() => _show_word_limiter(false)}
            ></input>
          </div>
          {/* ~  ~  ~  ~  ~  ~  ~  ~  ~  ~  ~  ~  ~  ~  ~  ~  ~  ~ */}
          <div className="common">
            {!selected_type && (
              <div className="dummies stack-2">
                <div className="-f- -dummy">
                  <div className="dummy-line-1 push"></div>
                  <div className="dummy-line-major"></div>
                </div>
                <div className="-f- -dummy">
                  <div className="dummy-line-2 push"></div>
                  <div className="dummy-line-major"></div>
                </div>
                <div className="-f- -dummy">
                  <div className="dummy-line-1 push"></div>
                  <div className="dummy-line-major"></div>
                </div>
                <div className="-f- -dummy">
                  <div className="dummy-line-2 push"></div>
                  <div className="dummy-line-major"></div>
                </div>
              </div>
            )}
            {/* LAUNCH */}
            <div
              id="launch-date"
              className="-f-a form-field"
              style={{ display: selected_type ? 'flex' : 'none' }}
            >
              <input id="input-date" type="date" />
              <p className="label">
                <span id="i-launch" className="info ps-top-static-left-info">
                  Launch
                </span>
                :
              </p>
              <p id="selected-date" className="value inline">
                Now
              </p>
              <div id="calendar" className="-_calendar inline">
                <_calendar />
              </div>
            </div>
            {/* DURATION */}
            <div
              id="duration"
              className={
                'form-field type-1 ' + (inFocusCustom ? 'stay-open' : '')
              }
              style={{ display: selected_type ? 'block' : 'none' }}
            >
              <p className="label push">
                <span id="i-duration" className="info ps-top-static-left-info">
                  Victory Term
                </span>
                :
              </p>
              {selected_type === 'community' && (
                <svg
                  id="infinity"
                  className={
                    'value push-1 ' +
                    (duration === 'infinity' ? 'selected' : '')
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 21.5 11.5"
                  fill="none"
                  onClick={() => {
                    _custom(false)
                    inputCustomRef.current.value = ''
                    _duration('infinity')
                  }}
                >
                  <path
                    strokeWidth="2"
                    d="M10.8,5.8c0,0-2.7,5-5.5,5s-4.5-2.2-4.5-5c0-2.8,1.7-5,4.5-5S10.8,5.8,10.8,5.8z M10.8,5.8c0,0,2.7,5,5.5,5
  s4.5-2.2,4.5-5c0-2.8-1.7-5-4.5-5S10.8,5.8,10.8,5.8z"
                  />
                </svg>
              )}
              {selected_type === 'event' && (
                <p
                  className={
                    'value push-1 ' + (duration === '1 month' ? 'selected' : '')
                  }
                  onClick={() => {
                    _custom(false)
                    inputCustomRef.current.value = ''
                    _duration('1 month')
                  }}
                >
                  1 Month
                </p>
              )}
              <div className="value" onClick={() => _custom(true)}>
                <p
                  className="nomarj"
                  style={{
                    display: custom ? 'none' : 'inline',
                    // visibility: duration !== 'custom' ? 'hidden' : 'visible',
                  }}
                >
                  Custom
                </p>
              </div>
              <input
                ref={inputCustomRef}
                id="input-custom"
                className={
                  'value document-input-style always-on-display ' +
                  (duration === 'custom' ? 'selected' : '')
                }
                type="text"
                placeholder="e.g. 100 Days"
                style={{
                  visibility: custom ? 'visible' : 'hidden',
                  opacity: 1,
                }}
                onKeyDown={(e) =>
                  e.key === 'Enter' && handle_input_custom('click')
                }
                onFocus={() => _inFocusCustom(true)}
                onBlur={() => handle_input_custom('blur')}
              />
              <p ref={errorCustomRef} id="error-custom" className="inline"></p>
            </div>
            {/* ELIMINATION */}
            <div
              id="elimination_squads"
              className="form-field type-1"
              style={{
                display: selected_type === 'community' ? 'block' : 'none',
              }}
            >
              <p className="label push">
                <span
                  id="i-elimination"
                  className="info ps-top-static-left-info"
                >
                  + Elimination Squads?
                </span>
                :
              </p>
              <p
                className="value selected bool"
                onClick={() => _elimination_squads(!elimination_squads)}
              >
                {elimination_squads.toString()}
              </p>
            </div>
            {/* INTERVAL */}
            <div
              id="intervals"
              className="form-field type-1"
              style={{
                display:
                  selected_type === 'community' && elimination_squads
                    ? 'block'
                    : 'none',
              }}
            >
              <p className="label push">
                <span id="i-interval" className="info ps-top-static-left-info">
                  Squad Inception Interval
                </span>
                :
              </p>
              {intervals_arr.map((e) => (
                <p
                  className={
                    'value push-1 ' + (interval === e ? 'selected' : '')
                  }
                  onClick={() => _interval(e)}
                >
                  {e}
                </p>
              ))}
            </div>
            {/* TAGS */}
            <div
              id="tags"
              style={{ display: selected_type ? 'block' : 'none' }}
            >
              <p className="label">
                <span id="i-tags" className="info ps-top-static-left-info">
                  Search Tags
                </span>
                :
              </p>
              <input
                ref={tagsRef}
                className="inline document-input-style"
                type="text"
                placeholder="Add Tag"
                onKeyDown={(ev) => ev.key === 'Enter' && set_tags('Add')}
                onBlur={() => set_tags('Blur')}
              />
              {tags.map((e, index) => (
                <div className="tag inline-2 -hide-display-flex">
                  <p
                    id={`tag-${index}`}
                    contentEditable={true}
                    onKeyDown={(ev) =>
                      ev.key === 'Enter'
                        ? ev.preventDefault() + set_tags('Edit', index)
                        : ev.code === 'Space'
                        ? ev.preventDefault()
                        : console.log(ev)
                    }
                    onBlur={() => set_tags('Edit', index)}
                  >
                    {e}
                  </p>
                  <div className="-click-me hide">
                    <div
                      className="click-me -X"
                      onClick={() => set_tags('Del', index)}
                    >
                      <X type="block" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="-moderators">
            <p className="section-label background">Moderators</p>
            <div>
              <p
                id="i-admin"
                className="section-label-micro info ps-top-static-left-info"
              >
                Admins
              </p>
              <div id="admin" className="mod-group">
                {get_moderators('me')}
                {get_moderators('admin')}
                <input
                  ref={inputModAdminRef}
                  className={add_admin.toString()}
                  type="text"
                  placeholder="Add Admin"
                  onKeyDown={(e) => {
                    e.key === 'Enter' && set_moderators('admin', 'add')
                    e.key === 'Delete' && (inputModAdminRef.current.value = '')
                  }}
                  onChange={() => _bzz(null)}
                  onFocus={() => _add_admin(true)}
                  onBlur={() =>
                    inputModAdminRef.current.value === '' && _add_admin(false)
                  }
                />
              </div>
            </div>
            <div>
              <p
                id="i-mods"
                className="section-label-micro info ps-top-static-left-info"
              >
                Mods
              </p>
              <div className="mod-group">
                {get_moderators('moderator')}
                <input
                  ref={inputModModRef}
                  className={
                    add_moderator.toString() +
                    (mods_mod.length === 0 ? ' mt0IMP' : '')
                  }
                  type="text"
                  placeholder="Add Moderator"
                  onKeyDown={(e) => {
                    e.key === 'Enter' && set_moderators('moderator', 'add')
                    e.key === 'Delete' && (inputModModRef.current.value = '')
                  }}
                  onChange={() => _bzz(null)}
                  onFocus={() => _add_moderator(true)}
                  onBlur={() =>
                    inputModModRef.current.value === '' && _add_moderator(false)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <p
        id="launch"
        className={'color ' + (form_check === true ? 'ready' : '')}
        onClick={() => launch_community()}
      >
        Launch
      </p> */}
    </div>
  )
}
