import React from 'react'
import { I } from '../.i.js'

import X from '../svg/X.js'

export default function PopUp({ props: { showPopUp, _showPopUp } }) {
  const { id, payload } = showPopUp
  return (
    <>
      <div id="pop-up-m" className="-m-"></div>
      <div id="pop-up" className={'xy show ' + id}>
        <div
          className="-X click-me black-light"
          onClick={() => _showPopUp(false)}
        >
          <X />
        </div>
        {I()[id](payload)}
      </div>
    </>
  )
}
