import React from 'react'

export default function ArrowDown() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.5 17.5"
      fill="none"
    >
      <path
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M5.8,16.8v-16"
      />
      <path
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M10.8,11.8c0,0-3.7,5-5,5s-5-5-5-5"
      />
    </svg>
  )
}
