import React from 'react'
import { src } from '../.core.js'
import { desktop, mobile } from './ROUTERJS.js'

export default function UserProfile({
  props: { __SQUAD__, focus_user_id, focus_user, location, get_entries },
}) {
  const iso3166 = require('iso-3166-1')

  const isSquadie = __SQUAD__.users.includes(focus_user_id)
  const isDummy = focus_user_id.includes('dummy')

  const $$FlagStyleISO$$ = true

  function get_user_items() {
    let awards = []

    function get_awards() {
      if (focus_user.hasOwnProperty('awards')) {
        const award = (entry) => <img src={null} alt={null} />
        focus_user['awards'].forEach((entry) => {
          awards.push(award(entry))
        })
      }
    }

    get_awards()

    return [awards]
  }
  function get_flag() {
    const flag = (iso) => {
      if (iso.length > 2) {
        return `url(${src('3x2/principal-subdivisions/' + iso + '.svg')})`
      } else {
        return `url(${src('3x2/' + iso + '.svg')})`
      }
    }
    const country = (iso) => {
      const shortForm = {
        FM: 'Micronesia',
        'ES-CT': 'Catalonia',
        GB: 'United Kingdom',
        'GB-ENG': 'England',
        'GB-SCT': 'Scotland',
        'GB-WLS': 'Wales',
        IR: 'Iran',
        KP: 'North Korea',
        KR: 'South Korea',
        LA: 'Laos',
        MD: 'Moldova',
        PS: 'Palestine',
        RU: 'Russia',
        SY: 'Syria',
        TR: 'Türkiye',
        TW: 'Taiwan',
        TZ: 'Tanzania',
        US: 'United States',
        VE: 'Venezuela',
        XK: 'Kosovo',
      }
      return (
        shortForm[iso] ??
        iso3166.whereAlpha2(iso)?.country ??
        `INVALID ISO: ${iso}`
      )
    }
    return $$FlagStyleISO$$
      ? focus_user.flag && (
          <span className="iso inline pseudo-top-mini">
            {focus_user.flag}
            <span className="pseudo">{country(focus_user.flag)}</span>
          </span>
        )
      : focus_user.flag && (
          <span
            className="flag pseudo-top-mini"
            style={{
              backgroundImage: flag(focus_user.flag),
            }}
          >
            <span className="pseudo">{country(focus_user.flag)}</span>
          </span>
        )
  }

  return (
    <div
      id="UserProfile"
      className={location === 'squad' ? 'quick-view background-semi' : ''}
      style={{
        height: mobile ? window.innerHeight + 'px' : '-webkit-fill-available',
      }}
    >
      <div id="focus">
        <div className="-f-ja -img">
          {isDummy ? (
            <div
              className="dummy-img"
              style={{ background: focus_user.col }}
            ></div>
          ) : (
            <img src={src('img/' + focus_user.img)} alt="Image 404" />
          )}
        </div>
        {/* <div id="-awards"></div> */}
        <div id="-user-items">
          <p className="mt0">
            <span className="usn hoverDrop posrel">
              {focus_user.usn}
              {/* <div className="drop">
                <p id="report" className="color">
                  Report User
                </p>
              </div> */}
            </span>
            {isSquadie ? (
              <span className="isSquadie ps-top-mini">
                <img src={src('squadie.svg')} alt="" />
              </span>
            ) : null}
            {get_flag()}
          </p>
        </div>
        <div id="feed-user" className="posrel">
          {get_entries('UserProfile')}
        </div>
      </div>
    </div>
  )
}
